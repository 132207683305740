import Swal from "sweetalert2";
import cogoToast from "cogo-toast";
import { xor } from "lodash";
import { getDaysInMonth, differenceInDays } from "date-fns";
import _ from "lodash";

// FORMULAS
export const prorate = (
  decimalValue,
  days,
  withThousandSeparator = true,
  forceTo30Days = false
) => {
  // const modifiedDays = forceTo30Days ? 30 : days;
  const modifiedDays = days >= 30 ? 30 : days;
  if (withThousandSeparator) {
    return thousand(((decimalValue / 30) * modifiedDays).toFixed(2));
  } else {
    return ((decimalValue / 30) * modifiedDays).toFixed(2);
  }
};

export const calcExcess = (hours, rate) => {
  if (rate && hours) {
    return (rate * hours).toFixed(2);
  }
  return "0.00";
};

export const mergeParams = (
  params,
  currencies,
  wageDays,
  params_calc = null
) => {
  params = params ? JSON.parse(params) : [];
  currencies = currencies ? JSON.parse(currencies) : [];
  wageDays = wageDays ? wageDays : 0;

  if (
    params &&
    Object.keys(params).length > 0 &&
    params.constructor === Object
  ) {
    let newParams = [];
    Object.entries(params).forEach((value, index) => {
      let noProrate = false;
      if (params_calc !== null) {
        const decodedCalc = JSON.parse(params_calc);
        noProrate = value[1] === decodedCalc[value[0]] ? true : false;
      }

      const totalDays = noProrate ? 30 : wageDays;
      newParams[index] = {
        name: value[0],
        amount: value[1],
        prorate: prorate(value[1], totalDays, false),
        currency: currencies[index],
      };
    });
    return newParams;
  }
  return [];
};

export const revertToDBFormat = (
  arrayObject,
  stringify = false,
  wageDays = null
) => {
  if (Array.isArray(arrayObject) && arrayObject.length) {
    let values = {};
    let prorates = {};
    let currencies = [];

    arrayObject.map((value, index) => {
      values = { ...values, [value.name]: value.amount };
      currencies[index] = value.currency;
      if (value.prorate || value.prorate === 0) {
        if (wageDays !== null) {
          prorates = {
            ...prorates,
            [value.name]: prorate(value.amount, wageDays, false, true),
          };
        } else {
          prorates = { ...prorates, [value.name]: value.prorate };
        }
      }
      return value;
    });

    if (!stringify) {
      return { values, prorates, currencies };
    } else {
      return {
        values: JSON.stringify(values),
        prorates: JSON.stringify(prorates),
        currencies: JSON.stringify(currencies),
      };
    }
  }
  return { values: null, prorates: null, currencies: null };
};

export const revertFixedToDBFormat = (arrayObject, stringify = false) => {
  if (Array.isArray(arrayObject) && arrayObject.length) {
    let merged = {};
    arrayObject.map((value) => {
      merged = {
        ...merged,
        [value.name]: value.amount,
        [value.name + "_calc"]: value.prorate,
        [value.name + "_currency"]: value.currency,
      };
      return value;
    });

    if (!stringify) {
      return merged;
    }
    return JSON.stringify(merged);
  }
  return [];
};

// RESPONSE API PARSING
export const transformObject = (object) => {
  let newObject = {};
  Object.keys(object).forEach((key) => {
    let value = object[key][0];
    newObject[key] = value;
  });
  return newObject;
};

export const removeNullObject = (obj) =>
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === "object") removeNullObject(val);
    else if (val === null) delete obj[key];
  });

export const compareArrayExact = (array1, array2) =>
  array1.length === array2.length &&
  array1.every((element, index) => {
    return element === array2[index];
  });

export const compareArray = (array1, array2) => {
  return xor(array1, array2).length === 0;
};

export const mergeSet = (array1, array2) => {
  return [...new Set([...array1, ...array2])];
};

export const sortReg = (key, order = "desc") => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

    const convert = (value) => {
      if (typeof value === "string") {
        if (isValidFloat(value)) {
          return parseFloat(value);
        }
        return value.toUpperCase();
      }
      return value;
    };

    const varA = convert(a[key]);
    const varB = convert(b[key]);
    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    return order === "desc" ? comparison * -1 : comparison;
  };
};

export const sortNested = (arrObj, key, order = "desc") => {
  let split = key ? key.split(".") : [];
  let result = _.orderBy(
    arrObj,
    (obj) => {
      let value = obj[key];
      if (split.length > 1) {
        value = obj[split[0]][split[1]];
      }

      if (typeof value === "string" && isValidFloat(value)) {
        value = parseFloat(value);
      }
      return value;
    },
    order
  );
  return result;
};

// NUMBERS
export const autoDecimal = (num) => {
  if (num) {
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    return Number(num).toFixed(len);
  }
  return "0.00";
};

export const isValidFloat = (str) => {
  return /^-?[\d]*(\.[\d]+)?$/g.test(str);
};

export const convEmptyStringToCustom = (object, type = 0) => {
  let newObject = {};

  for (let [key, value] of Object.entries(object)) {
    if (value === "") {
      if (type === undefined || type === null) {
        newObject[key] = null;
      } else {
        newObject[key] = type;
      }
    } else {
      newObject[key] = value;
    }
  }
  return newObject;
};

export const thousand = (number, numDefault = "0.00") => {
  // old function
  //     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (number) {
    let parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  return numDefault;
};

export const toNumber = (float, numDefault = 0) => {
  if (float) {
    const parts = float.toString().split(".");
    return parts[0];
  }
  return numDefault;
};

export const multiParseFloat = (collection, defaultValue = 0.0) => {
  if (Array.isArray(collection) && collection.length) {
    return collection;
  }

  if (Object.keys(collection).length > 0 && collection.constructor === Object) {
    Object.entries(collection).forEach((value) => {
      const name = value[0];
      const val = value[1];
      if (!val) {
        collection[name] = defaultValue;
      } else {
        collection[name] = isValidFloat(val)
          ? parseFloat(val)
          : collection[name];
      }
    });
    return collection;
  }
};

// REACT-SELECT HELPER
export const convertCreatableInput = (array, isMulti = false, label) => {
  if (Array.isArray(array) && array.length) {
    return array.map((value) => {
      if (isMulti) return { label, value };
      return { label: value, value };
    });
  }
  return [];
};

export const revertCreatable = (arrObj, key = "value") => {
  if (Array.isArray(arrObj) && arrObj.length) {
    return arrObj.map((value) => {
      return value[key] ? value[key] : value; // if object then get value.value, if already array then return original array value
    });
  }
  return [];
};

// DATE HELPER
export const daysDifference = (date1, date2, countLastDay = true) => {
  let diff = differenceInDays(date2, date1);
  return countLastDay ? diff + 1 : diff;
};

export const getMonthDays = (date) => {
  if (date && date instanceof Date && !isNaN(date.valueOf())) {
    return getDaysInMonth(date);
  }
  return getDaysInMonth(strToDate(date)); // conv if string, not javascript Date
};

export const convDate = (date, type = "default") => {
  if (date && date instanceof Date && !isNaN(date.valueOf())) {
    // convert javascript Date format to prefered format
    const yyyy = date.getFullYear().toString();
    let mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
    let dd = date.getDate().toString();

    if (type === "DMY") {
      return (
        (dd[1] ? dd : "0" + dd[0]) +
        "-" +
        (mm[1] ? mm : "0" + mm[0]) +
        "-" +
        yyyy
      );
    } else if (type === "MY") {
      return yyyy + "-" + (mm[1] ? mm : "0" + mm[0]);
    } else if (type === "M-Y") {
      return (mm[1] ? mm : "0" + mm[0]) + "-" + yyyy;
    } else if (type === "DMYHI") {
      let hh = date.getHours().toString();
      let ii = date.getMinutes().toString();
      return (
        (dd[1] ? dd : "0" + dd[0]) +
        "-" +
        (mm[1] ? mm : "0" + mm[0]) +
        "-" +
        yyyy +
        " " +
        (hh[1] ? hh : "0" + hh[0]) +
        ":" +
        (ii[1] ? ii : "0" + ii[0])
      );
    } else {
      return (
        yyyy +
        "-" +
        (mm[1] ? mm : "0" + mm[0]) +
        "-" +
        (dd[1] ? dd : "0" + dd[0])
      );
    }
  }
  return date;
};

export const splitDate = (date, index = "all", separator = "-") => {
  const vals = date.split(separator);
  if (index === "all") {
    return vals;
  } else if (index === "YYYY-MM") {
    return vals[1] + "-" + vals[0];
  } else {
    return vals[index];
  }
};

export const strToDate = (string, format = "jsdate", separator = "-") => {
  if (!string) return null;
  let parts = string.split(separator);
  let converted = null;

  if (format === "YM") {
    converted = new Date(parts[1], parts[0] - 1, 1); // javaScript counts months from 0:
  } else {
    converted = new Date(parts[0], parts[1] - 1, parts[2]);
  }
  return converted;
};

export const firstDateMonth = (date) => {
  const month = splitDate(date, 1);
  const year = splitDate(date, 0);
  return year + "-" + month + "-01";
};

export const utcDate = (date) => {
  var str =
    date.getUTCFullYear().toString() +
    "/" +
    (date.getUTCMonth() + 1).toString() +
    "/" +
    date.getUTCDate() +
    " " +
    date.getUTCHours() +
    ":" +
    date.getUTCMinutes() +
    ":" +
    date.getUTCSeconds();
  return str;
};

export const dateFormatStd = (date, format = "DMY") => {
  let result;
  const stringToDate = strToDate(date);

  if (format === "DMY") {
    result = convDate(stringToDate, format);
  } else if (format === "MY") {
    result = convDate(stringToDate, "MY");
  } else if (format === "M-Y") {
    result = convDate(stringToDate, "M-Y");
  } else if (format === "DMYHI") {
    const newDate = new Date(date);
    result = convDate(newDate, format);
  } else if (format === "DMYSTRING") {
    const newDate = new Date(date);
    result = convDate(newDate, "DMY");
  }
  return result;
};

export const getMonthFromDate = (strDate) => {
  if (strDate) {
    const newDate = strToDate(strDate);
    return newDate.toLocaleString("en-US", { month: "long" });
  }
  return "-";
};

export const getYearFromDate = (strDate) => {
  if (strDate) {
    const newDate = strToDate(strDate);
    return newDate.toLocaleString("en-US", { year: "numeric" });
  }
  return "-";
};

// JSON STRING

export const isValidJSONString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

// EVENT BINDING
export const numericKeypress = (
  e,
  maxDigit = 12,
  allowLeadingZero = true,
  allowMinus = false
) => {
  const characterCode = e.key;
  let allowedCodes = [
    "Backspace",
    "Tab",
    ".",
    "Shift",
    "Delete",
    "ArrowUp",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "Ctrl",
  ];

  // specific handler when minus is allowed
  if (allowMinus && characterCode === "-" && e.currentTarget.value.length > 0)
    e.preventDefault(); //disallow negative in middle
  allowedCodes = allowMinus ? ["-", ...allowedCodes] : allowedCodes;
  maxDigit = allowMinus ? maxDigit + 1 : maxDigit;

  if (allowedCodes.includes(characterCode)) return;
  if (e.which === 32) e.preventDefault(); // disallow char by code

  const characterNumber = Number(characterCode);

  if (characterNumber >= 0 && characterNumber <= maxDigit) {
    if (e.currentTarget.value && e.currentTarget.value.length) {
      if (e.currentTarget.value.length > maxDigit) {
        //default max length 9 digits
        e.preventDefault();
      } else {
        return;
      }
    }
    if (!allowLeadingZero && characterNumber === 0) e.preventDefault();
  } else {
    e.preventDefault();
  }
};

// GLOBAL HELPER

export const fetchWhenEmpty = (dataSource, fetch) => {
  if (Array.isArray(dataSource) && dataSource.length < 1) {
    fetch();
  }
};

export const handleEnter = (keyEvent, action) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    action();
  }
};

// POPUP HELPER
export const showPopup = (type, ...rest) => {
  switch (type) {
    case "custom-confirm":
      const [
        message,
        buttonOKText,
        icon,
        type,
        handleOK,
        buttonCancelText,
        handleCancel = null,
      ] = rest;
      Swal.fire({
        title: "Confirmation",
        text: message,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: type === "default" ? "#3085d6" : "#d33",
        cancelButtonColor: type === "default" ? "#d33" : "#3085d6",
        confirmButtonText: buttonOKText,
        cancelButtonText: buttonCancelText ? buttonCancelText : "Cancel",
      }).then((result) => {
        if (result.value) handleOK();
        if (handleCancel && !result.value) handleCancel();
      });
      break;
    case "confirm-update":
      const [params, handleUpdate] = rest;
      Swal.fire({
        title: "Confirmation",
        text: "Are you sure you want to update this data?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          handleUpdate(params);
        }
      });
      break;
    case "confirm-delete":
      const [id, handleDelete] = rest;
      Swal.fire({
        title: "Confirmation",
        text: "Are you sure you want to delete this data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          handleDelete(id);
        }
      });
      break;
    case "success":
      const [successMessage] = rest;
      Swal.fire("Success", successMessage, "success");
      break;
    case "error":
      const [errorMessage] = rest;
      Swal.fire(
        "Oops...",
        errorMessage
          ? errorMessage
          : `Something is wrong, please try again later...`,
        "error"
      );
      break;
    case "error-confirm":
      const [errorConfirmMessage, handleErrorOK, handleErrorCancel] = rest;
      Swal.fire({
        title: "Oops...",
        text: errorConfirmMessage
          ? errorConfirmMessage
          : `Something is wrong, please try again later...`,
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Refresh",
        cancelButtonColor: "#d33",
        cancelButtonText: "Close",
      }).then((result) => {
        if (result.value) {
          handleErrorOK();
        } else {
          handleErrorCancel();
        }
      });
      break;
    case "warning":
      const [warningTitle, warningMessage] = rest;
      Swal.fire(
        warningTitle ? warningTitle : `Warning`,
        warningMessage
          ? warningMessage
          : `Something is wrong, please try again later...`,
        "warning"
      );
      break;
    default:
      return;
  }
};

// TOAST HELPER

export const showToast = (type, ...rest) => {
  switch (type) {
    case "success":
      const [successMessage] = rest;
      cogoToast.success(successMessage, {
        position: "bottom-center",
      });
      break;
    case "error":
      const [errorMessage] = rest;
      cogoToast.error(errorMessage, {
        position: "bottom-center",
      });
      break;
    default:
      return;
  }
};
