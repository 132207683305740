import { WAGE_ACCOUNT } from '../../services';

export const GENERATE_STATE = {
    REQUEST: 'GENERATE_FETCH_REQUEST',
    REQUEST_FAILED: 'GENERATE_REQUEST_FAILED',
    FETCH_CREW_SUCCESS: 'GENERATE_FETCH_CREW_SUCCESS',
    GENERATE_PAYROLL_SUCCESS: 'GENERATE_GENERATE_PAYROLL_SUCCESS',
};

const requesting = (requestType, response) => ({
    type: GENERATE_STATE.REQUEST,
    requestType,
    payload: response
});

const requestingFailed = (requestType, error) => ({
    type: GENERATE_STATE.REQUEST_FAILED,
    requestType,
    payload: error
});

const fetchingCrewSuccess = (requestType, response) => ({
    type: GENERATE_STATE.FETCH_CREW_SUCCESS,
    requestType,
    payload: response
});

const generatingPayrollSuccess = (requestType, response) => ({
    type: GENERATE_STATE.GENERATE_PAYROLL_SUCCESS,
    requestType,
    payload: response,
});

export const getCrew = (params) => {
    return dispatch => {
        dispatch(requesting('dt-crew'));
        WAGE_ACCOUNT.getCrew(params)
            .then(
                response => {
                    dispatch(fetchingCrewSuccess('dt-crew', response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestingFailed('dt-crew', response.body.message));
                }
            );
    };
};

export const generatePayroll = (params) => {
    return async dispatch => {
        dispatch(requesting('generate'));
        if(params.ids.length < 1) {
            dispatch(requestingFailed('generate', 'Please select crew'));
        } else {
            await WAGE_ACCOUNT.generate(params)
            .then(
                response => {
                    dispatch(generatingPayrollSuccess(response.data.body, params));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestingFailed('generate', response.body.message));
                }
            );
        }
    };
};