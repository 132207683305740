import { apiService } from "../config";

export function getCrew(params) {
  const keyword = params.keyword ? params.keyword : "";
  const vessel_id = params.vessel_id ? params.vessel_id : "";
  const csc = params.csc !== null ? params.csc : "";
  const start_period = params.start_period ? params.start_period : "";
  const end_period = params.end_period ? params.end_period : "";
  return apiService.get(
    `/wage-accounts/crews?keyword=${keyword}&vessel_id=${vessel_id}&csc=${csc}&start_period=${start_period}&end_period=${end_period}`
  );
}

export function generate(params) {
  return apiService.post(`/wage-accounts/generate`, params);
}

export function getPayroll(params) {
  const keyword = params.keyword ? params.keyword : "";
  const vessel_id = params.vessel_id ? params.vessel_id : "";
  const csc = params.csc !== null ? params.csc : "";
  const month = params.month ? params.month : "";
  const year = params.year ? params.year : "";
  return apiService.get(
    `/wage-accounts/payrolls?keyword=${keyword}&vessel_id=${vessel_id}&csc=${csc}&month=${month}&year=${year}`
  );
}

export function putEarningPayroll(params) {
  return apiService.put(`/wage-accounts/update-earnings-deductions`, params);
}

export function putRemarksLeaveDays(params) {
  return apiService.put(`/wage-accounts/update-remarks-leavedays`, params);
}

export function removePayroll(params) {
  return apiService.delete(`/wage-accounts/delete/${params.id}`);
}

export function postPreview(params) {
  return apiService.post(`/wage-accounts/previews`, params);
}

export function postDeleteAll(params) {
  return apiService.post(`/wage-accounts/delete-multiple`, params);
}

export function sendAllPayslip(params) {
  return apiService.post(`/wage-accounts/send`, params);
}

export function postDownload(params) {
  return apiService.post(`/wage-summary/download`, params);
}

export function postPromotion(params) {
  return apiService.post(`/wage-accounts/promote-demote`, params);
}
