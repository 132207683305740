import { GENERAL_SETTING_STATE } from './action';

const INITIAL_STATE = {
    loading: true,
    settings: null,
    success: null,
    error: null,
    errorMessage: null,
};

const generalSettingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GENERAL_SETTING_STATE.REQUEST:
            return {
                ...state,
                loading: true,
                success: null,
                error: null,
                errorMessage: null
            };
        case GENERAL_SETTING_STATE.REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.requestType,
                errorMessage: action.payload,
            };
        case GENERAL_SETTING_STATE.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'get-settings',
                settings: action.payload.contents.settings
            };
        case GENERAL_SETTING_STATE.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'update',
            };
        default:
            return state;
    }
};

export default generalSettingReducer;
