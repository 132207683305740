import { apiService } from '../config';

export function get(params) {
    const page = params.page ? `${params.page}` : '';
    const limit = params.limit ? `${params.limit}` : '';
    return apiService.get(`/audit-trails?page=${page}&limit=${limit}`);
};




