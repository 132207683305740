import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBasicWage, postBasicWage, updateBasicWage, removeBasicWage } from './action';
import { getPrincipal } from '../principal/action';
import { getRank } from '../rank/action';
import { showPopup, showToast, convEmptyStringToCustom, removeNullObject } from '../../helpers';
import BasicWageDatatable from './datatable';
import BasicWageModal from './modal';

class BasicWagePage extends Component {

    constructor(props) {
        super(props);

        this.EMPTY_FORM = {
            id: null,
            principal: '',
            rank: '',
            basic_wages: '',
            fixed_overtime: '',
            leave: '',
            over_time: '',
        };

        this.state = {
            openModal: false,
            formData: this.EMPTY_FORM,
            formType: null,
            alert: 'hidden',
        };
    }

    componentDidMount() {
        this.fetchDatatable();
        this.props.getPrincipal({filter: '', search:''});
        this.props.getRank({filter: '', search:''});
    }

    componentDidUpdate(prevProps) {
        if(this.props.reload !== prevProps.reload) {
        }

        if(this.props.success && this.props.success !== prevProps.success) {
            let message;
            if(this.props.success === 'create') message = 'Data successfully created';
            if(this.props.success === 'update') message = 'Data successfully updated';
            if(this.props.success === 'delete') message = 'Data successfully deleted';
            this.setState({openModal: false});
            this.fetchDatatable();
            showToast('success', message);
        }
    }

    fetchDatatable = (limit, page, filter) => {
        this.props.getBasicWage({limit, page, filter});
    }

    toggleModal = (type, formData) => {
        this.setState({
            formData: type !== 'create' ? formData : this.EMPTY_FORM,
            formType: type,
        }, () => this.setState({openModal: !this.state.openModal}) );
    }

    toggleConfirmDelete = (id) => {
        showPopup('confirm-delete', id, this.handleDelete);
    }

    handleSubmit = (params, {setErrors, resetForm}) => {
        const { formData, formType } = this.state;
        let convertedParams = convEmptyStringToCustom(params, null);
                              removeNullObject(convertedParams);

        if(formType === 'create') {
            this.props.postBasicWage(convertedParams, setErrors, resetForm);
        } else if(formType === 'update' && formData.id !== null) {
            showPopup(
                'confirm-update',
                convertedParams,
                () => this.props.updateBasicWage(convertedParams, setErrors, resetForm)
            );
        }
    }

    handleDelete = (id) => {
        this.props.removeBasicWage({id});
    }

    handleCloseAlert = () => {
        this.setState({alert: 'hidden'});
    }

    render() {
        const { openModal, formData, formType, } = this.state;
        const { 
            dtLoading, modalLoading, datatable, error, errorMessage, currencyData, principalData, 
            rankData  
        } = this.props;

        return (
            <div className="page-container">
                <BasicWageDatatable
                    isLoading={dtLoading}
                    dataSource={datatable}
                    currencyData={currencyData}
                    toggleModal={this.toggleModal}
                    toggleConfirmDelete={this.toggleConfirmDelete}
                    reloadDatatable={this.fetchDatatable}
                />
                <BasicWageModal
                    isOpen={openModal}
                    formData={formData}
                    formType={formType}
                    handleSubmit={this.handleSubmit}
                    handleClose={() => this.toggleModal('close')}
                    errorMessage={error !== 'validate' && errorMessage ? errorMessage : undefined}
                    modalLoading={modalLoading}
                    principalData={principalData}
                    rankData={rankData}
                    currencyData={currencyData}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ basicWage, principal, rank, currency }) => ({
    basicWage,
    dtLoading: basicWage.dtLoading,
    modalLoading: basicWage.modalLoading,
    datatable: basicWage.datatable,
    reload: basicWage.reload,
    success: basicWage.success,
    error: basicWage.error,
    errorMessage: basicWage.errorMessage,
    currencyData: currency.datatable,
    rankData: rank.datatable,
    principalData: principal.datatable
});

const mapDispatchToProps = dispatch => ({
    getBasicWage: (params) => dispatch(getBasicWage(params)),
    postBasicWage: (params, setErrors) => dispatch(postBasicWage(params, setErrors)),
    updateBasicWage: (params, setErrors) => dispatch(updateBasicWage(params, setErrors)),
    removeBasicWage: (params) => dispatch(removeBasicWage(params)),
    getPrincipal: (params) => dispatch(getPrincipal(params)),
    getRank: (params) => dispatch(getRank(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BasicWagePage);


