import React, { Component   } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { startOfMonth } from 'date-fns';
import { postPromotion } from '../action';
import { getRank } from '../../rank/action';
import { showPopup, convDate, strToDate } from '../../../helpers';
import { Datepicker } from '../../../components/forms';
import { Button, Select2Filter } from '../../../components/ui';
import { MInput } from '../../../components/material/form';
import Modal from '../../../components/modal';

class PromoteModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            dataChanged: false,
            dataSource: null,
            formData: {
                start_rank:  convDate(startOfMonth(new Date(), 1)),
                end_rank: null,
                new_rank_id: null,
                new_rank_start: null,
                new_rank_end: null,
            }
        };
        this.formik = React.createRef();
    }

    UNSAFE_componentWillMount() {
        if (Array.isArray(this.props.rankData) && this.props.rankData.length < 1) {
            this.props.getRank();
        }
        this.setState({ dataSource : this.props.dataSource });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(!nextProps.isOpen && this.props.isOpen && this.props.isOpen !== nextProps.isOpen) {
            this.setState({ dataChanged : false });
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.formData !== prevProps.formData) {
            this.setState({ formData : this.props.formData });
        }
    }

    clickSubmit = () => {
        const formikProps = this.formik.current;
        formikProps.handleSubmit();
    }

    handleChange = (params) => {
        let key;
        let value;

        if(params.name){
            key = params.name;
            value = params.value;
        }

        if(params.new_rank_id) {
            key = 'new_rank_id';
            value = params.new_rank_id;
            this.formik.current.setFieldValue('new_rank_id', params.new_rank_id);
        }

        this.setState({ 
            formData: {
                ...this.state.formData, 
                [key]: value
            }
        });
    }

    handleSubmit = () => {
        const { dataSource } = this.props;
        let { formData } = this.state;
        formData.id = dataSource.id;
        formData.principal_id = dataSource.vessel.principal_id;
        this.props.postPromotion(formData);
    }

    handleCancelAndClose = () => {
        let { dirty, resetForm } = this.formik.current;
        const clearAndClose = () => {
            dirty = false;
            this.props.handleClose();
            resetForm();
        }
        
        if(dirty || this.state.dataChanged) {
            showPopup(
                'custom-confirm',
                "You haven't saved the data. Cancel the proceed anyway? Unsaved data may occurred",
                'Discard changes',
                'warning',
                'reversed',
                () => clearAndClose()
            )
        } else {
            clearAndClose();
        }
    }
            
    render() {
        const { formData } = this.state; 
        const { isOpen, dataSource, modalLoading, rankData, error, errorMessage } = this.props;
    
        const validation = Yup.object({
            end_rank: Yup.string().nullable().required('Please select end date for previous rank'),
            new_rank_id: Yup.string().nullable().required('Please select new rank'),
            new_rank_start: Yup.string().nullable().required('Please select start date for new rank'),
            new_rank_end: Yup.string().nullable().required('Please select end date for new rank'),
        });
        
        return (
            <Modal
                isOpen={isOpen}
                background={'bg-cyan'}
                size={'modal-lg'}
                title={'Rank Promotion / Demotion'}
                errorMessage={error === 'promote-demote' && errorMessage ? errorMessage : undefined}
                onClose={this.handleCancelAndClose}
                content={(
                    <Formik
                        innerRef={this.formik}
                        initialValues={formData}
                        validationSchema={validation}
                        onSubmit={this.handleSubmit}
                        enableReinitialize
                    >
                        {() => (
                            <Form autoComplete="off">
                                <div className="row">
                                    <div className='col-lg-6 col-md-6'>
                                        <MInput
                                            className="promote-current-rank"
                                            label="Current Rank"
                                            name="old_rank"
                                            type="text"
                                            defaultValue={dataSource.rank}
                                            fullWidth
                                            noMargin
                                            disabled
                                        />
                                    </div>
                                    {/* <div className='col-lg-3 col-md-3'>
                                        <Datepicker
                                            className={'datepicker-filter'}
                                            name={'start_rank'}
                                            label={'Start'}
                                            placeholder="Select date"
                                            icon={'date_range'}
                                            minDate={strToDate(formData.start_rank)}
                                            onChange={this.handleChange}
                                        />
                                    </div> */}
                                    <div className='col-lg-3 col-md-3'>
                                        <Datepicker
                                            className={'datepicker-filter'}
                                            name={'end_rank'}
                                            label={'End'}
                                            placeholder="Select date"
                                            icon={'date_range'}
                                            minDate={strToDate(formData.start_rank)}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-lg-6 col-md-6'>
                                        <Select2Filter
                                            label={'New Rank'}
                                            className={'promote-new-rank'}
                                            customPrefix={'active_principal'}
                                            name={'new_rank_id'}
                                            placeholder="Select rank or type to search"
                                            keyPair={['id', 'description']}
                                            data={rankData}
                                            onChange={this.handleChange}
                                            // defaultValue={formData.new_rank_id}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-md-3'>
                                        <Datepicker
                                            className={'datepicker-filter'}
                                            name={'new_rank_start'}
                                            label={'Start'}
                                            placeholder="Select date"
                                            icon={'date_range'}
                                            minDate={strToDate(formData.end_rank)}
                                            maxDate={strToDate(formData.new_rank_end)}
                                            onChange={this.handleChange}
                                            disabled={formData.end_rank ? false : true}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-md-3'>
                                        <Datepicker
                                            className={'datepicker-filter'}
                                            name={'new_rank_end'}
                                            label={'End'}
                                            placeholder="Select date"
                                            icon={'date_range'}
                                            minDate={strToDate(formData.new_rank_start)}
                                            onChange={this.handleChange}
                                            disabled={formData.new_rank_start ? false : true}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
                footer={(
                    <div>
                        <Button
                            classCustom={'btn-lg'}
                            color={'bg-blue'}
                            type={'submit'}
                            disabled={modalLoading}
                            loading={modalLoading}
                            text={'Save Promotion / Demotion'}
                            onClick={this.clickSubmit}
                        />
                        <Button
                            classCustom={'btn-link'}
                            color={'bg-white'}
                            type={'button'}
                            text={'Cancel'}
                            onClick={this.handleCancelAndClose}
                        />
                    </div>
                )} 
            />
        );
    }
}

const mapStateToProps = ({ payroll, rank }) => ({
    payroll,
    success: payroll.success,
    error: payroll.error,
    errorMessage: payroll.errorMessage,
    rankData: rank.datatable,
});

const mapDispatchToProps = dispatch => ({
    getRank: (params) => dispatch(getRank(params)),
    postPromotion: (params) => dispatch(postPromotion(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PromoteModal);