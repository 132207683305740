import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { checkExpired, resetPassword } from './action';
import { Button } from '../../components/ui';
import { Alert } from '../../components/alert';
import { showPopup } from '../../helpers';
import { Typography, Grid } from '@material-ui/core';
import { MInput } from '../../components/material/form';

class ResetPasswordPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'form-reset',
            formData: {
                password: '',
                password_confirmation: ''
            }
        };
    }
    
    componentWillMount() {
        const userAlreadyLoggedIn = this.props.auth.token;
        if(userAlreadyLoggedIn) {
            this.props.history.push('/payroll-transaction');
            showPopup('warning', 'Hmm...', 'You are already logged in. Please log out to continue');
        }

        const queryParam = this.props.location.search.substring(1); // remove the "?" at the start
        const searchParams = new URLSearchParams( queryParam );
        const token = searchParams.get("token");
        const email = searchParams.get("email");

        this.setState({  token,  email });
        this.props.checkExpired({token, email});
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(!nextProps.loading && !this.props.success && nextProps.success === 'reset') {
            this.setState({formType: 'reset-success'});
        }

        if(!this.props.success && nextProps.error === 'check') {
            this.setState({formType: 'token-expired'});
        }
    }

    handleSubmit = async (params, { setErrors }) => {
        const {token, email} = this.state;
        Object.assign(params, { token, email });
        await this.props.resetPassword(params, setErrors);
    };

    handleBackToLogin = () => {
        this.props.history.push('/login');
    }

    handleResubmit = () => {
        this.props.history.push('/forgot-password');
    }

    renderForm = () => {
        const { email, formData } = this.state;
        const { loading } = this.props;

        const validation = Yup.object({
            password: Yup.string().required('Password is required'),
            password_confirmation: Yup.string()
                .required('Confirm Password is required')
                .oneOf([Yup.ref('password'), null], "Confirm Password is mismatch with Password")
        });

        return (
            <Formik
                initialValues={formData}
                validationSchema={validation}
                onSubmit={this.handleSubmit}
            >
                <Form>
                    <div className="reset-password-msg">Please submit your new password for <strong>{email}</strong></div>
                    <MInput
                        label="New Password"
                        name="password"
                        type="password"
                        placeholder="Enter new password"
                        required
                        fullWidth
                    />
                    <MInput
                        label="Confirm Password"
                        name="password_confirmation"
                        type="password"
                        placeholder="Enter new password confirmation"
                        required
                        fullWidth
                    />
                    <Button
                        classCustom={'btn-block btn-lg btn-reset-pass'}
                        color={'bg-green'}
                        type={'submit'}
                        disabled={loading}
                        loading={loading}
                        text={'Reset Password'}
                        icon={'lock_open'}
                    />
                    <Grid container className="m-t-15 text-center">
                        <Grid item xs>
                            <button className="btn btn-link" onClick={this.handleBackToLogin}>I'll do it later</button>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        );
    };

    renderMessage = () => {
        const { success } = this.props;
        return (
            <div>
                <div className="reset-password-msg">
                    {
                        success === 'reset' ?
                        "Thank you. Your password has been successfully changed" :
                        "We are sorry. Your reset password link has expired, please submit your forgot password email again"
                    }
                </div>
                <Button
                    classCustom={'btn-block btn-lg btn-reset-pass'}
                    color={'bg-green'}
                    type={'button'}
                    text={success === 'reset' ? "Back to Login" : "Resubmit my Email" }
                    onClick={() => {
                        if(success) { 
                            this.handleBackToLogin()
                        } else { 
                            this.handleResubmit() 
                        }
                    }}
                />
            </div>
        );
    };

    render() {
        const { error, errorMessage } = this.props;
        const { formType } = this.state;

        return (
            <div>
                <Alert text={error === 'reset' && errorMessage ? errorMessage : ''}/>
                <Typography className="login-sub-title" component="h1" variant="h5">Reset Password</Typography>
                { 
                    formType === 'form-reset' ?
                    this.renderForm() :
                    this.renderMessage()
                }
            </div>
        );
    }
}

const mapStateToProps = ({ auth, resetPassword}) => ({
    auth,
    resetPassword,
    loading: resetPassword.loading,
    error: resetPassword.error,
    errorMessage: resetPassword.errorMessage,
    tokenExpired: resetPassword.tokenExpired,
    success: resetPassword.success,
});

const mapDispatchToProps = dispatch => ({
    checkExpired: (params, setErrors) => dispatch(checkExpired(params, setErrors)),
    resetPassword: (params, setErrors) => dispatch(resetPassword(params, setErrors))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordPage);


