import { apiService } from '../config';

export function getAll() {
    return apiService.get(`/principals/all`);
};

export function getFiltered() {
    return apiService.get(`/principals/active`);
};



