import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  updateRemarksLeaveDays,
  formChanged,
  submitTab,
  resetSubmit,
} from "../action";
import { Datepicker, TextAreaLabel } from "../../../components/forms";
import {
  showPopup,
  strToDate,
  multiParseFloat,
  daysDifference,
  prorate,
} from "../../../helpers";
import { Button, Select2Filter } from "../../../components/ui";
import { MInput } from "../../../components/material/form";

class RemarkSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      minDate: undefined,
      maxDate: undefined,
      csc: null,
      formData: {},
      leave_days: {},
      leavepays: {},
      start_period: null,
      end_period: null,
    };
    this.totalDaysPeriod = null;
    this.initialState = this.state;
    this.formik = React.createRef();
  }

  UNSAFE_componentWillMount() {
    if (this.props.dataSource) {
      const _signOffDate = new Date(this.props.dataSource.sign_off_date);
      const _signOnDate = new Date(this.props.dataSource.sign_on_date);
      let _periodPayrollFrom = new Date(
        this.props.dataSource.account_period_from
      );

      if (
        _signOffDate.getMonth() === _signOnDate.getMonth() &&
        _signOffDate.getYear() === _signOnDate.getYear()
      ) {
        _periodPayrollFrom = _signOnDate;
        const formatSignOnDate = `${_signOnDate.getFullYear()}-${_signOnDate.getMonth()}-${_signOnDate.getDate()}`;
        const formatSignOffDate = `${_signOffDate.getFullYear()}-${_signOffDate.getMonth()}-${_signOffDate.getDate()}`;
        this.setState({
          sign_on_date: formatSignOnDate,
          sign_off_date: formatSignOffDate,
        });
      }

      if (this.props.csc === 1) {
        if (
          _signOffDate.getMonth() === _periodPayrollFrom.getMonth() &&
          _signOffDate.getYear() === _periodPayrollFrom.getYear()
        ) {
          let onboard = this.props.dataSource.leave_days_earned;
          onboard = daysDifference(_periodPayrollFrom, _signOffDate);
          this.props.dataSource.leave_days_earned = onboard;
        }
      }

      const { dataSource, csc } = this.props;
      let { leave_days, leavepays } = this.state;
      this.totalDaysPeriod = daysDifference(
        strToDate(dataSource.account_period_from),
        strToDate(dataSource.account_period_to)
      );

      let formData = {
        id: dataSource.id,
        remarks: dataSource.remarks,
        reason_for_sign_off: dataSource.reason_for_sign_off,
        sign_off_date: dataSource.sign_off_date,
        bal_carried_to_next_month: dataSource.bal_carried_to_next_month,
        sign_on_date: this.props.dataSource.sign_on_date
          ? this.props.dataSource.sign_on_date
          : this.props.dataSource.account_period_from,
      };

      if (csc === 1) {
        let leave_days_end_of_month = dataSource.leave_days_end_of_month;
        leave_days_end_of_month =
          leave_days_end_of_month > 0 ? leave_days_end_of_month : 0; // handle minus problem

        leave_days = multiParseFloat(
          {
            previous_leave_days: dataSource.previous_leave_days,
            leave_days_earned: dataSource.leave_days_earned,
            leave_days_spent: dataSource.leave_days_spent,
            leave_days_paid_out: dataSource.leave_days_paid_out,
            leave_days_additional: dataSource.leave_days_additional,
            leave_days_additional_desc: dataSource.leave_days_additional_desc,
            leave_days_end_of_month,
          },
          null
        );
        formData = { ...formData, ...leave_days };
      } else {
        leavepays = multiParseFloat(
          {
            leave_leavesub: dataSource.leave_leavesub,
            leavepay_from_last_month: dataSource.leavepay_from_last_month,
            leavepay_this_month: dataSource.leavepay_this_month,
            bal_to_next_month: dataSource.bal_to_next_month,
            transferred_to_line_4: dataSource.transferred_to_line_4,
          },
          null
        );
        formData = { ...formData, ...leavepays };
      }

      this.setState({
        csc,
        start_period: strToDate(dataSource.account_period_from),
        end_period: strToDate(dataSource.account_period_to),
        total_days: dataSource.total_days,
        leave_days,
        leavepays,
        formData,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { trigger, submitStatus, nextTab, currentTab } = nextProps;
    if (
      trigger === "PAYROLL_SUBMIT_TAB" &&
      submitStatus &&
      currentTab === "remarks" &&
      nextTab !== "remarks"
    ) {
      this.formik.current.handleSubmit();
      this.props.resetSubmit(false);
    }
  }

  handleClickSubmit = (closeModal, nextTab = null) => {
    this.props.formChanged(true);
    this.props.submitTab(false, closeModal, "remarks", nextTab);
    this.formik.current.handleSubmit();
  };

  handleSubmit = () => {
    const { leave_days, leavepays, formData } = this.state;
    const calcLeaveEarned = () => {
      const newestLeaveDaysState = this.state.leave_days;
      const leaveValues = multiParseFloat(newestLeaveDaysState);
      let leaveTotal =
        // (leaveValues.previous_leave_days + leaveValues.leave_days_earned + leaveValues.leave_days_additional)
        leaveValues.previous_leave_days +
        leaveValues.leave_days_additional +
        leaveValues.leave_days_earned -
        leaveValues.leave_days_spent -
        leaveValues.leave_days_paid_out;

      leaveTotal = leaveTotal > 0 ? leaveTotal : 0; // handle minus problem
      this.setState({
        leave_days: {
          ...newestLeaveDaysState,
          leave_days_end_of_month: leaveTotal,
        },
      });
      this.props.dataSource.previous_leave_days =
        leaveValues.previous_leave_days;
      this.props.dataSource.leave_days_additional =
        leaveValues.leave_days_additional;
      this.props.dataSource.leave_days_earned = leaveValues.leave_days_earned;
      this.props.dataSource.leave_days_spent = leaveValues.leave_days_spent;
      this.props.dataSource.leave_days_paid_out =
        leaveValues.leave_days_paid_out;

      this.props.dataSource.total_days = leaveValues.leave_days_earned;
    };
    if (this.props.dataSource.csc === 1) calcLeaveEarned();

    let merged = { ...formData, ...leave_days, ...leavepays };
    this.props.updateRemarksLeaveDays(merged);
  };

  handleCancelAndClose = () => {
    this.props.formChanged(true);
    if (this.props.dataChanged) {
      showPopup(
        "custom-confirm",
        "You haven't saved the data. Cancel the proceed anyway? Unsaved data may occurred",
        "Discard changes",
        "warning",
        "reversed",
        () => {
          this.props.handleClose();
        }
      );
    } else {
      this.props.handleClose();
    }
  };

  handleBlurCSC = (event) => {
    const { leave_days, formData } = this.state;

    const calcLeaveEarned = () => {
      const newestLeaveDaysState = this.state.leave_days;
      const leaveValues = multiParseFloat(newestLeaveDaysState);
      let leaveTotal =
        // (leaveValues.previous_leave_days + leaveValues.leave_days_earned + leaveValues.leave_days_additional)
        leaveValues.previous_leave_days +
        leaveValues.leave_days_additional +
        leaveValues.leave_days_earned -
        leaveValues.leave_days_spent -
        leaveValues.leave_days_paid_out;

      leaveTotal = leaveTotal > 0 ? leaveTotal : 0; // handle minus problem
      this.setState({
        leave_days: {
          ...newestLeaveDaysState,
          leave_days_end_of_month: leaveTotal,
        },
      });
      this.props.dataSource.previous_leave_days =
        leaveValues.previous_leave_days;
      this.props.dataSource.leave_days_additional =
        leaveValues.leave_days_additional;
      this.props.dataSource.leave_days_earned = leaveValues.leave_days_earned;
      this.props.dataSource.leave_days_spent = leaveValues.leave_days_spent;
      this.props.dataSource.leave_days_paid_out =
        leaveValues.leave_days_paid_out;
    };

    if (event.name === "sign_off_date") {
      // NOTE: datepicker component have special props onChange = {name : ..., value: ...}, not the same with usual event.target.value
      const { start_period, total_days } = this.state;
      let _startPeriod = start_period;
      const _signOn = new Date(formData.sign_on_date);
      if (
        _signOn.getMonth() === _startPeriod.getMonth() &&
        _signOn.getYear() === _startPeriod.getYear()
      ) {
        _startPeriod = _signOn;
      }
      const _endPeriod = event.value
        ? event.value
        : this.props.dataSource.account_period_to;
      let onboard = daysDifference(_startPeriod, strToDate(_endPeriod));
      // Force to zero if result is minus
      onboard = onboard < 0 ? 0 : onboard;
      const offboard = total_days - onboard > 0 ? total_days - onboard : 0;
      this.setState(
        {
          formData: {
            ...formData,
            sign_off_date: event.value,
            leave_days_spent: offboard,
            leave_days_earned: onboard,
            previous_leave_days: leave_days.previous_leave_days,
          },
          leave_days: {
            ...leave_days,
            leave_days_spent: offboard,
            leave_days_earned: onboard,
          },
        },
        () => calcLeaveEarned()
      );
    } else if (event.name === "sign_on_date") {
      // TO DO: Update leave days earned
      if (event.value !== "") {
        let _endPeriod = new Date(this.props.dataSource.account_period_to);
        const _signOff = formData.sign_off_date
          ? new Date(formData.sign_off_date)
          : new Date(this.props.dataSource.sign_off_date);
        if (
          _signOff.getMonth() === _endPeriod.getMonth() &&
          _signOff.getYear() === _endPeriod.getYear()
        ) {
          _endPeriod = _signOff;
        }

        const newSetDate = new Date(event.value);
        const oldSetDate = new Date(_endPeriod);
        const _startPeriod = new Date(
          this.props.dataSource.account_period_from
        );
        let diff = 0;
        if (
          newSetDate.getMonth() === oldSetDate.getMonth() &&
          newSetDate.getYear() === oldSetDate.getYear()
        ) {
          diff = daysDifference(newSetDate, oldSetDate);
        } else if (newSetDate < _startPeriod) {
          diff = daysDifference(_startPeriod, oldSetDate);
        }
        const leaveDaysDataOld = this.state.leave_days;
        this.setState(
          {
            formData: {
              ...formData,
              sign_on_date: event.value,
            },
            total_days: diff,
            leave_days: {
              ...leaveDaysDataOld,
              leave_days_earned: diff,
            },
          },
          () => calcLeaveEarned()
        );
        this.props.dataSource.total_days = diff;
      }
    } else {
      const { name, value } = event.target;
      this.setState(
        {
          leave_days: { ...leave_days, [name]: value },
        },
        () => calcLeaveEarned()
      );
    }

    if (!this.props.dataChanged) this.props.formChanged(true);
  };

  handleBlurNonCSC = (event) => {
    const calcLeavePay = () => {
      const { leavepays, total_days, formData } = this.state;
      const leavepay_from_last_month = leavepays.leavepay_from_last_month
        ? parseFloat(leavepays.leavepay_from_last_month)
        : 0;
      const leavepay_this_month = parseFloat(
        prorate(leavepays.leave_leavesub, total_days)
      );
      const leave_total = leavepay_from_last_month + leavepay_this_month;
      let bal_to_next_month = 0;
      let transferred_to_line_4 = 0;

      if (formData.sign_off_date) {
        bal_to_next_month = 0;
        transferred_to_line_4 = leave_total;
      } else {
        transferred_to_line_4 = 0;
        bal_to_next_month = leave_total;
      }

      this.setState({
        leavepays: {
          ...leavepays,
          leavepay_this_month,
          bal_to_next_month,
          transferred_to_line_4,
        },
      });
    };

    const { start_period, leavepays, formData } = this.state;
    if (event.name === "sign_off_date") {
      // NOTE: datepicker component have special props onChange = {name : ..., value: ...}, not the same with usual event.target.value
      let _startPeriod = start_period;
      const _signOn = new Date(formData.sign_on_date);
      if (
        _signOn.getMonth() === _startPeriod.getMonth() &&
        _signOn.getYear() === _startPeriod.getYear()
      ) {
        _startPeriod = _signOn;
      }
      const _endPeriod = event.value
        ? event.value
        : this.props.dataSource.account_period_to;
      let onboard = daysDifference(_startPeriod, strToDate(_endPeriod));
      // Force to zero if result is minus
      onboard = onboard < 0 ? 0 : onboard;
      this.props.dataSource.total_days = onboard;
      this.setState(
        {
          total_days: onboard,
          formData: { ...formData, sign_off_date: event.value },
        },
        () => calcLeavePay()
      );
    } else if (event.name === "sign_on_date") {
      if (event.value !== "") {
        let _endPeriod = new Date(this.props.dataSource.account_period_to);
        const _signOff = formData.sign_off_date
          ? new Date(formData.sign_off_date)
          : new Date(this.props.dataSource.sign_off_date);
        if (
          _signOff.getMonth() === _endPeriod.getMonth() &&
          _signOff.getYear() === _endPeriod.getYear()
        ) {
          _endPeriod = _signOff;
        }

        const newSetDate = new Date(event.value);
        const oldSetDate = new Date(_endPeriod);
        const _startPeriod = new Date(
          this.props.dataSource.account_period_from
        );
        let diff = 0;
        if (
          newSetDate.getMonth() === oldSetDate.getMonth() &&
          newSetDate.getYear() === oldSetDate.getYear()
        ) {
          diff = daysDifference(newSetDate, oldSetDate);
        } else if (newSetDate < _startPeriod) {
          diff = daysDifference(_startPeriod, oldSetDate);
        }
        this.setState(
          {
            formData: {
              ...formData,
              sign_on_date: event.value,
            },
            total_days: diff,
          },
          () => calcLeavePay()
        );
        this.props.dataSource.total_days = diff;
      }
    } else {
      let { name } = event.target;
      let value = event.target.value;
      value = value === "" ? null : value;

      this.setState({ leavepays: { ...leavepays, [name]: value } }, () =>
        calcLeavePay()
      );
    }

    if (!this.props.dataChanged) {
      this.props.formChanged(true);
    }
  };

  handleBlurGlobal = (event) => {
    const { name, value } = event.target;
    const { formData } = this.state;
    this.setState({ formData: { ...formData, [name]: value } });
    if (!this.props.dataChanged) {
      this.props.formChanged(true);
    }
  };

  handleBlurReason = (params) => {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        reason_for_sign_off: params.reason_for_sign_off,
      },
    });
    if (!this.props.dataChanged) {
      this.props.formChanged(true);
    }
  };

  renderCSCForm = () => {
    const {
      leave_days_end_of_month,
      leave_days_earned,
      leave_days_spent,
    } = this.state.leave_days;
    return (
      <div>
        <div className="row">
          <div className="col-lg-6 col-md-6 pl-0">
            <MInput
              label="Leave Days Earned"
              name="leave_days_earned"
              type="number"
              min={1}
              max={31}
              placeholder="0"
              fullWidth
              noMargin
              value={leave_days_earned}
              defaultValue={leave_days_earned}
              onBlur={this.handleBlurCSC}
              disabled
              disableUnderline
            />
          </div>
          <div className="col-lg-6 col-md-6 pl-0">
            <MInput
              label="Leave Days Spent"
              name="leave_days_spent"
              type="number"
              placeholder="0"
              fullWidth
              noMargin
              // value={leave_days_spent}
              defaultValue={leave_days_spent}
              onBlur={this.handleBlurCSC}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 pl-0">
            <MInput
              className="leavedays-additional"
              label="Leave Days Additional"
              name="leave_days_additional"
              type="number"
              placeholder="0"
              fullWidth
              noMargin
              onBlur={this.handleBlurCSC}
            />
          </div>
          <div className="col-lg-6 col-md-6 pl-0">
            <TextAreaLabel
              label="Leave Days Additional Description"
              className="form-control leavedays-desc"
              name="leave_days_additional_desc"
              rows="2"
              placeholder="Fill in description"
              onBlur={this.handleBlurCSC}
              noMargin
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 pl-0">
            <MInput
              label="Leave Days Paid Out"
              name="leave_days_paid_out"
              type="number"
              placeholder="0"
              fullWidth
              noMargin
              onBlur={this.handleBlurCSC}
            />
          </div>
          <div className="col-lg-6 col-md-6 pl-0">
            <MInput
              label="Leave Days End of Month"
              name="leave_days_end_of_month"
              type="number"
              placeholder="0"
              fullWidth
              noMargin
              value={leave_days_end_of_month}
            />
          </div>
        </div>
      </div>
    );
  };

  renderNonCSCForm = () => {
    const { csc } = this.props;
    const {
      leavepay_this_month,
      bal_to_next_month,
      transferred_to_line_4,
    } = this.state.leavepays;
    return (
      <div>
        <div className="row">
          <div className="col-lg-6 col-md-6 pl-0">
            <MInput
              label="Leavepay from Last Month"
              name="leavepay_from_last_month"
              type={csc === 0 ? "currency" : "number"}
              placeholder={csc === 0 ? "0.00" : "0"}
              fullWidth
              noMargin
              onBlur={this.handleBlurNonCSC}
            />
          </div>
          <div className="col-lg-6 col-md-6 pl-0">
            <MInput
              label="Leavepay This Month"
              name="leavepay_this_month"
              type={"currency"}
              placeholder="0.00"
              fullWidth
              noMargin
              value={leavepay_this_month}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 pl-0">
            <MInput
              label="Balance to Next Month"
              name="bal_to_next_month"
              type={"currency"}
              placeholder="0.00"
              fullWidth
              noMargin
              value={bal_to_next_month}
            />
          </div>
          <div className="col-lg-6 col-md-6 pl-0">
            <MInput
              label="Transferred to Line 4"
              name="transferred_to_line_4"
              type={"currency"}
              placeholder="0.00"
              fullWidth
              noMargin
              value={transferred_to_line_4}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { dataSource, loading, editing, csc, reasonData } = this.props;
    const { formData } = this.state;

    const validation = Yup.object({
      sign_off_date: Yup.date().nullable(),
      remarks: Yup.string().nullable(),
      reason_for_sign_off: Yup.string().nullable(),
    });

    return (
      <Formik
        innerRef={this.formik}
        initialValues={formData}
        validationSchema={validation}
        onSubmit={this.handleSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <Form autoComplete="off">
            <div className="tab-body tab-edit-payroll">
              <div className="row">
                <div className="col-lg-12 m-0">{this.props.profile()}</div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-5">
                  <h4 className="title-separator">Remarks</h4>
                  <Datepicker
                    className={"datepicker-remarks"}
                    name={"sign_on_date"}
                    label={"Sign On Date"}
                    placeholder="Select date"
                    icon={"date_range"}
                    // minDate={strToDate(dataSource.account_period_from)}
                    // maxDate={strToDate(dataSource.account_period_to)}
                    setValue={
                      dataSource.sign_on_date
                        ? strToDate(dataSource.sign_on_date)
                        : undefined
                    }
                    onChange={
                      csc === 1 ? this.handleBlurCSC : this.handleBlurNonCSC
                    }
                    mandatory={true}
                  />
                  <Datepicker
                    className={"datepicker-remarks"}
                    name={"sign_off_date"}
                    label={"Sign Off Date"}
                    placeholder="Select date"
                    icon={"date_range"}
                    // minDate={strToDate(dataSource.account_period_from)}
                    maxDate={strToDate(dataSource.account_period_to)}
                    setValue={
                      dataSource.sign_off_date
                        ? strToDate(dataSource.sign_off_date)
                        : undefined
                    }
                    onChange={
                      csc === 1 ? this.handleBlurCSC : this.handleBlurNonCSC
                    }
                  />
                  <Select2Filter
                    className={"reason-filter"}
                    name={"reason_for_sign_off"}
                    label={"Reason for Sign Off"}
                    placeholder="Select reason"
                    keyPair={["description", "description"]}
                    data={reasonData}
                    onChange={this.handleBlurReason}
                    defaultValue={formData.reason_for_sign_off}
                  />
                  <TextAreaLabel
                    label="Remarks"
                    className="form-control"
                    name="remarks"
                    rows="2"
                    placeholder="Fill in remarks note"
                    onChange={this.handleBlurGlobal}
                  />
                </div>
                <div className="col-lg-1 col-md-1"></div>
                <div className="col-lg-6 col-md-6">
                  <div className="row">
                    <h4 className="title-separator">Leave Days Balance</h4>
                    {csc === 1 ? (
                      <div className="col-lg-6 col-md-6 pl-0">
                        <MInput
                          label="Previous Leave Days"
                          name="previous_leave_days"
                          type="number"
                          placeholder="0"
                          min={1}
                          max={31}
                          fullWidth
                          noMargin
                          onBlur={this.handleBlurCSC}
                          disabled={
                            dataSource.previous_leave_days === null ||
                            parseFloat(dataSource.previous_leave_days) === 0
                              ? false
                              : true
                          } // Disable input
                          disableUnderline={
                            dataSource.previous_leave_days === null ||
                            parseFloat(dataSource.previous_leave_days) === 0
                              ? false
                              : true
                          } // Disable input
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-lg-6 col-md-6 pl-0">
                      <MInput
                        label="Balance carried to next month"
                        name="bal_carried_to_next_month"
                        type="currency"
                        placeholder="0.00"
                        // disabled
                        // disableUnderline
                        fullWidth
                        noMargin
                      />
                    </div>
                  </div>
                  {csc === 1 ? this.renderCSCForm() : this.renderNonCSCForm()}
                </div>
              </div>
            </div>
            <div className="tab-footer currency p-0 mb-15">
              <div className="button-footer">
                <Button
                  classCustom={"btn-lg mr-7"}
                  color={"bg-green"}
                  type={"button"}
                  disabled={editing || loading ? true : false}
                  loading={loading}
                  text="Update &amp; Next"
                  onClick={() => this.handleClickSubmit(false, "earnings")}
                />
                {/* <Button
                  classCustom={"btn-lg mr-7"}
                  color={"bg-blue"}
                  type={"button"}
                  disabled={editing || loading ? true : false}
                  loading={loading}
                  text="Update &amp; Finish"
                  onClick={() => this.handleClickSubmit(true, null)}
                /> */}
                {/* <button
                  type="button"
                  onClick={() => this.handleCancelAndClose(formikProps)}
                  className="btn btn-link waves-effect"
                  data-dismiss="modal"
                >
                  Close
                </button> */}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = ({ payroll, reason }) => ({
  payroll,
  loading: payroll.loading,
  formReset: payroll.formReset,
  dataChanged: payroll.formChanged,
  reasonData: reason.datatable,
  submitStatus: payroll.submitStatus,
  currentTab: payroll.currentTab,
  nextTab: payroll.nextTab,
  trigger: payroll.trigger,
});

const mapDispatchToProps = (dispatch) => ({
  updateRemarksLeaveDays: (params) => dispatch(updateRemarksLeaveDays(params)),
  formChanged: (params) => dispatch(formChanged(params)),
  submitTab: (submitStatus, closeModal, currentTab, nextTab) =>
    dispatch(submitTab(submitStatus, closeModal, currentTab, nextTab)),
  resetSubmit: (closeModal) => dispatch(resetSubmit(closeModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemarkSection);
