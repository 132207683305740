import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useField, useFormikContext } from 'formik';
import { numericKeypress, autoDecimal } from '../../helpers';
import { convDate } from '../../helpers';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export const InputLabel = ({ ...props }) => {

    const [field, meta, helpers] = useField(props);

    const handleKeypress = (e) => {
        numericKeypress(e, props.maxDigit ? props.maxDigit : 12);
    }

    const handleCurrencyBlur = (e) => {
        let value = e.target.value;
        if(value) {
            e.target.value = autoDecimal(e.target.value);
            helpers.setValue(e.target.value);
        }
    }

    const renderInputType = (type) => {
        if(type === 'number') {
            return (
                <input {...field} {...props}
                    onKeyDown={handleKeypress}
                    min="0"
                    placeholder="0"
                />
            );
        } else if(type === 'currency') {
            return (
                <input {...field} {...props}
                    onKeyDown={handleKeypress}
                    onBlur={handleCurrencyBlur}
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                />
            );
        } else {
            return (
                <input {...field} {...props} />
            );
        }
    }

    return (
        <div>
            <label htmlFor={props.name}>
                {props.label} {props.mandatory ? <span className="mandatory-icon">*</span> : ''}
            </label>
            <div className="form-group">
                <div className={`${props.line === false ? '' : 'form-line'} ${meta.error ? 'error' : ''}`}>
                    {renderInputType(props.type)}
                </div>
                { 
                    props.note ? 
                    <label id={`note-`+props.name} className="input-note" htmlFor={props.name}>{ '* '+props.note}</label> :
                    null
                }
                {
                    meta.touched && meta.error ? 
                    <label id={props.name} className="error" htmlFor={props.name}>{meta.error}</label> :
                    null
                }
            </div>
        </div>
    );
};

export const Input = ({ ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className="input-group">
            <span className="input-group-addon"><i className="material-icons">{props.icon}</i></span>
            <div className={`form-line ${meta.error ? 'error' : ''}`}>
                <input className="text-input" {...field} {...props} />
            </div>
            {meta.touched && meta.error ? (
                    <label id={props.name} className="error" htmlFor={props.name}>{meta.error}</label>
                ) : null
            }
        </div>
    );
};

export const InputFloat = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className="form-group form-float">
            <div className={`form-line ${meta.error ? 'error' : ''}`}>
                <input className="text-input" {...field} {...props} />
                <label className="form-label" htmlFor={props.name}>{props.name}</label>
            </div>
            <div className="help-info">{props.helpInfo ? props.helpInfo : ''}</div>
            {meta.touched && meta.error ? (
                    <label id={props.name} className="error" htmlFor={props.name}>{meta.error}</label>
                ) : null
            }
        </div>
    );
};

export const Select = ({ line, mandatory, label, keyPair, onlyValue, showLabel, noMargin, ...props }) => {
    const [field, meta] = useField(props);
    const renderOptions = (options) => {
        return options.map((value) => {
            // destructure the record based on keyPair array value
            // eg: keyPair = ['id', 'name'] will take (id => name) from the record
            const {[keyPair[0]]: id, [keyPair[1]]: text} = value;

            return onlyValue ? //special handler for currencies, return value=string not ID
                (<option key={id} value={text}>{text}</option>) :
                (<option key={id} value={id}>{text}</option>)
        });
    }

    return (
        <div>
            {
                showLabel !== false ? 
                (
                    <label htmlFor={props.name}>
                        {label} {mandatory ? <span className="mandatory-icon">*</span> : ''}
                    </label>
                ) : ''
            }
            <div className={`form-group ${noMargin ? 'm-0' : ''}`}>
                <div className={`${line ? 'form-line' : ''} ${meta.error ? 'error' : ''}`}>
                    <select className={`form-control ${line ? ' show-tick' : ''}`} as="select" {...field} {...props}>
                        <option value="">{props.placeholder}</option>
                        {renderOptions(props.data)}
                    </select>
                </div>
                {meta.touched && meta.error ? (
                        <label id={props.name} className="error" htmlFor={props.name}>{meta.error}</label>
                    ) : null
                }
            </div>
        </div>
    );
};

export const Datepicker = ({...props}) => {
    const initialDate = props.setValue || props.setValue === undefined ? props.setValue : new Date();
    const [startDate, setStartDate] = useState(initialDate);
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();
    const rootElement = document.querySelector('#root');
    const calendarRef = React.useRef();
    const PopperContainer = ({ children }) => (ReactDOM.createPortal(children, rootElement));
   
    const handleClickIcon = () => {
        calendarRef.current.setOpen(true);
    }

    const handleChange = (date) => {
        setStartDate(date);
        setFieldValue(field.name, convDate(date));

        if(props.onChange) {
            return props.onChange({name: props.name, value: convDate(date)}); 
        }
    }

    return (
        <div>
            <div className={`input-group datepicker ${props.className ? props.className : ''}`}>
                <span className={`label-dt-filter ${props.labelClass ? props.labelClass : ''}`}>
                    <label>{props.label} {props.mandatory ? <span className="mandatory-icon">*</span> : ''}</label>
                </span>
                <div className="form-line">
                    <DatePicker
                        className="form-control"
                        selected={startDate}
                        placeholderText={props.placeholder}
                        dateFormat={props.type === 'monthyear' ? "MM-yyyy" : "dd-MM-yyyy"}
                        showMonthYearPicker={props.type === 'monthyear' ? true : false}
                        showDisabledMonthNavigation
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                        onChange={handleChange}
                        popperContainer={PopperContainer}
                        disabled={props.disabled ? true : false}
                        ref={calendarRef}
                        isClearable
                    /> 
                </div>
                { props.icon ? ( <span className="input-group-addon absolute-icon" onClick={handleClickIcon}><i className="material-icons">{props.icon}</i></span>) : '' }
                {
                    meta.error ? (
                    <label id={props.name} className="error" htmlFor={props.name}>{meta.error}</label>
                    ) : null
                }
            </div>
        </div>
    );
};

export const InputTable = ({allowMinus, maxDigit, ...props}) => {

    const handleKeypress = (e) => {
        const minus = allowMinus ? true : false;
        numericKeypress(e, maxDigit ? maxDigit: 12, true, minus);
    }

    const handleChange = (event) => {
        const keyword = event.target.value;
        if(props.name) {
            props.onBlur({oldname: props.defaultValue, name: props.name, value:keyword});
        } else {
            props.onBlur(keyword);
        }
    }

    const renderInputType = (type) => {
        if(type === 'number') {
            return (
                <input
                    className={`${props.classCustom ? props.classCustom : ''} ${props.disabled ? ' disabled' : ''}`}
                    type="text"
                    onBlur={handleChange}
                    onFocus={props.onFocus}
                    onKeyDown={handleKeypress}
                    defaultValue={props.defaultValue}
                    min="0"
                    placeholder="0"
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                />
            );
        } else if(type === 'currency') {
            return (
                <input
                    type="text"
                    className={`${props.classCustom ? props.classCustom : ''} ${props.disabled ? ' disabled' : ''}`}
                    onBlur={handleChange}
                    onFocus={props.onFocus}
                    onKeyDown={handleKeypress}
                    defaultValue={props.defaultValue}
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                />
            );
        } else {
            return (
                <input
                    type={props.hidden ? 'hidden' : 'text'}
                    className={`${props.classCustom ? props.classCustom : ''} ${props.disabled ? ' disabled' : ''}`}
                    defaultValue={props.defaultValue}
                    onBlur={handleChange}
                    onFocus={props.onFocus}
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                />
            );
        }
    }

    return renderInputType(props.type);
};

export const TextAreaLabel = ({ noMargin, ...props }) => {

    const [field, meta] = useField(props);
    return (
        <div>
            <label htmlFor={props.name}>
                {props.label} {props.mandatory ? <span className="mandatory-icon">*</span> : ''}
            </label>
            <div className={`form-group ${noMargin ? 'm-0' : ''}`}>
                <div className={`${props.line === false ? '' : 'form-line'} ${meta.error ? 'error' : ''}`}>
                    <textarea className="form-control" {...field} {...props} />
                </div>
                { 
                    props.note ? 
                    <label id={`note-`+props.name} className="input-note" htmlFor={props.name}>{ '* '+props.note}</label> :
                    null
                }
                {
                    meta.touched && meta.error ?
                    (
                        <label id={props.name} className="error" htmlFor={props.name}>{meta.error}</label>
                    ) : null
                }
            </div>
        </div>
    );
};

export const InlineInput = ({ classRow, classLabel, classInput, removeMargin, afterLabel, classElem, ...props}) => {
    const [field, meta] = useField(props);
    return (
        <div className={`row ${classRow ? classRow : ''}`}>
            <div className={`
                ${classLabel ? classLabel : 'col-lg-2 col-md-2 form-control-label' } 
                ${props.align ? props.align : ''} 
                ${removeMargin ? 'm-0' : ''}
            `}>
                <label htmlFor={props.name}>{props.label ? props.label : ''}</label>
            </div>
            <div className={`${classInput ? classInput : 'col-lg-10 col-md-10' } ${removeMargin ? 'm-0' : ''}`}>
                <div className={`form-group ${removeMargin ? 'm-0' : ''}`}>
                    <div className={`${props.line === false ? '' : 'form-line'}`}>
                        <input 
                            className={`form-control ${classElem ? classElem: ''}`} 
                            type={props.type ? props.type : 'text'}
                            {...field} 
                            {...props}
                        />
                    </div>
                    {
                        meta.touched && meta.error ?
                        ( <label id={props.name} className="error" htmlFor={props.name}>{meta.error}</label>)
                        : null
                    }
                </div>
            </div>
            {
                afterLabel ?
                ( <label className="label-after">{afterLabel}</label>)
                : null
            }
        </div>
    );
}