import { CURRENCY_STATE } from './action';

const INITIAL_STATE = {
    dtLoading: true,
    datatable: [],
    isError: false,
    errorMessage: {},
};

const parseCurrencyResponse = (response) => {
    let newArray = [];
    response.map((value) => {
        newArray.push({id: value.id, name: value.code });
        return value;
    });
    return newArray;
}

const currencyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CURRENCY_STATE.REQUEST:
            return {
                ...state,
                dtLoading: true,
            };
        case CURRENCY_STATE.REQUEST_FAILED:
            return {
                ...state,
                dtLoading: false,
                datatable: []
            };
        case CURRENCY_STATE.FETCH_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                datatable: action.payload.contents.currencies ? parseCurrencyResponse(action.payload.contents.currencies) : []
            };
        default:
            return state;
    }
};

export default currencyReducer;
