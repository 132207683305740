import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { forgotPassword } from './action';
import { Button } from '../../components/ui';
import { Alert } from '../../components/alert';
import { showPopup } from '../../helpers';
import { Typography, Grid } from '@material-ui/core';
import { MInput } from '../../components/material/form';

class ForgotPasswordPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'forgot-password',
            formData: {
                email: ''
            }
        };
    }

    UNSAFE_componentWillMount() {
        const userAlreadyLoggedIn = this.props.auth.token;
        if(userAlreadyLoggedIn) {
            this.props.history.push('/payroll-transaction');
            showPopup('warning', 'Hmm...', 'You are already logged in. Please log out to continue');
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(!nextProps.loading && !this.props.success && nextProps.success === 'email-sent') {
            this.setState({formType: 'email-sent'});
        }
    }

    handleSubmit = (params, { setErrors, resetForm }) => {
        this.props.forgotPassword(params, setErrors, resetForm);
    };

    handleReset = () => {
        this.setState({formType: 'forgot-password'});
    }

    handleBackToLogin = () => {
        this.props.history.push('/login');
    }

    renderForm = () => {
        const { formData } = this.state;
        const { loading } = this.props;

        const validation = Yup.object({
            email: Yup.string().required('Email is required').email('Incorrect email format'),
        });

        return (
            <Formik
                initialValues={formData}
                validationSchema={validation}
                onSubmit={this.handleSubmit}
                enableReinitialize
            >
                {() => (
                    <Form>
                        <div className="forgot-password-msg">
                            Enter your email address that you used to register. We'll send you an email with your username and a
                            link to reset your password.
                        </div>
                        <MInput
                            label="E-mail"
                            name="email"
                            type="email"
                            placeholder="Enter email address"
                            required
                            fullWidth
                        />
                        <Button
                            classCustom={'btn-block btn-lg btn-forgot-pass'}
                            color={'bg-green'}
                            type={'submit'}
                            disabled={loading}
                            loading={loading}
                            text={'Send Email'}
                            icon={'email'}
                        />
                        <Grid container className="m-t-15 text-center">
                            <Grid item xs>
                                <Link to={"/login"}>Back to Login</Link>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    };

    renderSuccess = () => {
        return (
            <div>
                <div className="forgot-password-msg">
                    Email successfully sent to you. Follow the instructions on the email to change the password
                </div>
                <div className="row m-t-20 m-b-10 align-center">
                    <button className="btn btn-link" onClick={this.handleReset}>Not receiving Email ? Click to resubmit</button>
                </div>
                <Button
                    classCustom={'btn-block btn-lg'}
                    color={'bg-green'}
                    text={'Back to Login'}
                    icon={'keyboard_backspace'}
                    onClick={this.handleBackToLogin}
                />
            </div>
        );
    };

    render() {
        const { error, errorMessage } = this.props;
        const { formType } = this.state;

        return (
            <div>
                <Alert text={error === 'post' && errorMessage ? errorMessage : ''}/>
                <Typography className="login-sub-title" component="h1" variant="h5">Forgot your password ?</Typography>
                { 
                    formType === 'forgot-password' ?
                    this.renderForm() :
                    this.renderSuccess()
                }
            </div>
        );
    }
}

const mapStateToProps = ({ auth, forgotPassword }) => ({
    auth,
    loading: forgotPassword.loading,
    error: forgotPassword.error,
    errorMessage: forgotPassword.errorMessage,
    success: forgotPassword.success,
});

const mapDispatchToProps = dispatch => ({
    forgotPassword: (params, setErrors, resetForm) => dispatch(forgotPassword(params, setErrors, resetForm)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordPage);


