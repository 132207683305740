import { BASIC_WAGE_STATE } from './action';

const INITIAL_STATE = {
    dtLoading: true,
    datatable: [],
    error: null,
    errorMessage: null,
    openModal: false,
    modalLoading: false,
    reload: false,
    success: null,
    data: null,
};

const basicWageReducer = (state = INITIAL_STATE, action) => {
    const loading = action.requestType === 'dt' ? 'dtLoading' : 'modalLoading';
    switch (action.type) {
        case BASIC_WAGE_STATE.REQUEST:
            return {
                ...state,
                [loading]: true,
                success: null,
                error: null,
                errorMessage: null,
                reload: false,
            };
        case BASIC_WAGE_STATE.REQUEST_FAILED:
            const errMsg = action.requestType === 'validate' ? null : action.payload;
            return {
                ...state,
                [loading]: false,
                error: action.requestType,
                errorMessage: errMsg,
            };
        case BASIC_WAGE_STATE.FETCH_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                datatable: action.payload.contents.basicWages
            };
        case BASIC_WAGE_STATE.CREATE_SUCCESS:
            return {
                ...state,
                openModal: false,
                modalLoading: false,
                reload: true,
                success: 'create',
                data: action.payload,
            };
        case BASIC_WAGE_STATE.UPDATE_SUCCESS:
            return {
                ...state,
                openModal: false,
                modalLoading: false,
                reload: true,
                success: 'update',
                data: action.payload,
            };
        case BASIC_WAGE_STATE.DELETE_SUCCESS:
            return {
                ...state,
                openModal: false,
                modalLoading: false,
                reload: true,
                success: 'delete',
            };
        default:
            return state;
    }
};

export default basicWageReducer;
