import { REASON_STATE } from './action';

const INITIAL_STATE = {
    loading: true,
    datatable: [],
    success: null,
    error: null,
    errorMessage: null,
};

const reasonReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REASON_STATE.REQUEST:
            return {
                ...state,
                dtLoading: true,
            };
        case REASON_STATE.REQUEST_FAILED:
            return {
                ...state,
                dtLoading: false,
                datatable: []
            };
        case REASON_STATE.FETCH_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                datatable: action.payload.contents.reasons
            };
        default:
            return state;
    }
};

export default reasonReducer;
