import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAuditTrail } from './action';
import AuditTrailDatatable from './datatable';

class AuditTrailPage extends Component {

    componentDidMount() {
        this.fetchDatatable();
    }

    fetchDatatable = (limit, page) => {
        this.props.getAuditTrail({limit, page});
    }

    render() {
        const { dtLoading, datatable } = this.props;
        return (
            <div className="page-container">
                <AuditTrailDatatable
                    isLoading={dtLoading}
                    dataSource={datatable}
                    reloadDatatable={this.fetchDatatable}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ auditTrail }) => ({
    dtLoading: auditTrail.dtLoading,
    datatable: auditTrail.datatable,
    success: auditTrail.success,
    error: auditTrail.error,
    errorMessage: auditTrail.errorMessage,
});

const mapDispatchToProps = dispatch => ({
    getAuditTrail: (params) => dispatch(getAuditTrail(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuditTrailPage);


