import { AUTH } from './../../services';
import { transformObject } from '../../helpers';

export const LOGIN_STATE = {
    REQUEST: 'LOGIN_REQUEST',
    REQUEST_FAILED: 'LOGIN_REQUEST_FAILED',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
};

const requestingLogin = (requestType) => ({
    type: LOGIN_STATE.REQUEST,
    requestType,
});

const requestFailed = (requestType, response) => ({
    type: LOGIN_STATE.REQUEST_FAILED,
    requestType,
    payload: response,
});

const loginSuccess = (response) => ({
    type: LOGIN_STATE.LOGIN_SUCCESS,
    payload: response,
});

const logoutSuccess = (response) => ({
    type: LOGIN_STATE.LOGOUT_SUCCESS,
    payload: response,
});

export const login = (params, setErrors) => {
    return (dispatch) => {
        dispatch(requestingLogin('login'));
        AUTH.postLogin(params)
            .then(
                response => {
                    dispatch(loginSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    const code = response.meta.code;
                    if(code === 422) {
                        const errMsg = transformObject(response.body.message);
                        setErrors(errMsg);
                        dispatch(requestFailed('validate', errMsg));
                    } else {
                        dispatch(requestFailed('login', response.body.message));
                    }
                }
            );
    };
};

export const logout = () => {
    return dispatch => {
        dispatch(requestingLogin('logout'));
        AUTH.postLogout()
            .then(
                response => {
                    dispatch(logoutSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestFailed('logout', response.body.message));
                }
            );
    };
};