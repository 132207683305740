import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { getProfile, updateProfile } from './action';
import { showToast } from '../../helpers';
import { Button, Tabs } from '../../components/ui';
import { MInputInline } from '../../components/material/form';
import Modal from '../../components/modal';

class ProfileModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formDataEmail: { email: '' },
            formDataPassword: { 
                current_password: '',
                password: '',
                password_confirmation: '',
            },
            showError: false,
            activeTab: 'email',
        }
        this.formikEmail = React.createRef();
        this.formikPassword = React.createRef();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.authUser.email && this.state.formDataEmail.email !== nextProps.authUser.email) {
            this.setState({ formDataEmail: {email: nextProps.authUser.email} });
        }

        if(!this.props.success &&  nextProps.success === 'update' && !nextProps.loading) {
            this.props.handleClose();
            showToast('success', 'Account successfully updated');
        }

        if(!this.props.error && nextProps.error !== 'validate') {
            this.setState({ showError: true });
        }
    }

    handleCancelAndClose = () => {
        this.props.handleClose();
        this.setState({ showError: false });
    }

    handleChangeTab = (tab) => {
        this.setState({ activeTab: tab, showError: false }); //remove global error message on tab change 
    }

    handleClickSubmit = (formikRef) => {
        const formikProps = formikRef.current;
        formikProps.handleSubmit();
    }

    handleSubmit = (params, { setErrors }) => {
        const { activeTab } = this.state;
        params.id = this.props.authUser.id;
        if(activeTab === 'password') params.email = this.state.formDataEmail.email; 
        this.props.updateProfile(params, setErrors);
    }

    render() {
        const { loading, isOpen, errorMessage } = this.props;
        const { activeTab, formDataEmail, formDataPassword, showError } = this.state;

        const renderTabEmail = () => {

            const validateEmail =  Yup.object({
                email: Yup.string()
                    .email('Email is invalid, please input your valid email format')
                    .required('Email is required'),
            });

            return (
                <Formik
                    innerRef={this.formikEmail}
                    initialValues={formDataEmail}
                    validationSchema={validateEmail}
                    onSubmit={this.handleSubmit}
                    enableReinitialized
                >
                    { () => (
                        <Form autoComplete="off" className={'form-horizontal'}>
                            <MInputInline
                                classRow={'account-row'}
                                classLabel={'col-md-4 account-label'}
                                classInput={'col-md-8'}
                                label={'E-mail'}
                                name={'email'}
                            />
                            <div className="form-group">
                                <div className="col-sm-offset-4 col-sm-8 btn-profile">
                                    <Button
                                        classCustom={'btn-lg'}
                                        color={'bg-blue'}
                                        type={'submit'}
                                        disabled={loading}
                                        loading={loading}
                                        text={'Save'}
                                        onClick={() => this.handleClickSubmit(this.formikEmail)}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            );
        }

        const renderTabPassword = () => {

            const validatePassword =  Yup.object({
                current_password: Yup.string().required('Please fill in your current password'),
                password: Yup.string().required('Please fill in your new password'),
                password_confirmation: Yup.string().required('Please fill in your new password confirmation'),
            });

            return (
                <Formik
                    innerRef={this.formikPassword}
                    initialValues={formDataPassword}
                    validationSchema={validatePassword}
                    onSubmit={this.handleSubmit}
                    enableReinitialized
                >
                    { () => (
                        <Form autoComplete="off" className={'form-horizontal'}>
                            <MInputInline
                                classRow={'account-row'}
                                classLabel={'col-md-4 account-label'}
                                classInput={'col-md-8'}
                                type={'password'}
                                label={'Current Password'}
                                name={'current_password'}
                            />
                            <MInputInline
                                classLabel={'col-md-4 account-label'}
                                classInput={'col-md-8'}
                                type={'password'}
                                label={'New Password'}
                                name={'password'}
                            />
                            <MInputInline
                                classLabel={'col-md-4 account-label'}
                                classInput={'col-md-8'}
                                type={'password'}
                                label={'New Password (Confirm)'}
                                name={'password_confirmation'}
                            />
                            <div className="form-group">
                                <div className="col-sm-offset-4 col-sm-8 btn-profile">
                                    <Button
                                        classCustom={'btn-lg'}
                                        color={'bg-blue'}
                                        type={'submit'}
                                        disabled={loading}
                                        loading={loading}
                                        text={'Save'}
                                        onClick={() => this.handleClickSubmit(this.formikPassword)}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            );
        }

        return (
            <Modal
                isOpen={isOpen}
                background={'bg-light-green'}
                size={'modal-md'}
                title={'Update User Account'}
                onClose={this.handleCancelAndClose}
                errorMessage={showError ? errorMessage : undefined}
                content={(
                    <Tabs
                        activeTab={activeTab}
                        handleChangeTab={this.handleChangeTab}
                        tabs={[
                            {name: 'email', text: 'Account Email'},
                            {name: 'password', text: 'Change Password'},
                        ]}
                        contents={[
                            {name: 'email', content: renderTabEmail()},
                            {name: 'password', content: renderTabPassword()}
                        ]}
                    />
                )}
                footer={(
                    <div>
                        <Button
                            classCustom={'btn-link'}
                            color={'bg-white'}
                            type={'button'}
                            text={'Cancel'}
                            onClick={this.handleCancelAndClose}
                        />
                    </div>
                )} 
            />
        );
    }
}

const mapStateToProps = ({ auth, profile }) => ({
    authUser: auth.user,
    loading: profile.loading,
    success: profile.success,
    error: profile.error,
    errorMessage: profile.errorMessage,
});

const mapDispatchToProps = dispatch => ({
    getProfile: (params) => dispatch(getProfile(params)),
    updateProfile: (params, setErrors) => dispatch(updateProfile(params, setErrors)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileModal);