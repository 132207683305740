import { GENERATE_STATE } from './action';

const INITIAL_STATE = {
    loading: false,
    dtLoading: false,
    crewDatatable: [],
    crewIds: [],
    existedIds: [],
    request: null,
    error: null,
    errorMessage: null,
    success: null,
};

const generateReducer = (state = INITIAL_STATE, action) => {
    const loading = action.requestType === 'dt-crew' ? 'dtLoading' : 'loading';
    switch (action.type) {
        case GENERATE_STATE.REQUEST:
            return {
                ...state,
                [loading]: true,
                error: null,
                errorMessage: null,
                success: null,
                request: action.requestType
            };
        case GENERATE_STATE.REQUEST_FAILED:
            return {
                ...state,
                [loading]: false,
                error: action.requestType,
                errorMessage: action.payload,
                request: null,
            };
        case GENERATE_STATE.FETCH_CREW_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                success: action.requestType,
                crewDatatable: action.payload.contents.crews,
                crewIds: action.payload.contents.ids,
                existedIds: action.payload.contents.existedIds,
            };
        case GENERATE_STATE.GENERATE_PAYROLL_SUCCESS:
            return {
                ...state,
                loading: false,
                request: null,
                success: 'generate',
                crewDatatable: [],
                crewIds: [],
                existedIds: [],
            };
        default:
            return state;
    }
};

export default generateReducer;
