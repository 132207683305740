import { GENERAL_SETTING } from '../../services';
import { transformObject } from '../../helpers';

export const GENERAL_SETTING_STATE = {
    REQUEST: 'GENERAL_SETTING_REQUEST',
    REQUEST_FAILED: 'GENERAL_SETTING_REQUEST_FAILED',
    FETCH_SUCCESS: 'GENERAL_SETTING_FETCH_SUCCESS',
    UPDATE_SUCCESS: 'GENERAL_SETTING_UPDATE_SUCCESS',
};

const requesting = (requestType, response) => ({
    type: GENERAL_SETTING_STATE.REQUEST,
    requestType,
    payload: response
});

const requestFailed = (requestType, error) => ({
    type: GENERAL_SETTING_STATE.REQUEST_FAILED,
    requestType,
    payload: error
});

const fetchingSuccess = (requestType, response) => ({
    type: GENERAL_SETTING_STATE.FETCH_SUCCESS,
    requestType,
    payload: response
});

const updatingSuccess = (requestType, response) => ({
    type: GENERAL_SETTING_STATE.UPDATE_SUCCESS,
    requestType,
    payload: response
});

export const getSettingValues = (params) => {
    return dispatch => {
        dispatch(requesting('get-values'));
        GENERAL_SETTING.getSettings(params)
            .then(
                response => {
                    dispatch(fetchingSuccess('get-values', response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestFailed('get-values', response.body.message));
                }
            );
    };
};

export const updateSetting = (params, setErrors) => {
    return async dispatch => {
        dispatch(requesting('update'));
        await GENERAL_SETTING.putSettings(params)
            .then(
                response => {
                    dispatch(updatingSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    const code = response.meta.code;
                    if(code === 422) {
                        const errMsg = transformObject(response.body.message);
                        setErrors(errMsg);
                        dispatch(requestFailed('validate', errMsg));
                    } else {
                        dispatch(requestFailed('update', response.body.message));
                    }
                }
            );
    };
};

