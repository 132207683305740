import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { sendAllPayslip } from '../action';
import { showPopup, showToast } from '../../../helpers';
import { TextAreaLabel } from '../../../components/forms';
import { BtnSpinner } from '../../../components/ui';
import { MInput } from '../../../components/material/form';
import InputCreateable from '../../../components/creatable-select';
import Modal from '../../../components/modal';

class PreviewModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ids: [],
            data: [],
            ccEmail: [],
            pdfShow: null,
            emailShow: null,
            subjectShow: null,
            messageShow: null,
            replyTo: null,
            from: "payroll@ebu.co.id",
            page: 1,
            maxPage: 1,
            dataChanged: false,
            defaultCCLoaded: false,
        };
        this.initialState = this.state;
    }

    componentDidMount() {
        this.renderPDF(this.props.dataSource.data);
        this.getIds(this.props.dataSource.data);
        this.setState({ 
            ccEmail: this.convertCreatableInput(this.props.dataSource.ccEmail),
            replyTo: this.props.dataSource.replyTo,
            defaultCCLoaded: true,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.page && this.state.page !== prevState.page) {
            this.setState({
                pdfShow: this.state.data[this.state.page-1].file,
                emailShow: this.state.data[this.state.page-1].email,
                subjectShow: this.state.data[this.state.page-1].subject,
                messageShow: this.state.data[this.state.page-1].message || '',
            });
        }
    }

    convertCreatableInput = (array) => {
        return array.map((value) => {
            return {label: value, value};
        });
    }

    revertCreatable = (arrObj) => {
        return arrObj.map((value) => {
            return value.value;
        });
    }

    getIds = (data) => {
        const ids = data.map((value) => {
            return value.id;
        });
        this.setState({ ids });
    }

    showFirstData = () => {
        this.setState({
            pdfShow: this.state.data[0].file,
            emailShow: this.state.data[0].email,
            subjectShow: this.state.data[0].subject,
            messageShow: this.state.data[0].message || '',
        });
    }

    handleCancelAndClose = () => {
        if(this.state.dataChanged) {
            showPopup(
                'custom-confirm',
                "You haven't saved the data. Cancel the proceed anyway? Unsaved data may occurred",
                'Discard changes',
                'warning',
                'reversed',
                () => this.props.handleClose()
            )
        } else {
            this.props.handleClose();
        }
    }

    handleChange = (params) => {
        if(params.name === 'custom_cc' && params.value !== this.state.ccEmail) {
            const emails = params.value ? params.value : [];
            this.setState({ ccEmail: emails });
        } else if (params.name !== 'custom_cc' && params.target) {
            const index = this.state.page - 1;
            const value = params.target.value;
            const name = params.target.name;
            let data = this.state.data;

            if(name === 'email' || name === 'subject' || name === 'message') {
                data[index] = {...data[index], [name]: value };
                this.setState({ 
                    data,
                    [name+'Show']: value,
                });
            } else {
                this.setState({[name]: value});
            }
        }
        // this.setState({dataChanged: true});
    }

    handleNavigate = (nav) =>  {
        let page = null;
        if(nav === 'prev') {
            page = this.state.page < 2 ? this.state.page : this.state.page - 1;
        } else if (nav === 'next') {
            page = this.state.page === this.state.maxPage ? this.state.page : this.state.page + 1;
        }
        this.setState({ page });
    }

    handleSubmit = (params) =>  {
        params.ids = this.state.ids;
        params.cc_emails = this.revertCreatable(this.state.ccEmail);
        params.data = this.state.data;
        params.data = this.replaceEmptyMessage(params.data);

        const check = this.checkEmptyEmail(params.data);
        if (!check) return;

        const { to, ...restParams } = params; // remove 'to' parameter from params
        this.props.sendAllPayslip(restParams);
    }

    handleEnter = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    checkEmptyEmail = (data) => {
        const emptyEmailData = data.filter(data => !data.email);
        const names = emptyEmailData.map((value) => {
            return value.fullname;
        });

        if(names.length) {
            showToast('error', 'Please fill in email address for \n (<b> ' + names.toString() + ' </b>)')
            return false;
        }
        return true; 
    }

    replaceEmptyMessage = (dataSource) => {
        return dataSource.map((value) => {
            const message = value.message ? value.message : null;
            return { ...value, message };
        });
    }

    renderPDF = (data) => {
        const pdfPath = this.props.csc === 1 ? process.env.REACT_APP_API_PDF_CSC_PATH : process.env.REACT_APP_API_PDF_NONCSC_PATH;
        const maxPage = data.length;
        const newData = data.map((value, index) => {
            const file = process.env.REACT_APP_API_URL + pdfPath + value.file;
            return {...value, file }
        });

        this.setState(
            { maxPage, data: newData },
            ()=> this.showFirstData()
        ); 
    }

    render() {
        const { isOpen, loading } = this.props;
        const { from, emailShow, replyTo, pdfShow, subjectShow, messageShow, ccEmail, page, maxPage, defaultCCLoaded } = this.state;

        const validation = Yup.object({
            from: Yup.string()
                .required('Please fill in receiver email')
                .email("Invalid email format"),
            email: Yup.string()
                .required('Please fill in receiver email')
                .email("Invalid email format"),
            replyTo: Yup.string()
                .required('Please fill in reply email')
                .email("Invalid email format"),
        });

        const initialValues = {
            from,
            replyTo,
            email: emailShow,
            subject: subjectShow,
            message: messageShow,
        }

        return (
            <Modal
                isOpen={isOpen === 'preview' ? true : false}
                background={'bg-blue'}
                classBody={'preview'}
                size={'modal-90'}
                title={'Payslip Preview'}
                onClose={this.handleCancelAndClose}
                content={(
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={this.handleSubmit}
                        enableReinitialize
                    >
                        { () => (
                            <Form 
                                autoComplete="off"
                                onKeyDown={this.handleEnter}
                            >
                                <div className="row">
                                    <div className="col-lg-8 m-0">
                                        <h4 className="title-separator">Preview</h4>
                                        <div className="pdf-container">
                                            <object
                                                id='pdf_preview'
                                                aria-label='pdf-preview'
                                                aria-labelledby='pdf_preview'
                                                className="pdf-preview"
                                                width="100%"
                                                height="600"
                                                data={pdfShow}
                                                type="application/pdf"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 m-0">
                                        <h4 className="title-separator">Email Setting</h4>
                                        <MInput
                                            label="From"
                                            name="from"
                                            type="text"
                                            placeholder="Enter sender email address"
                                            fullWidth
                                            disabled
                                        />
                                        <MInput
                                            label="To"
                                            name="email"
                                            type="text"
                                            placeholder="Enter target email address"
                                            fullWidth
                                            onBlur={this.handleChange}
                                        />
                                        <MInput
                                            className='preview-reply'
                                            label="Reply Email"
                                            name="replyTo"
                                            type="text"
                                            placeholder="Enter email address for replying"
                                            fullWidth
                                            onBlur={this.handleChange}
                                        />
                                        {
                                            defaultCCLoaded  ?
                                            (
                                                <InputCreateable
                                                    label="Custom CC"
                                                    name="custom_cc"
                                                    className={'custom_cc'}
                                                    defaultValue={ccEmail && ccEmail.length ? ccEmail : []}
                                                    onChange={this.handleChange}
                                                    note={"Type email and press 'Enter' to add"}
                                                    noMargin
                                                />
                                            ) : ''
                                        }
                                        <MInput
                                            label="Custom Email Subject (Optional)"
                                            name="subject"
                                            type="text"
                                            placeholder="Enter email addres for replying"
                                            note={"Email subject will be replaced with this values"}
                                            fullWidth
                                            onBlur={this.handleChange}
                                        />
                                        <TextAreaLabel
                                            label="Custom Email Message (Optional)"
                                            className="form-control"
                                            name="message"
                                            rows={2}
                                            note={"Email message content will be added with this values in NOTE: (Message)"}
                                            onBlur={this.handleChange}
                                        />
                                        <div className="row">
                                            <div className="col-lg-12 m-0">
                                                <button
                                                    className={`btn btn-block bg-blue waves-effect ${ccEmail && ccEmail.length && !loading ? '' : ' disabled'}`}
                                                    disabled={ccEmail && ccEmail.length && !loading ? false : true}
                                                    type="submit"
                                                >
                                                    { loading ? <BtnSpinner/> : <i className="material-icons">send</i> }
                                                    <span>Send All</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>   
                )}
                footer={(
                    <div className="">
                        <div className="col-lg-8 col-md-8 m-0">
                            <div className="preview-navigator">
                                <div className="nav previous">
                                    <button
                                        className="btn bg-blue waves-effect btn-preview-nav"
                                        type="button"
                                        onClick={()=>this.handleNavigate('prev')}
                                    >
                                        <i className="material-icons">chevron_left</i>
                                        <span>Prev</span>
                                    </button>
                                </div>
                                <div className="nav preview-number">
                                    <span>{page} from {maxPage}</span>
                                </div>
                                <div className="nav next">
                                    <button
                                        className="btn bg-blue waves-effect btn-preview-nav"
                                        type="button"
                                        onClick={()=>this.handleNavigate('next')}
                                    >
                                        <span>Next</span>
                                        <i className="material-icons">chevron_right</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 m-0 btn-show-preview">
                            <button type="button" onClick={() => this.handleCancelAndClose()} className="btn btn-link waves-effect" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                )}
            />
        );
    }
}

const mapStateToProps = ({ payroll }) => ({
    payroll,
    loading: payroll.loading,
});

const mapDispatchToProps = dispatch => ({
    sendAllPayslip: (params) => dispatch(sendAllPayslip(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviewModal);