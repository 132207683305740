import { apiService } from '../config';

export function getUser(params) {
    const page = params.page ? `${params.page}` : '';
    const limit = params.limit ? `${params.limit}` : '';
    const filter = params.filter ? `${params.filter}` : '';
    return apiService.get(`/users?page=${page}&limit=${limit}&keyword=${filter}`);
};

export function postUser(params) {
    return apiService.post(`/users/store`,
        params,
    );
};

export function activateUser(params) {
    return apiService.put(`/users/change-status/${params.id}`,
        { status : params.status },
    );
};

export function deleteUser(params) {
    return apiService.delete(`/users/delete/${params.id}`);
};



