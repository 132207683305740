import { AUTH_STATE } from '../auth/action';
import { LOGIN_STATE } from './action';

const LOGIN_INITIAL_STATE = {
    loading: false,
    data: null,
    success: null,
    error: null,
    errorMessage: null,
};

const loginReducer = (state = LOGIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_STATE.REQUEST:
            return {
                ...state,
                loading: true,
                success: null,
                error: null,
                errorMessage: null,
            };
        case LOGIN_STATE.REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.requestType,
                errorMessage: action.payload,
            };
        case LOGIN_STATE.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'login',
            };
        case LOGIN_STATE.LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'logout',
            };
        case AUTH_STATE.TOKEN_EXPIRED:
            return {
                ...state,
                loading: false,
                success: null,
                error: 'login',
                errorMessage: action.payload,
            };
        default:
            return state;
    }
};

export default loginReducer;
