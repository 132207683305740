import { RANK_STATE } from './action';

const INITIAL_STATE = {
    dtLoading: true,
    datatable: [],
    isError: false,
    errorMessage: {},
};

const rankReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RANK_STATE.REQUEST:
            return {
                ...state,
                dtLoading: true,
            };
        case RANK_STATE.REQUEST_FAILED:
            return {
                ...state,
                dtLoading: false,
                datatable: []
            };
        case RANK_STATE.FETCH_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                datatable: action.payload.contents.ranks
            };
        default:
            return state;
    }
};

export default rankReducer;
