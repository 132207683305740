import { apiService } from '../config';

export function get(params) {
    const page = params.page ? `${params.page}` : '';
    const limit = params.limit ? `${params.limit}` : '';
    const filter = params.filter ? `${params.filter}` : '';
    return apiService.get(`/basic-wages?page=${page}&limit=${limit}&keyword=${filter}`);
};

export function create() {
    return apiService.get(`/basic-wages/create`);
};

export function post(params) {
    return apiService.post(`/basic-wages/store`,
        params
    );
};

export function edit(params) {
    return apiService.get(`/basic-wages/edit/${params.id}`);
};

export function put(params) {
    params.principal_id = params.principal;
    params.rank_id = params.rank;

    return apiService.put(`/basic-wages/update/${params.id}`,
        params,
    );
};

export function remove(params) {
    return apiService.delete(`/basic-wages/delete/${params.id}`);
};



