import React, { Component } from "react";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";

class PickList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedItems: [],
            autoRender: true,
        };
    }

    UNSAFE_componentWillMount() {
        if(this.props.defaultValue) {
            const selectedItems = this.findMatchValue(this.props.defaultValue); //set initial value ONCE, only before mount
            this.setState({ selectedItems });
        }
    }

    shouldComponentUpdate(nextProps) { 
        // DevNote : Be careful setting this values, this lifecycle is used to fix some BUG :
        // component keep rendering when search bar is used
        // just use props disableAutoRender={true} to disable auto rendering 

        if(nextProps.disableAutoRender) return false; 
        return true;
    }

    findMatchValue = (defaultValue) => {
        const { dataSource } = this.props;
        if(defaultValue) {
            return dataSource.filter(data => defaultValue.includes(data.id));
        }
        return [];
    }

    handleChange = (selectedItems) => {
        this.props.onChange({ name: this.props.name, value: selectedItems });
    }

    render() {
        const { selectedItems } = this.state;
        const { 
            dataSource, showSelectedItems, withGrouping, filterFunction, showSelectAll, showClearAll, customMessages 
        } = this.props;

        return (
            <MultiSelect
                items={dataSource}
                selectedItems={selectedItems}
                onChange={this.handleChange}
                filterFunction={filterFunction}
                showSelectedItems={showSelectedItems}
                withGrouping={withGrouping}
                showSelectAll={showSelectAll}
                showClearAll={showClearAll}
                messages={customMessages}
            />
        );
    }
}

export default PickList;