import React, { Component } from 'react';
import { Datatable } from '../../components/datatables';
import { dateFormatStd } from '../../helpers';

class AuditTrailDatatable extends Component {

    renderTableBody = (dataSource) => {
        if(Array.isArray(dataSource.data) && dataSource.data.length) {
            let { from } = dataSource;
            return dataSource.data.map((value) => {
                const { id, description, name, ip_address, created_at } = value;
                return (
                    <tr key={id}>
                        <td className="index-td">{from++}</td>
                        <td>{description}</td>
                        <td className="text-center">{name}</td>
                        <td className="text-center">{ip_address}</td>
                        <td className="text-center date">{dateFormatStd(created_at, 'DMYHI')}</td>
                    </tr>
                );
            });
        }
    }

    render() {
        const { dataSource, isLoading, reloadDatatable } = this.props;
        const datatableHeader = [
            {label: 'Action', field: 'description'},
            {label: 'Username', field: 'name'},
            {label: 'IP Address', field: 'ip_address'},
            {label: 'Date', field: 'created_at'},
        ];

        return (
            <Datatable
                isLoading={isLoading}
                tableHeader={datatableHeader}
                tableBody={this.renderTableBody}
                title={{main: 'Audit Trail', sub: 'Audit Trail'}}
                dataSource={dataSource}
                reloadDatatable={reloadDatatable}
                pagination={true}
                searchable={false}
                creatable={false}
                customHeader={() => {
                    return(
                        <div className="header">
                            <div className="d-flex">
                            </div>
                        </div>
                    );
                }}
            />
        );
    }
}

export default AuditTrailDatatable;


