import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { getSettingValues, updateSetting } from './action';
import { getAllPrincipal } from '../principal/action';
import { getAllVessel } from '../vessel/action';
import { showToast, convertCreatableInput, revertCreatable } from '../../helpers';
import { Button, Select2Filter, InlineContainer } from '../../components/ui';
import { InlineInput, Select } from '../../components/forms';
import InputCreateable from '../../components/creatable-select';
import PickList from '../../components/pick-list';

class GeneralSettingPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initialValues: {},
            formData: {
                smtp_host: null,
                smtp_port: null,
                smtp_username: null,
                smtp_password: null,
                smtp_from_address: null,
                smtp_from_name: null,
                active_principals_csc: null,
                active_principals_non_csc: null,
                active_vessels: null,
                default_cc: null,
                cut_off_date: null,
                password_expiration: 90,
            },
            dataLoaded: false,
        }
        this.formik = React.createRef();
    }

    UNSAFE_componentWillMount() {
        if(this.props.authUser.roles[0].id !== 1) {  // only superadmin access general settings
            this.props.history.push('/payroll-transaction');
        }
        
        if (Array.isArray(this.props.principalAllData) && this.props.principalAllData.length < 1) {
            this.props.getAllPrincipal();
        }
        
        this.props.getSettingValues();
        this.props.getAllVessel();
    }

    UNSAFE_componentWillReceiveProps(nextProps) { 
        if(nextProps.settings && !nextProps.loading) {
            const settings = nextProps.settings;
            const default_cc = convertCreatableInput(settings.default_cc); // parsing default_cc to react-select compatible value
            const smtp = this.getSMTPValues(settings.smtp);

            this.setState({ 
                dataLoaded: true,
                initialValues: { ...settings, ...smtp, default_cc },
                formData: { ...settings, ...smtp, default_cc },
            });
        }

        if(!this.props.success && nextProps.success === 'update' && !nextProps.loading) {
            showToast('success', 'General Settings successfully updated');
        }
    }

    getSMTPValues = (params) => {
        let newObj = {};
        if(params) {
            Object.entries(params).forEach((value) => {
                const name = 'smtp_'+value[0];
                newObj = { ...newObj, [name]: value[1] };
            });
        }
        return newObj;
    }

    handleChange = (params) => {
        const { formData } = this.state;

        if(params.name) {
            this.setState({ formData: { ...formData, [params.name]: params.value } });
        } else {
            const { name, value } = params.target;
            this.setState({ formData: { ...formData, [name]: value } });
        }
    }

    handleEnter = (keyEvent) => { // disable press enter to submit
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    handleSubmit = (params, formik) => {
        let { active_principals_csc, active_principals_non_csc, default_cc, active_vessels } = this.state.formData;
        params.default_cc = revertCreatable(default_cc); // parsing default_cc to react-select compatible value
        params.active_principals_non_csc = revertCreatable(active_principals_non_csc);
        params.active_principals_csc = revertCreatable(active_principals_csc);
        params.active_vessels = revertCreatable(active_vessels, 'id');

        params.smtp = {
            host: params.smtp_host,
            port: params.smtp_port,
            username: params.smtp_username,
            password: params.smtp_password,
            encryption: params.smtp_encryption,
            from_address: params.smtp_from_address,
            from_name: params.smtp_from_name,
        }

        this.props.updateSetting(params);
    }

    renderForm = () => {
        const { 
            default_cc, active_principals_csc, active_principals_non_csc, active_vessels,
            // cut_off_date
        } = this.state.formData;
        
        let { principalAllData, principalLoading, vesselAllData } = this.props;

        // const fillCutOff = () => {
        //     let array = new Array(30).fill().map((val,index) => {
        //         return {id: (index+1).toString(), label: (index+1).toString()}
        //     });

        //     return [
        //         {id: 'start', label: 'Start of month'},
        //         {id: 'end', label: 'End of month'},
        //         ...array,
        //     ];
        // }
        // const cutOffData = fillCutOff();

        let parsedVesselData = vesselAllData.map((value) => {
            const principal = value.principal ? value.principal.name : ''; 
            return { id: value.id, label: value.name, group: principal };
        });

        const encryptionData = [
            { id: 'tls', name: "tls" },
            { id: 'ssl', name: "ssl"},
        ];

        return (
            <div>
                <div className='row'>
                    <div className='setting-title'>Emails</div>
                    <div className='setting-title-desc'>SMTP and other email configuration</div>
                    <div className='col-md-6'>
                        <InlineContainer
                            classRow={'smtp-row'}
                            classLabel={'col-md-3'}
                            classInput={'col-md-9 smtp-col'}
                            label={'SMTP Settings'}
                            content={() => {
                                return(
                                    <div>
                                        <InlineInput
                                            classLabel={'col-md-4 smtp-label'}
                                            classInput={'col-md-8'}
                                            label={'Host'}
                                            name={'smtp_host'}
                                            removeMargin={true}
                                            onBlur={this.handleChange}
                                        />
                                        <InlineInput
                                            classLabel={'col-md-4 smtp-label'}
                                            classInput={'col-md-8'}
                                            label={'Port'}
                                            name={'smtp_port'}
                                            removeMargin={true}
                                            onBlur={this.handleChange}
                                        />
                                        <InlineContainer
                                            classLabel={'col-md-4 smtp-label m-0'}
                                            classInput={'col-md-8 m-0'}
                                            label={'Encryption'}
                                            content={() => (
                                                <Select
                                                    name="smtp_encryption"
                                                    placeholder="Select encryption type"
                                                    data={encryptionData}
                                                    keyPair={['id', 'name']}
                                                    line={'true'}
                                                    showLabel={false}
                                                    noMargin={true}
                                                    onBlur={this.handleChange}
                                                />
                                            )}
                                        />
                                        <InlineInput
                                            classLabel={'col-md-4 smtp-label'}
                                            classInput={'col-md-8'}
                                            label={'Username'}
                                            name={'smtp_username'}
                                            removeMargin={true}
                                            onBlur={this.handleChange}
                                        />
                                        <InlineInput
                                            classLabel={'col-md-4 smtp-label'}
                                            classInput={'col-md-8'}
                                            label={'Password'}
                                            name={'smtp_password'}
                                            type={'password'}
                                            removeMargin={true}
                                            onBlur={this.handleChange}
                                        />
                                        <InlineInput
                                            classLabel={'col-md-4 smtp-label'}
                                            classInput={'col-md-8'}
                                            label={'From Address'}
                                            name={'smtp_from_address'}
                                            removeMargin={true}
                                            onBlur={this.handleChange}
                                        />
                                        <InlineInput
                                            classLabel={'col-md-4 smtp-label'}
                                            classInput={'col-md-8'}
                                            label={'From Name'}
                                            name={'smtp_from_name'}
                                            removeMargin={true}
                                            onBlur={this.handleChange}
                                        />
                                    </div>
                                );
                            }}
                        />
                    </div>
                    <div className='col-md-6'>
                        <InlineContainer
                            classLabel={'col-md-3'}
                            classInput={'col-md-9'}
                            label={'Default Payslip Email Cc'}
                            content={() => (
                                <InputCreateable
                                    name={"default_cc"}
                                    className={'custom_cc'}
                                    defaultValue={default_cc}
                                    onChange={this.handleChange}
                                    note={"Type email and press 'Enter' to add"}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='setting-title'>Password</div>
                    <div className='setting-title-desc'>Password expiration duration and other password related settings</div>
                    <div className='col-md-12'>
                        <InlineInput
                            classLabel={'col-md-3 password-exp-label'}
                            classInput={'col-md-2'}
                            classElem={'text-center'}
                            label={'Password Expiration Duration'}
                            name={'password_expiration'}
                            afterLabel={'(days)'}
                            onBlur={this.handleChange}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='setting-title'>Active Principal</div>
                    <div className='setting-title-desc'>List of managed principals and csc type management (Note: Select principals for each active managed category)</div>
                    <div className='col-md-6'>
                        <InlineContainer
                            classLabel={'col-md-3 active-principal-label'}
                            classInput={'col-md-9'}
                            label={'Non CSC'}
                            content={() => (
                                <Select2Filter
                                    className={'active-principal'}
                                    customPrefix={'active_principal'}
                                    name={'active_principals_non_csc'}
                                    placeholder="Select principal or type to search"
                                    keyPair={['id', 'name']}
                                    data={principalAllData}
                                    onChange={this.handleChange}
                                    defaultValue={active_principals_non_csc}
                                    loading={principalLoading}
                                    detailedValue={true}
                                    isMulti
                                />
                            )}
                        />
                    </div>
                    <div className='col-md-6'>
                        <InlineContainer
                            classLabel={'col-md-3 active-principal-label'}
                            classInput={'col-md-9'}
                            label={'CSC'}
                            content={() => (
                                <Select2Filter
                                    className={'active-principal'}
                                    customPrefix={'active_principal'}
                                    name={'active_principals_csc'}
                                    placeholder="Select principal or type to search"
                                    keyPair={['id', 'name']}
                                    data={principalAllData}
                                    onChange={this.handleChange}
                                    defaultValue={active_principals_csc}
                                    loading={principalLoading}
                                    detailedValue={true}
                                    isMulti
                                />
                            )}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='setting-title'>Active Vessel</div>
                    <div className='setting-title-desc'>List of managed vessels (Note: check to activate vessel, use searchbar to filter vessels by principal)</div>
                    <div className='col-md-6 setting-vessel-header'><strong>Vessel List from CMS</strong></div>
                    <div className='col-md-6 setting-vessel-header'><strong>Active Vessel</strong></div>
                    <div className='col-md-12'>
                        <PickList
                            name={'active_vessels'}
                            dataSource={parsedVesselData}
                            defaultValue={active_vessels}
                            withGrouping={true}
                            showSelectAll={false}
                            showClearAll={false}
                            onChange={this.handleChange}
                            customMessages={{
                                searchPlaceholder: 'Search by principal name ...'
                            }}
                            filterFunction={(value) => (item) =>
                                String(item.group)
                                    .toLowerCase()
                                    .includes(value.toLowerCase())
                            }
                            disableAutoRender={true} // fix bug component keep rerendering, when search bar is used
                        />
                    </div>
                </div>
                        
                {/* <InlineContainer
                    classInput={'col-md-2'}
                    label={'Cut Off Date'}
                    content={() => (
                        <Select2Filter
                            className={'cut-off-date'}
                            name={'cut_off_date'}
                            placeholder="Select date"
                            keyPair={['id', 'label']}
                            data={cutOffData}
                            onChange={this.handleChange}
                            defaultValue={cut_off_date.toString()}
                            loading={false}
                            detailedValue={true}
                        />
                    )}
                /> */}

            </div>
        );
    }

    render () { 
        const { loading } = this.props;
        const { dataLoaded } = this.state;
        const initialValues = this.state.initialValues;
        
        const validation = Yup.object({
            smtp_host: Yup.string().required('Please fill in hostname'),
            smtp_port: Yup.number('Port must be number').typeError('Port must be a number').required('Please fill in port number'),
            smtp_username: Yup.string().required('Please fill in username'),
            smtp_password: Yup.string().required('Please fill in password'),
            smtp_encryption: Yup.string().required('Please fill in encryption type'),
            smtp_from_address: Yup.string().required('Please fill in source address'),
            smtp_from_name: Yup.string().required('Please fill in source name'),
            password_expiration: Yup.string().required('Please fill in password duration'),
        });

        return (
            <div className="page-container">
                <div className="container-fluid">
                    <div className="block-header">
                        <h2>
                            General Setting
                            <small>Application General Settings</small>
                        </h2>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="card">
                                <div className="body">
                                    <div className="container-setting">
                                        <Formik
                                            innerRef={this.formik}
                                            initialValues={initialValues}
                                            validationSchema={validation}
                                            onSubmit={this.handleSubmit}
                                            enableReinitialize
                                        >
                                            { () => (
                                                <Form 
                                                    autoComplete="off"
                                                    onKeyDown={this.handleEnter}
                                                >
                                                    { 
                                                        dataLoaded ? 
                                                        this.renderForm() : ''
                                                    }
                                                    <Button
                                                        classCustom={'btn-lg'}
                                                        color={'bg-teal'}
                                                        loading={loading}
                                                        text={'Update Settings'}
                                                        icon={'save'}
                                                        type={'submit'}
                                                    />
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, generalSetting, principal, vessel }) => ({
    generalSetting,
    authUser: auth.user,
    settings: generalSetting.settings,
    loading: generalSetting.loading,
    success: generalSetting.success,
    error: generalSetting.error,
    errorMessage: generalSetting.errorMessage,
    principalLoading: principal.dtLoading,
    principalAllData: principal.datatableAll,
    vesselLoading: vessel.dtLoading,
    vesselAllData: vessel.datatableAll,
});

const mapDispatchToProps = dispatch => ({
    getSettingValues: () => dispatch(getSettingValues()),
    getAllPrincipal: () => dispatch(getAllPrincipal()),
    getAllVessel: () => dispatch(getAllVessel()),
    updateSetting: (params) => dispatch(updateSetting(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneralSettingPage);


