import { AUDIT_TRAIL } from '../../services';

export const AUDIT_TRAIL_STATE = {
    REQUEST: 'AUDIT_TRAIL_REQUEST',
    REQUEST_FAILED: 'AUDIT_TRAIL_REQUEST_FAILED',
    FETCH_SUCCESS: 'AUDIT_TRAIL_FETCH_SUCCESS',
};

const requesting = (requestType) => ({
    type: AUDIT_TRAIL_STATE.REQUEST,
    requestType
});

const requestFailed = (requestType, error) => ({
    type: AUDIT_TRAIL_STATE.REQUEST_FAILED,
    requestType,
    payload: error
});

const fetchingSuccess = (requestType, response) => ({
    type: AUDIT_TRAIL_STATE.FETCH_SUCCESS,
    requestType,
    payload: response
});

export const getAuditTrail = (params) => {
    return dispatch => {
        dispatch(requesting('dt'));
        AUDIT_TRAIL.get(params)
            .then(
                response => {
                    dispatch(fetchingSuccess('dt', response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestFailed('dt', response.body.message));
                }
            );
    };
};