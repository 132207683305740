import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser, postUser, activateUser, removeUser } from './action';
import { showPopup, showToast } from '../../helpers';
import UserDatatable from './datatable';
import UserModal from "./modal";

class UserPage extends Component {

    constructor(props) {
        super(props);
        this.EMPTY_FORM = {
            id: null,
            username: '',
            email:'',
            active: true
        };
        this.state = {
            openModal: false,
            formData: this.EMPTY_FORM,
            formType: '',
        };
    }

    UNSAFE_componentWillMount() {
        if(this.props.authUser.roles[0].id !== 1) {  // only superadmin access audit trail
            this.props.history.push('/payroll-transaction');
        }
    }

    componentDidMount() {
        this.fetchDatatable();
    }

    componentDidUpdate(prevProps) {
        if(!prevProps.success) {
            if (this.props.success === 'create') {
                this.setState({ openModal: false });
                showToast('success', `Data successfully created`);
                this.fetchDatatable();
            }

            if (this.props.success === 'delete') {
                showToast('success', 'Data successfully deleted');
                this.fetchDatatable();
            }
        }

        if(!prevProps.error && this.props.error === 'activate') {
            showToast('error', 'Something is wrong, failed to change user status');
        }
    }

    fetchDatatable = (limit, page, filter) => {
        this.props.getUser({limit, page, filter});
    }

    toggleModal = (type = null, formData = null) => {
        this.setState({ formData: type === 'update' ? formData : this.EMPTY_FORM });
        this.setState({
            formType: type,
            openModal: !this.state.openModal,
        });
    }

    toggleActivation = (event, id) => {    
        const status = event.target.checked  === true ? 1: 0;
        this.props.activateUser({id, status});
    }

    toggleConfirmDelete = (id) => {
        showPopup('confirm-delete', id, () => this.props.removeUser({id}) );
    }

    handleSubmit = (params, {setErrors, resetForm}) => {
        this.props.postUser(params, setErrors, resetForm);
    }

    render() {
        const { openModal, formType, formData } = this.state;
        const { loading, modalLoading, datatable, error, errorMessage } = this.props;
        return (
            <div className="page-container">
                <UserDatatable
                    isLoading={loading}
                    title={'User Management'}
                    dataSource={datatable}
                    toggleModal={this.toggleModal}
                    toggleConfirmDelete={this.toggleConfirmDelete}
                    toggleActivation={this.toggleActivation}
                    reloadDatatable={this.fetchDatatable}
                />
                <UserModal
                    isOpen={openModal}
                    formType={formType}
                    formData={formData}
                    handleSubmit={this.handleSubmit}
                    handleClose={() => this.toggleModal('close')}
                    errorMessage={error !== 'validate' && errorMessage ? errorMessage : undefined}
                    modalLoading={modalLoading}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ user, auth }) => ({
    auth,
    authUser: auth.user,
    loading: user.dtLoading,
    modalLoading: user.modalLoading,
    datatable: user.datatable,
    success: user.success,
    error: user.error,
    errorMessage: user.errorMessage,
});

const mapDispatchToProps = dispatch => ({
    getUser: (params) => dispatch(getUser(params)),
    postUser: (params, setErrors, resetForm) => dispatch(postUser(params, setErrors, resetForm)),
    activateUser: (params) => dispatch(activateUser(params)),
    removeUser: (params) => dispatch(removeUser(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPage);


