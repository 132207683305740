
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link, Paper, Box, Grid, Typography } from '@material-ui/core';
import ebu from '../assets/images/ebu.png';
import loginbg from '../assets/images/vessel1.jpg';

const useStyles = (theme) => ({
    root: {
        height: '100%',
        minHeight: '100vh',
        backgroundColor: 'green',
        fontSize: '14px',
    },
    image: {
        backgroundImage: `url(${loginbg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        opacity: '0.8',
    },
    appTitle: {
        margin: '20px 0 30px 0',
        color: '#4caf50',
        fontWeight: '100',
    },
    paperRoot: {
        // borderTopLeftRadius: '50px',
        display: 'flex',
    },
});

class Public extends Component {
    render () {
        const { classes } = this.props;
        return (
            <Grid container component="main" className={classes.root}>
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} className={classes.paperRoot} square>
                    <div className="mui-login-paper">
                        <img className="logo-login" src={ebu} alt='ebu'/>
                        <Typography component="h4" variant="h4" className={classes.appTitle}>Crew Payroll System</Typography>

                        {/* content */}
                        {this.props.children}

                        <Box mt={5}>
                            <Typography variant="body2" color="textSecondary" align="center">
                                {'Copyright © '}
		                <Link color="inherit" href="https://ebu.co.id/">
                                    EBU
                                </Link>{' '}
                                <Link color="inherit" href="https://cps.ebu.co.id/">
                                    Crew Payroll System
                                </Link>{' '}
                                {new Date().getFullYear()}
                                {'.'}
                            </Typography>
                        </Box>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(useStyles)(Public);
