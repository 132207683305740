import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import {
  prorate,
  thousand,
  showPopup,
  showToast,
  mergeParams,
  autoDecimal,
} from "../../helpers";
import { InputTable } from "../../components/forms";
import { Select } from "../../components/ui";

class DynamicFields extends Component {
  constructor(props) {
    super(props);
    let newWithProrate = [];
    for (let index = 0; index < 10; index++) {
      const key = `is_with_prorate_${index}`;
      let item = { ...newWithProrate[key] };
      let isChecked = { isChecked: 1 };
      item = { ...item, ...isChecked };
      newWithProrate[key] = item;
    }
    this.state = {
      withProrate: newWithProrate,
      editable: true,
      dataChanged: false,
      wageDays: 0,
      params: [],
      editParams: { editing: false, type: null, index: null, values: {} },
    };
    this.initialState = this.state;
    this.resetEdit = this.state.editParams;
  }

  UNSAFE_componentWillMount() {
    const { dataSource, editable } = this.props;
    if (dataSource) {
      let params = mergeParams(
        dataSource.params,
        dataSource.currencies,
        dataSource.wageDays,
        dataSource.params_calc
      );
      const wageDays = dataSource.wageDays;
      for (let index = 0; index < params.length; index++) {
        params[index].prorate = prorate(
          params[index].amount,
          wageDays,
          false,
          false
        );
      }

      this.setState(
        {
          editable: editable,
          params,
          wageDays,
        },
        () => this.handleSubmitToParent()
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.editParams.editing !== this.state.editParams.editing) {
      this.props.handleEditing(this.state.editParams.editing); // send editing state to parent component
    }

    if (prevProps.dataSource.wageDays !== this.props.dataSource.wageDays) {
      const dataSource = this.props.dataSource;
      let params = mergeParams(
        dataSource.params,
        dataSource.currencies,
        dataSource.wageDays
      );
      const wageDays = dataSource.wageDays;

      for (let index = 0; index < params.length; index++) {
        params[index].prorate = prorate(
          params[index].amount,
          wageDays,
          false,
          false
        );
      }

      this.setState(
        {
          editable: this.props.editable,
          params,
          wageDays,
        },
        () => this.handleSubmitToParent()
      );
    }
  }

  toggleEdit = (edit, indexParam, valueParam) => {
    if (edit) {
      const index =
        indexParam === "new"
          ? Object.keys(this.state.params).length
          : indexParam;
      this.setState({
        editParams: {
          ...this.state.editParams,
          editing: true,
          type: "edit",
          index,
          values: valueParam,
        },
      });
    } else {
      const initialParams = this.state.params;
      this.setState(
        {
          editParams: this.resetEdit,
          dataChanged: true,
          params: [],
        },
        () => this.setState({ params: initialParams })
      ); // revert to initial params if cancelled
    }
  };

  handleSubmitToParent = () => {
    this.props.handleSubmit({
      type: "dynamic",
      changed: this.state.dataChanged,
      params: this.state.params,
    });
  };

  handleAddNew = (newIndex) => {
    this.setState({
      editParams: {
        ...this.state.editParams,
        editing: true,
        type: "new",
        index: newIndex,
      },
      dataChanged: true,
    });
  };

  handleAcceptUpdate = (type, index, oldValue) => {
    const params = this.state.params;
    let newParams = this.state.editParams.values;
    const idx = this.state.editParams.index;
    const withProrate = this.state.withProrate;
    newParams.prorate = autoDecimal(newParams.amount);

    if (withProrate[`is_with_prorate_${idx}`].isChecked === 1) {
      newParams.prorate = prorate(newParams.amount, this.state.wageDays, false);
    }

    newParams.amount = autoDecimal(newParams.amount); // add auto decimal to number

    if (
      !newParams ||
      !newParams.name ||
      !newParams.amount ||
      !newParams.currency
    ) {
      showToast("error", "Please fill in name, amount and currency");
    } else {
      const updatedParams = Object.assign([...params], { [index]: newParams });
      this.setState(
        {
          params: updatedParams,
          editParams: this.resetEdit,
          dataChanged: true,
        },
        () => this.handleSubmitToParent()
      );
    }
  };

  handleCancelUpdate = () => {
    this.toggleEdit(false);
  };

  handleDelete = (removedIndex, value) => {
    const params = this.state.params;
    showPopup("confirm-delete", null, () => {
      params.splice(removedIndex, 1);
      this.setState({ params: [] }, () => {
        this.setState({ params, dataChanged: true }, () =>
          this.handleSubmitToParent()
        );
      });
    });
  };

  handleValueChange = (params) => {
    const edit = this.state.editParams;
    this.setState({
      editParams: {
        ...edit,
        values: { ...edit.values, [params.name]: params.value },
      },
    });
  };

  handleValueChangeCheckbox = (event) => {
    const targetId = event.target.id;
    let newWithProrate = [];
    const old = this.state.withProrate;
    for (let index = 0; index < 10; index++) {
      const key = `is_with_prorate_${index}`;
      let item = { ...old[key] };
      let isChecked = { isChecked: item.isChecked };

      if (targetId === key) {
        isChecked = { isChecked: event.target.checked ? 1 : 0 };
        item = { ...item, ...isChecked };
      }

      newWithProrate[key] = item;
    }

    this.setState({
      ...this.state,
      withProrate: newWithProrate,
    });
  };

  renderNonEditable = (newParams) => {
    if (Array.isArray(newParams) && newParams.length) {
      return newParams.map((value, index, array) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td className="break">
              <ReactTooltip id={`cell-${index}`} />
              <div data-tip={value.name} data-for={`action-${index}`}>
                {value.name}
              </div>
            </td>
            <td className="text-right">{value.amount}</td>
            {this.props.prorate ? (
              <td className={`text-right`}>{thousand(value.prorate)}</td>
            ) : (
              ""
            )}
            <td className="text-center">{value.currency}</td>
          </tr>
        );
      });
    } else {
      const cols = this.props.prorate ? 5 : 4;
      return (
        <tr>
          <td colSpan={cols} className="dt-empty-text">
            {" "}
            No data found{" "}
          </td>
        </tr>
      );
    }
  };

  renderBody = (newParams) => {
    const editState = this.state.editParams;
    if (Array.isArray(newParams) && newParams.length) {
      return newParams.map((value, index, array) => {
        return this.renderRow("existing", value, index, array);
      });
    } else if (editState.editing) {
      return;
    } else {
      const cols = this.props.prorate ? 7 : 5;
      return (
        <tr key={0}>
          <td colSpan={cols} className="dt-empty-text">
            {" "}
            No data found{" "}
          </td>
        </tr>
      );
    }
  };

  renderNew = (newIndex) => {
    const editState = this.state.editParams;
    let newParams = [];
    newParams[newIndex] = { name: "", amount: "", prorate: "", currency: "" };

    if (editState.editing && editState.type === "new") {
      return newParams.map((value, index, array) => {
        return this.renderRow("new", value, index, array);
      });
    }
    return;
  };

  renderRow = (type, value, index) => {
    const editState = this.state.editParams;
    const { withProrate } = this.state;

    let prorateValue = prorate(value.amount, 30, true, true);
    if (
      value.amount &&
      this.state.withProrate[`is_with_prorate_${index}`].isChecked === 1 &&
      (value.amount !== value.prorate ||
        (value.amount === "" && value.prorate === ""))
    ) {
      prorateValue = prorate(value.amount, this.state.wageDays, true, true);
    }

    const defaultCheckedCond =
      withProrate[`is_with_prorate_${index}`].isChecked === 1 &&
      (value.amount !== value.prorate ||
        (value.amount === "" && value.prorate === ""));

    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td
          className={`${
            editState.editing && editState.index === index ? " edited" : ""
          }`}
        >
          <InputTable
            classCustom={"break"}
            name={"name"}
            type={"text"}
            defaultValue={value.name}
            onBlur={this.handleValueChange}
            disabled={
              editState.editing && editState.index === index ? false : true
            }
            maxLength={50}
          />
        </td>
        <td
          className={`text-right ${
            editState.editing && editState.index === index ? " edited" : ""
          }`}
        >
          <InputTable
            type={"currency"}
            name={"amount"}
            defaultValue={value.amount}
            onBlur={this.handleValueChange}
            disabled={
              editState.editing && editState.index === index ? false : true
            }
            maxLength={20}
            allowMinus
          />
        </td>
        {this.props.prorate ? (
          <td className={`text-right nonedit`}>{prorateValue}</td>
        ) : (
          ""
        )}
        <td
          className={`${
            editState.editing && editState.index === index ? " edited" : ""
          }`}
        >
          <Select
            className={""}
            name={"currency"}
            data={this.props.currencyData}
            keyPair={["id", "name"]}
            placeholder={"None"}
            onChange={this.handleValueChange}
            setValue={value.currency}
            disabled={
              editState.editing && editState.index === index ? false : true
            }
            onlyValue={true}
          />
        </td>
        {this.props.prorate ? (
          <td className="text-center">
            <form>
              <input
                type="checkbox"
                name={`is_with_prorate_${index}`}
                id={`is_with_prorate_${index}`}
                className="filled-in chk-col-green"
                defaultChecked={defaultCheckedCond}
                checked={defaultCheckedCond}
                onChange={this.handleValueChangeCheckbox}
                disabled={
                  editState.editing && editState.index === index
                    ? ""
                    : "disabled"
                }
              />
              <label
                htmlFor={`is_with_prorate_${index}`}
                style={{ top: "5px" }}
              ></label>
            </form>
          </td>
        ) : (
          ""
        )}
        <td className="text-center">
          {editState.editing ? (
            editState.index === index ? (
              <div>
                <button
                  className="btn-outline pad-0"
                  type="button"
                  onClick={() => this.handleAcceptUpdate(type, index, value)}
                >
                  <i className="material-icons">check</i>
                </button>
                <button
                  className="btn-outline pad-0"
                  type="button"
                  onClick={this.handleCancelUpdate}
                >
                  <i className="material-icons">close</i>
                </button>
              </div>
            ) : (
              ""
            )
          ) : (
            <div>
              <button
                className="btn-outline pad-0"
                type="button"
                onClick={() => this.toggleEdit(true, index, value)}
              >
                <i className="material-icons">edit</i>
              </button>
              <button
                className="btn-outline pad-0"
                type="button"
                onClick={() => this.handleDelete(index, value)}
              >
                <i className="material-icons">delete_forever</i>
              </button>
            </div>
          )}
        </td>
      </tr>
    );
  };

  renderTableHeader() {
    const defaultHeader = [
      { name: "Name", className: "w-auto text-center break" },
      { name: "Amount", className: "td-20 text-center" },
      { name: "Prorate", className: "td-20 text-center" },
      { name: "Currency", className: "td-75px" },
      { name: "With Prorate", className: "td-25px" },
      { name: "Action", className: "td-100px text-center" },
    ];

    const header = this.props.tableHeader
      ? this.props.tableHeader
      : defaultHeader;

    return header.map((key, index) => {
      if (
        (!this.props.prorate && key.name === "Prorate") ||
        (!this.props.prorate && key.name === "With Prorate")
      ) {
        return false;
      } else if (!this.props.editable && key.name === "Action") {
        return false;
      }
      return (
        <th className={`${key.className ? key.className : ""}`} key={index}>
          {key.name}
        </th>
      );
    });
  }

  render() {
    let { editParams, params } = this.state;
    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover table-fixed editable">
          <thead>
            <tr>
              <th className="td-30px">No.</th>
              {this.renderTableHeader()}
            </tr>
          </thead>
          <tbody>
            {this.state.editable
              ? [this.renderBody(params), this.renderNew(params.length)]
              : this.renderNonEditable(params)}
            {this.state.editable ? (
              <tr>
                <td
                  colSpan={this.props.prorate ? "7" : "5"}
                  className="no-effect"
                >
                  <ReactTooltip id={`table-button`} globalEventOff="click" />
                  <button
                    type="button"
                    className={`btn btn-circle btn-sm  ${
                      this.props.btnAddColor
                        ? this.props.btnAddColor
                        : "bg-blue"
                    } btn-add-dynamic  waves-circle waves-float ${
                      editParams.editing ? " disabled" : ""
                    }`}
                    disabled={editParams.editing ? true : false}
                    data-tip="Add New"
                    data-for={`table-button`}
                    onClick={() => this.handleAddNew(params.length)}
                  >
                    <i className="material-icons">add</i>
                  </button>
                </td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default DynamicFields;
