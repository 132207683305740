import { CURRENCY } from '../../services';

export const CURRENCY_STATE = {
    REQUEST: 'CURRENCY_REQUEST',
    REQUEST_FAILED: 'CURRENCY_REQUEST_FAILED',
    FETCH_SUCCESS: 'CURRENCY_FETCH_SUCCESS',
};

const requesting = (requestType, response) => ({
    type: CURRENCY_STATE.REQUEST,
    requestType,
    payload: response
});

const requestingFailed = (requestType, error) => ({
    type: CURRENCY_STATE.REQUEST_FAILED,
    requestType,
    payload: error
});

const fetchingSuccess = (requestType, response) => ({
    type: CURRENCY_STATE.FETCH_SUCCESS,
    requestType,
    payload: response
});

export const getCurrency = (params) => {
    return dispatch => {
        dispatch(requesting('dt'));
        CURRENCY.get(params)
            .then(
                response => {
                    dispatch(fetchingSuccess('dt', response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestingFailed('dt', response.body));
                }
            );
    };
};

