import { apiService } from '../config';

export function postLogin (params) {
    return apiService.post('/login',
        params
    );
};

export function postLogout() {
    return apiService.post('/logout');
};

export function postForgotPassword(params) {
    return apiService.post('/password/forgot',
        params
    );
};

export function getResetPassword(params) {
    return apiService.get('/password/reset', {
        params
    });
};

export function postResetPassword(params) {
    return apiService.post('/password/reset', {
        token: params.token,
        email: params.email,
        password: params.password,
        password_confirmation: params.password_confirmation,
    });
};

export function getVerifyPassword(params) {
    return apiService.get('/verify/password/reset', {
        params
    });
};

export function postVerifyPassword(params) {
    return apiService.post('/verify/password/reset', {
        token: params.token,
        email: params.email,
        current_password: params.current_password,
        password: params.password,
        password_confirmation: params.password_confirmation,
    });
};

export function getProfile(params) {
    return apiService.get(`/accounts`);
};

export function putProfile(params) {
    return apiService.put(`/accounts/update`,
        params
    );
};

