import { WAGE_SUMMARY_STATE } from './action';

const INITIAL_STATE = {
    dtLoading: false,
    loading: false,
    datatable: [],
    error: null,
    errorMessage: null,
    success: null,
    excelData: [],
};

const wageSummaryReducer = (state = INITIAL_STATE, action) => {
    const loading = action.requestType === 'dt' ? 'dtLoading' : 'loading';
    switch (action.type) {
        case WAGE_SUMMARY_STATE.REQUEST:
            return {
                ...state,
                [loading]: true,
                success: null,
                error: null,
                errorMessage: null,
            };
        case WAGE_SUMMARY_STATE.REQUEST_FAILED:
            return {
                ...state,
                dtLoading: false,
                loading: false,
                error: action.requestType,
                errorMessage: action.payload,
            };
        case WAGE_SUMMARY_STATE.FETCH_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                success: 'dt',
                datatable: action.payload.contents,
            };
        case WAGE_SUMMARY_STATE.DOWNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.requestType,
                excelData: action.payload.contents.file
            };
        default:
            return state;
    }
};

export default wageSummaryReducer;
