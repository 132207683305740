import { apiService } from '../config';

export function get(params) {
    const page = params.page ? `${params.page}` : '';
    const limit = params.limit ? `${params.limit}` : '';
    return apiService.get(`/notifications?page=${page}&limit=${limit}`);
};

export function read(params) {
    return apiService.put(`/notifications/read`,
        params
    );
};