import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { getSummary, postDownload } from './action';
import { splitDate, showToast } from '../../helpers';
import { Button, DtSearch, Tabs } from '../../components/ui';
import PayrollFilter from '../../components/payroll-filter';
import SummaryDatatable from './datatable';

class WageSummaryPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'earning',
            dtFilter: { keyword: ''}
        };
        this.initialState = this.state;
    }

    UNSAFE_componentWillMount() {
        this.fetchSummaryDatatable()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(!this.props.success) {
            if (nextProps.success === 'download') {
                const downloaded = this.handleDownload(nextProps.excelData);
                if(downloaded) showToast('success', 'File successfully exported & downloaded');
            } 
        }

        if(!this.props.error && nextProps.error === 'download') {
            showToast('error', nextProps.errorMessage);
        }
    }

    fetchSummaryDatatable =  () => {
        const { vessel_id, csc, wage_period } = this.props.globalFilter;
        this.props.getSummary({
            keyword: this.state.dtFilter.keyword,
            vessel_id,
            csc,
            month: splitDate(wage_period, 1),
            year: splitDate(wage_period, 0),
        });
    }

    handleFilterChange = (params) => {
        this.setState(params, () => {
            this.fetchSummaryDatatable();
        });
    }

    handleGenerateExcel = () => {
        const { vessel_id, csc, wage_period } = this.props.globalFilter;
        const params = {
            vessel_id,
            csc,
            month: splitDate(wage_period, 1),
            year: splitDate(wage_period, 0),
        };
        this.props.postDownload(params);
    }

    handleDownload = (fileName) => {
        const pdfPath = this.props.globalFilter.csc === 1 ? process.env.REACT_APP_API_EXCEL_CSC_PATH : process.env.REACT_APP_API_EXCEL_NONCSC_PATH;
        if(fileName) {
            const file = process.env.REACT_APP_API_URL + pdfPath + fileName;
            window.location.href = file; // alternative : window.open(file);
            return true;
        }
        return false;
    }

    handleChangeTab = (tab) => {
        this.setState({activeTab: tab});
    }

    handleSearch = debounce((keyword) => {
        this.setState(
            { dtFilter : {...this.state.dtFilter, keyword} },
            () => this.fetchSummaryDatatable()
        );
    }, 350);

    checkReady = () => {
        const { principal_id, vessel_id, csc, wage_period } = this.props.globalFilter;
        if( principal_id && vessel_id && csc !== null && wage_period ) return true;
        return false;
    }

    renderDatatableTabs = () => {
        const { csc } = this.props.globalFilter;
        const { activeTab } = this.state;
        const { dtLoading, datatable } = this.props;
        return (
            <div className="card">
                <Tabs
                    classNav={'wage-account'}
                    activeTab={activeTab}
                    handleChangeTab={this.handleChangeTab}
                    tabs={[
                        {name: 'earning', text: 'Earnings'},
                        {name: 'deduction', text: 'Deductions'},
                        {name: 'accumulated_leave', text: 'Accumulated Leave'},
                    ]}
                    contents={[
                        {
                            name: 'earning', 
                            content: (
                                <SummaryDatatable
                                    type={'earning'}
                                    isLoading={dtLoading}
                                    dataSource={datatable}
                                    reloadDatatable={this.fetchSummaryDatatable}
                                    csc={csc}
                                />
                            )
                        },
                        {
                            name: 'deduction', 
                            content: (
                                <SummaryDatatable
                                    type={'deduction'}
                                    isLoading={dtLoading}
                                    dataSource={datatable}
                                    reloadDatatable={this.fetchSummaryDatatable}
                                    csc={csc}
                                />
                            )
                        },
                        {
                            name: 'accumulated_leave', 
                            content: (
                                <SummaryDatatable
                                    type={'accumulated_leave'}
                                    isLoading={dtLoading}
                                    dataSource={datatable}
                                    reloadDatatable={this.fetchSummaryDatatable}
                                    csc={csc}
                                />
                            )
                        }
                    ]}
                />
            </div>
        );
    }
    
    renderFilterButton = () => {
        const { loading } = this.props;
        return (
            <div className="row pad-0 container-button-payroll">
                <div className="col-md-4">
                    <Button
                        classCustom={'btn-lg'}
                        color={'bg-deep-orange'}
                        type={'button'}
                        disabled={this.checkReady() && !loading ? false : true}
                        loading={loading}
                        text={'Download Wage Summary'}
                        icon={'assignment_returned'}
                        onClick={() => this.handleGenerateExcel()}
                    />
                </div>
                <div className="col-md-8">
                    {
                        this.checkReady() ?
                        (
                            <DtSearch
                                className={'search-summary'}
                                placeholder={'Search by Name'}
                                icon={'search'}
                                onChange={this.handleSearch}
                            />
                        ) : ''
                    }
                </div>
            </div>
        );
    }

    render () {
        return (
            <div className="page-container">
                <div className="container-fluid">
                    <div className="block-header">
                        <h2>
                            Wage Summary
                            <small>Payroll Wage Summary Report</small>
                        </h2>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="card">
                                <div className="body">
                                    <div className="payroll-filter">
                                        <PayrollFilter
                                            onChange={this.handleFilterChange}
                                        />
                                        { this.renderFilterButton() }
                                    </div>
                                </div>
                            </div>
                            {
                                this.checkReady() ? 
                                this.renderDatatableTabs() : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ global, summary }) => ({
    summary,
    globalFilter: global.payrollFilter,
    success: summary.success,
    error: summary.error,
    errorMessage: summary.errorMessage,
    excelData: summary.excelData,
    datatable: summary.datatable,
    dtLoading: summary.dtLoading,
});

const mapDispatchToProps = dispatch => ({
    getSummary: (params) => dispatch(getSummary(params)),
    postDownload: (params) => dispatch(postDownload(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WageSummaryPage);


