import React, { Component } from 'react';

class DtHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sortType: 'desc',
            sortBy: null, 
        }
    }

    handleSortParent = (field) => {
        const sortType = this.state.sortType === 'desc' ? 'asc' : 'desc';
        this.setState({ sortType, sortBy: field });
        this.props.onSort(sortType, field);
    }
    
    render() {
        const { sortType, sortBy } = this.state;
        const { header, sortable } = this.props;

        return header.map((key, index) => {
            if(sortable === false || !key.field) { 
                return (
                    <th
                        colSpan={key.colspan ? key.colspan : ''}
                        className={key.className ? key.className : 'text-center' }
                        key={index}>{key.label}
                    </th>
                );
            }
            
            return (
                <th 
                    className="dt-sort waves-effect waves-grey" 
                    key={index}
                    colSpan={key.colspan ? key.colspan : 0}
                    onClick={() => this.handleSortParent(key.field)}
                    data-tip={key.tooltip ? key.tooltip : null} 
                    data-for={`action-table`}
                >
                    <i className={`material-icons up ${sortBy === key.field && sortType === 'asc' ? ' active' : ''}`}>
                        arrow_drop_up
                    </i>
                    <i className={`material-icons down ${sortBy === key.field && sortType === 'desc' ? ' active' : ''}`}>
                        arrow_drop_down
                    </i>
                    <span>{key.label}</span>
                </th>);
        });
    }
}

export default DtHeader;


