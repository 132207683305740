import React, { Component } from "react";
import { debounce } from "lodash";
import { dateFormatStd, compareArray } from "../../helpers";
import { Datatable } from "../../components/datatables";
import { DtSearch } from "../../components/ui";

class CrewDatatable extends Component {
  renderTableBody = (dataSource) => {
    const { selectedCrew, handleSelect } = this.props;
    if (Array.isArray(dataSource) && dataSource.length) {
      return dataSource.map((value, index) => {
        const {
          id,
          crew,
          long_contract_num,
          long_contract_time,
          rank,
          coc_date,
          active,
          sign_on_date,
          sign_off_date,
        } = value;
        return (
          <tr key={id}>
            <td className="index-td">
              <form>
                <input
                  type="checkbox"
                  id={`crew_cb_${id}`}
                  name={`crew_cb_${id}`}
                  className="filled-in chk-col-green"
                  checked={selectedCrew.includes(id) ? true : false}
                  onChange={() => handleSelect(id)}
                />
                <label htmlFor={`crew_cb_${id}`}></label>
              </form>
            </td>
            <td className="text-center">{index + 1}</td>
            <td className="text-name">{crew.fullname}</td>
            <td className="text-center">{rank}</td>
            {/* <td className="text-center">{active === 1 ? 'Active' : 'Non-Active'}</td> */}
            <td className="text-center">
              {sign_on_date ? dateFormatStd(sign_on_date) : "-"}
            </td>
            <td className="text-center">
              {sign_off_date ? dateFormatStd(sign_off_date) : "-"}
            </td>
            <td className="text-date">{dateFormatStd(coc_date)}</td>
            <td className="text-center">
              {long_contract_num + " " + long_contract_time}
            </td>
          </tr>
        );
      });
    }
  };

  renderHeaderCheckbox = () => {
    const { crewIds, selectedCrew, handleSelect } = this.props;
    return (
      <th className="index-th index-th-check">
        <form>
          <input
            type="checkbox"
            name="crew_all"
            id={`crew_all`}
            className="filled-in chk-col-green"
            checked={
              crewIds.length && compareArray(selectedCrew, crewIds)
                ? true
                : false
            }
            onChange={() => handleSelect("all")}
          />
          <label htmlFor={`crew_all`}></label>
        </form>
      </th>
    );
  };

  renderCustomFilter = () => {
    const handleSearchParent = debounce((keyword) => {
      this.props.handleSearch(keyword);
    }, 500);

    return (
      <div className="row clearfix dt-body-filter">
        <div className="col-md-6 col-lg-6 col-sm-6 m-0 generate-crew-label">
          <span>
            <strong> Select Crew to Generate : </strong>
          </span>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6">
          <DtSearch
            placeholder={"Search"}
            icon={"search"}
            onChange={handleSearchParent}
          />
        </div>
      </div>
    );
  };

  render() {
    const { isLoading, dataSource, reloadDatatable, toggleModal } = this.props;
    const datatableHeader = [
      { label: "Full Name", field: "crew.fullname" },
      { label: "Rank", field: "rank" },
      // {label: 'Active', field: 'active'},
      { label: "Sign On", field: "sign_on_date" },
      { label: "Sign Off", field: "sign_off_date" },
      { label: "Coc Date", field: "coc_date" },
      { label: "Contract Duration", field: "" },
    ];

    return (
      <Datatable
        isLoading={isLoading}
        checkBox={this.renderHeaderCheckbox}
        tableHeader={datatableHeader}
        tableBody={this.renderTableBody}
        dataSource={dataSource}
        toggleModal={toggleModal}
        reloadDatatable={reloadDatatable}
        customFilter={this.renderCustomFilter}
        limitable={false}
        searchable={false}
        creatable={false}
        card={false}
      />
    );
  }
}

export default CrewDatatable;
