import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import DatePicker from "react-datepicker";
import { 
    FormControl, Input, InputLabel, InputAdornment, FormHelperText, Select, MenuItem,  
    TextField, TextareaAutosize
} from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { numericKeypress, convDate } from '../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import "react-datepicker/dist/react-datepicker.css";

const primary = green[500];

const useStyles = makeStyles((theme) => ({
    root: {
        '& label.Mui-focused:not(.Mui-error)': {
            color: primary,
        },
        '& .MuiInput-underline:not(.Mui-error):after': {
            borderBottomColor: primary,
        },
    },
}));


export const MInput = ({ allowMinus, type, noMargin, maxDigit, disableUnderline, ...props }) => {
    
    const [field, meta] = useField(props);
    const classes = useStyles();

    const handleKeypress = (e) => {
        if(type === 'currency' || type ==='number') {
            const minus = allowMinus ? true : false;
            numericKeypress(e, maxDigit ? maxDigit : 12, true, minus);
        }
    }

    return (
        <TextField
            className={classes.root}
            variant="standard"
            margin={noMargin ? 'none' : "normal"}
            min={type === 'currency' || type ==='number' ? 0 : undefined}
            error={meta.touched && meta.error ? true : false}
            helperText={meta.error ? meta.error : undefined}
            InputLabelProps={props.shrink === false ? {} : { shrink: true }}
            type={type ? (type === 'number' ? 'tel': type) : 'text'}  // tel == number, without spinner arrow
            InputProps={{ 
                className: 'mui-custom-input',
                onKeyDown: handleKeypress,
                disableUnderline
            }}
            autoComplete='off'
            {...field}
            {...props}
        />
    );
};

export const MInputInline = ({ 
    classRow, classLabel, classInput, removeMargin, afterLabel, classElem, showErrorMargin,
    ...props 
}) => {

    const [field, meta] = useField(props);
    
    return (
        <div className={`row ${classRow ? classRow : ''}`}>
            <div className={`
                ${classLabel ? classLabel : 'col-lg-2 col-md-2 form-control-label' } 
                ${props.align ? props.align : ''} 
                ${removeMargin ? 'm-0' : ''}
            `}>
                <label htmlFor={props.name}>{props.label ? props.label : ''}</label>
            </div>
            <div className={`${classInput ? classInput : 'col-lg-10 col-md-10' } ${removeMargin ? 'm-0' : ''}`}>
                <div className={`form-group ${removeMargin ? 'm-0' : ''}`}>
                    <div className={`${props.line === false ? '' : 'form-line'}`}>
                        <Input
                            className={`form-control ${classElem ? classElem: ''}`} 
                            type={props.type ? props.type : 'text'}
                            error={meta.touched && meta.error ? true : false}
                            {...field} 
                            {...props}
                        />
                    </div>
                    <label id={props.name} className={`error ${showErrorMargin === true ? 'visible' : ''}`} htmlFor={props.name}>
                        { meta.touched && meta.error ? meta.error : '' }
                    </label>
                </div>
            </div>
            {
                afterLabel ?
                ( <label className="label-after">{afterLabel}</label>)
                : null
            }
        </div>
    );
}

export const MSelect = ({ label, keyPair, onlyValue, showLabel, noMargin, ...props }) => {

    const [field, meta] = useField(props);

    const renderOptions = (options) => {
        return options.map((value) => {
            // NOTE : destructure the record based on keyPair array value
            // NOTE : eg: keyPair = ['id', 'name'] will take (id => name) from the record
            const {[keyPair[0]]: id, [keyPair[1]]: text} = value;
            return onlyValue ? //special handler for currencies, return value=string not ID
                (<MenuItem key={id} value={text}>{text}</MenuItem>) :
                (<MenuItem key={id} value={id}>{text}</MenuItem>)
        });
    }

    return (
        <FormControl>
            {
                showLabel !== false ? 
                (
                    <InputLabel shrink>
                        {label} {props.mandatory ? <span className="mandatory-icon">*</span> : ''}
                    </InputLabel>
                ) : ''
            }
            <Select
                displayEmpty
                {...field}
                {...props}
            >
                <MenuItem value=""><em>{props.placeholder ? props.placeholder : 'None'}</em></MenuItem>
                {renderOptions(props.data)}
            </Select>
            {
                meta.touched && meta.error ? 
                (
                    <FormHelperText className="error">{meta.error}</FormHelperText>
                ) : null
            }
        </FormControl>
    );
};

export const MDatepicker = ({ showLabel, ...props }) => {
    const initialDate = props.setValue || props.setValue === undefined ? props.setValue : new Date();
    const [startDate, setStartDate] = useState(initialDate);
    const [field, meta] = useField(props);
    const calendarRef = React.useRef();
    const { setFieldValue } = useFormikContext();
    const rootElement = document.querySelector('#root');
    
    const PopperContainer = ({ children }) => (
        ReactDOM.createPortal(children, rootElement)
    );

    const handleClickIcon = () => {
        calendarRef.current.setOpen(true);
    }

    const handleChange = (date) => {
        setStartDate(date);
        setFieldValue(field.name, convDate(date));

        if(props.onChange) {
            return props.onChange({ name: props.name, value: convDate(date) }); 
        }
    }

    return (
        <FormControl 
            className={`input-group datepicker ${props.className ? props.className : ''}`}
        >
            {
                showLabel !== false ? 
                (
                <InputLabel 
                    shrink 
                >
                        {props.label} {props.mandatory ? <span className="mandatory-icon">*</span> : ''}
                    </InputLabel>
                ) : ''
            }
            <Input
                className='mui-datepicker-input'
                startAdornment={
                    <InputAdornment position="end">
                        { 
                            props.icon ? 
                            ( 
                                <span 
                                    className="input-group-addon absolute-icon mui-end-icon"  
                                    onClick={handleClickIcon}
                                >
                                    <i className="material-icons">{props.icon}</i>
                                </span>
                            ) : '' 
                        }
                    </InputAdornment>
                }
                inputComponent={() => {
                    return (
                        <DatePicker
                            label={props.label}
                            className="form-control"
                            selected={startDate}
                            placeholderText={props.placeholder}
                            dateFormat={props.type === 'monthyear' ? "MM-yyyy" : "dd-MM-yyyy"}
                            showMonthYearPicker={props.type === 'monthyear' ? true : false}
                            showDisabledMonthNavigation
                            minDate={props.minDate}
                            maxDate={props.maxDate}
                            onChange={handleChange}
                            popperContainer={PopperContainer}
                            disabled={props.disabled ? true : false}
                            ref={calendarRef}
                            isClearable
                        />
                    );
                }}
            />
            {
                meta.touched && meta.error ? 
                (
                    <FormHelperText className="error">{meta.error}</FormHelperText>
                ) : null
            }
        </FormControl>
    );
};

export const MTextArea = ({ ...props }) => {

    const [field, meta] = useField(props);
    return (
        <FormControl 
            className="form-group"
        >
            <InputLabel shrink>
                {props.label} {props.mandatory ? <span className="mandatory-icon">*</span> : ''}
            </InputLabel>
            <Input 
                inputComponent={() => {
                    return (
                        <TextareaAutosize 
                            className="form-control"
                            {...field}
                            {...props}
                        />
                    );
                }}
            />
            { 
                props.note ? 
                <label id={`note-`+props.name} className="input-note" htmlFor={props.name}>{ '* '+props.note}</label> :
                null
            }
            {
                meta.touched && meta.error ?
                (
                    <label id={props.name} className="error" htmlFor={props.name}>{meta.error}</label>
                ) : null
            }
        </FormControl>
    );
};
