import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { changePassword, checkExpired } from './action';
import { Button } from '../../components/ui';
import { Alert } from '../../components/alert';
import { showPopup } from '../../helpers';
import { Typography } from '@material-ui/core';
import { MInput } from '../../components/material/form';

class VerifyPasswordPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formType: 'form-verify',
            formData: {
                current_password: '',
                password: '',
                password_confirmation: ''
            }
        };
    }

    componentWillMount() {
        const userAlreadyLoggedIn = this.props.auth.token;
        if(userAlreadyLoggedIn) {
            this.props.history.push('/payroll-transaction');
            showPopup('warning', 'Hmm...', 'You are already logged in. Please log out to continue');
        }

        const queryParam = this.props.location.search.substring(1); // remove the "?" at the start
        const searchParams = new URLSearchParams( queryParam );
        const token = searchParams.get("token");
        const email = searchParams.get("email");

        this.setState({ token, email });
        this.props.checkExpired({ token, email });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(!nextProps.loading && !this.props.success && nextProps.success === 'verify') {
            this.setState({formType: 'verify-success'});
        }

        if(!this.props.success && nextProps.error === 'check') {
            this.setState({formType: 'token-expired'});
        }
    }

    handleSubmit = async (params, { setErrors }) => {
        const {token, email} = this.state;
        Object.assign(params, { token, email });
        await this.props.changePassword(params, setErrors);
    };

    handleBackToLogin = () => {
        this.props.history.push('/login');
    }

    renderForm = () => {
        const { email, formData } = this.state;
        const { loading } = this.props;

        const validation = Yup.object({
            current_password: Yup.string().required('Please fill your current password'),
            password: Yup.string().required('Please fill your new password'),
            password_confirmation: Yup.string()
                .required('Please fill your new password confirmation')
                .oneOf([Yup.ref('password'), null], "Confirm Password is mismatch with Password")
        });

        return (
            <Formik
                initialValues={formData}
                validationSchema={validation}
                onSubmit={this.handleSubmit}
            >
                <Form>
                    <div className="reset-password-msg">Please submit your account password for <strong>{email}</strong></div>
                    <MInput
                        label="Current Password"
                        name="current_password"
                        type="password"
                        placeholder="Current password from email"
                        required
                        fullWidth
                    />
                    <MInput
                        label="New Password"
                        name="password"
                        type="password"
                        placeholder="Enter new password"
                        required
                        fullWidth
                    />
                    <MInput
                        label="Confirm New Password"
                        name="password_confirmation"
                        type="password"
                        placeholder="Enter new password confirmation"
                        required
                        fullWidth
                    />
                    <Button
                        classCustom={'btn-block btn-lg btn-verify-pass'}
                        color={'bg-green'}
                        type={'submit'}
                        disabled={loading}
                        loading={loading}
                        text={'Change Password'}
                        icon={'lock_open'}
                    />
                </Form>
            </Formik>
        );
    };

    renderMessage = () => {
        return (
            <div>
                <div className="reset-password-msg">
                    {
                        this.props.success === 'verify' ?
                        "Thank you. Your password has been successfully changed" :
                        "We are sorry. Your verify password link has expired, please contact your administrator to get new link"
                    }
                </div>
                <Button
                    classCustom={'btn-block btn-lg btn-reset-pass'}
                    color={'bg-green'}
                    type={'button'}
                    text={'Back to Login'}
                    onClick={this.handleBackToLogin}
                />
            </div>
        );
    };

    render() {
        const { error, errorMessage } = this.props;
        const { formType } = this.state;

        return (
            <div>
                <Alert text={error === 'verify' && errorMessage ? errorMessage : ''}/>
                <Typography className="login-sub-title" component="h1" variant="h5">Verify Password</Typography>
                { 
                    formType === 'form-verify' ?
                    this.renderForm() :
                    this.renderMessage()
                }
            </div>
        );
    }
}

const mapStateToProps = ({ auth, verifyPassword }) => ({
    auth,
    verifyPassword,
    loading: verifyPassword.loading,
    error: verifyPassword.error,
    errorMessage: verifyPassword.errorMessage,
    tokenExpired: verifyPassword.tokenExpired,
    success: verifyPassword.success,
});

const mapDispatchToProps = dispatch => ({
    checkExpired: (params, setErrors) => dispatch(checkExpired(params, setErrors)),
    changePassword: (params, setErrors) => dispatch(changePassword(params, setErrors)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifyPasswordPage);


