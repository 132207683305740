import { AUDIT_TRAIL_STATE } from './action';

const INITIAL_STATE = {
    dtLoading: true,
    datatable: [],
    error: null,
    errorMessage: null,
    success: null,
};

const auditTrailReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUDIT_TRAIL_STATE.REQUEST:
            return {
                ...state,
                dtLoading: true,
                success: null,
                error: null,
                errorMessage: null,
            };
        case AUDIT_TRAIL_STATE.REQUEST_FAILED:
            return {
                ...state,
                dtLoading: false,
                error: action.requestType,
                errorMessage: action.payload,
            };
        case AUDIT_TRAIL_STATE.FETCH_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                datatable: action.payload.contents.auditTrails
            };
        default:
            return state;
    }
};

export default auditTrailReducer;
