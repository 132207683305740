import { NOTIFICATION } from '../../services';
import { transformObject } from '../../helpers';
    
export const NOTIFICATION_STATE = {
    REQUEST: 'NOTIFICATION_REQUEST',
    REQUEST_FAILED: 'NOTIFICATION_REQUEST_FAILED',
    FETCH_SUCCESS: 'NOTIFICATION_FETCH_SUCCESS',
    UPDATE_SUCCESS: 'NOTIFICATION_UPDATE_SUCCESS',
};

const requesting = (requestType, response) => ({
    type: NOTIFICATION_STATE.REQUEST,
    requestType,
    payload: response
});

const requestingFailed = (requestType, error) => ({
    type: NOTIFICATION_STATE.REQUEST_FAILED,
    requestType,
    payload: error
});

const fetchingSuccess = (requestType, response) => ({
    type: NOTIFICATION_STATE.FETCH_SUCCESS,
    requestType,
    payload: response
});

const updatingSuccess = (response) => ({
    type: NOTIFICATION_STATE.UPDATE_SUCCESS,
    payload: response
});

export const getNotification = (params) => {
    return async dispatch => {
        dispatch(requesting('dt'));
        await NOTIFICATION.get(params)
            .then(
                response => {
                    dispatch(fetchingSuccess('dt', response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestingFailed('dt', response.body));
                }
            );
    };
};

export const updateNotification = (params, setErrors) => {
    return async dispatch => {
        dispatch(requesting('update'));
        await NOTIFICATION.read(params)
            .then(
                response => {
                    dispatch(updatingSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    const code = response.meta.code;
                    if(code === 422) {
                        const errMsg = transformObject(response.body.message);
                        setErrors(errMsg);
                        dispatch(requestingFailed('validate', errMsg));
                    } else {
                        dispatch(requestingFailed('update', response.body.message));
                    }
                }
            );
    };
};