import { AUTH_STATE } from './action';
import { LOGIN_STATE } from '../login/action';
import { PROFILE_STATE } from '../profile/action';

const AUTH_INITIAL_STATE = {
    token: null,
    user: null,
    tokenExpired: false,
    passwordExpiredDate: null,
};

const authReducer = (state = AUTH_INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH_STATE.TOKEN_EXPIRED:
            return {
                ...state,
                token: null,
                tokenExpired: true,
            };
        case LOGIN_STATE.LOGIN_SUCCESS:
            return {
                ...state,
                token: action.payload.contents.token,
                user: action.payload.contents.user,
                tokenExpired: false,
                passwordExpiredDate: action.payload.contents.passwordExpiredDate,
            };
        case LOGIN_STATE.LOGOUT_SUCCESS:
            return {
                ...state,
                token: null,
                user: null,
                tokenExpired: false,
            };
        case PROFILE_STATE.UPDATE_SUCCESS:
            return {
                ...state,
                user: action.payload.contents.user
            };
        default:
            return state;
    }
};

export default authReducer;
