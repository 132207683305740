import { BASIC_WAGE } from '../../services';
import { transformObject } from '../../helpers';

export const BASIC_WAGE_STATE = {
    REQUEST: 'BASIC_WAGE_REQUEST',
    REQUEST_FAILED: 'BASIC_WAGE_REQUEST_FAILED',
    FETCH_SUCCESS: 'BASIC_WAGE_FETCH_SUCCESS',
    CREATE_SUCCESS: 'BASIC_WAGE_CREATE_SUCCESS',
    UPDATE_SUCCESS: 'BASIC_WAGE_UPDATE_SUCCESS',
    DELETE_SUCCESS: 'BASIC_WAGE_DELETE_SUCCESS',
};

const requesting = (requestType) => ({
    type: BASIC_WAGE_STATE.REQUEST,
    requestType
});

const requestFailed = (requestType, error) => ({
    type: BASIC_WAGE_STATE.REQUEST_FAILED,
    requestType,
    payload: error
});

const fetchingSuccess = (requestType, response) => ({
    type: BASIC_WAGE_STATE.FETCH_SUCCESS,
    requestType,
    payload: response
});

const creatingSuccess = (response) => ({
    type: BASIC_WAGE_STATE.CREATE_SUCCESS,
    payload: response
});

const updatingSuccess = (response) => ({
    type: BASIC_WAGE_STATE.UPDATE_SUCCESS,
    payload: response
});

const deletingSuccess = (response) => ({
    type: BASIC_WAGE_STATE.DELETE_SUCCESS,
    payload: response
});

export const getBasicWage = (params) => {
    return dispatch => {
        dispatch(requesting('dt'));
        BASIC_WAGE.get(params)
            .then(
                response => {
                    dispatch(fetchingSuccess('dt', response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestFailed('dt', response.body.message));
                }
            );
    };
};

export const postBasicWage = (params, setErrors) => {
    return async dispatch => {
        dispatch(requesting('post'));
        await BASIC_WAGE.post(params)
            .then(
                response => {
                    dispatch(creatingSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    const code = response.meta.code;
                    if(code === 422) {
                        const errMsg = transformObject(response.body.message);
                        setErrors(errMsg);
                        dispatch(requestFailed('validate', errMsg));
                    } else {
                        dispatch(requestFailed('post', response.body.message));
                    }
                }
            );
    };
};

export const updateBasicWage = (params, setErrors) => {
    return async dispatch => {
        dispatch(requesting('update'));
        await BASIC_WAGE.put(params)
            .then(
                response => {
                    dispatch(updatingSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    const code = response.meta.code;
                    if(code === 422) {
                        const errMsg = transformObject(response.body.message);
                        setErrors(errMsg);
                        dispatch(requestFailed('validate', errMsg));
                    } else {
                        dispatch(requestFailed('update', response.body.message));
                    }
                }
            );
    };
};

export const removeBasicWage = (params) => {
    return async dispatch => {
        dispatch(requesting('delete'));
        await BASIC_WAGE.remove(params)
            .then(
                response => {
                    dispatch(deletingSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestFailed('delete', response.body.message));
                }
            );
    };
};

