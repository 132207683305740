import { AUTH } from './../../services';
import { transformObject } from '../../helpers';

export const PROFILE_STATE = {
    REQUEST: 'PROFILE_REQUEST',
    REQUEST_FAILED: 'PROFILE_REQUEST_FAILED',
    FETCH_SUCCESS: 'PROFILE_FETCH_SUCCESS',
    UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
};

const requesting = (requestType) => ({
    type: PROFILE_STATE.REQUEST,
    requestType
});

const requestFailed = (requestType, error) => ({
    type: PROFILE_STATE.REQUEST_FAILED,
    requestType,
    payload: error,
});

const fetchingProfileSuccess = (response) => ({
    type: PROFILE_STATE.FETCH_SUCCESS,
    payload: response
});

const updatingProfileSuccess = (response) => ({
    type: PROFILE_STATE.UPDATE_SUCCESS,
    payload: response
});

export const getProfile = (params) => {
    return (dispatch) => {
        dispatch(requesting('get'));
        AUTH.getProfile(params)
            .then(
                response => {
                    dispatch(fetchingProfileSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestFailed('get', response.body.message));
                }
        );
    };
};

export const updateProfile = (params, setErrors) => {
    return (dispatch) => {
        dispatch(requesting('update'));
        AUTH.putProfile(params)
            .then(
                response => {
                    dispatch(updatingProfileSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    const code = response.meta.code;
                    if(code === 422) {
                        const errMsg = transformObject(response.body.message);
                        setErrors(errMsg);
                        dispatch(requestFailed('validate', errMsg));
                    } else {
                        dispatch(requestFailed('update', response.body.message));
                    }
                }
        );
    };
};
