import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { debounce } from 'lodash';
import { compareArray, thousand, dateFormatStd } from '../../helpers';
import { Datatable } from '../../components/datatables';
import { DtSearch } from '../../components/ui';

class PayrollDatatable extends Component {

    renderTableBody = (dataSource) => {
        const { selectedAccount, completedIds, handleSelect, toggleModal, toggleConfirmDelete } = this.props;
        if(Array.isArray(dataSource) && dataSource.length) {
            return dataSource.map((value, index) => {
                const {
                    id, fullname, rank, wage_period_from, wage_period_to, coc_date, total_days,
                    bal_from_last_month, sub_total_earnings, total_earnings,
                    total_deductions, bal_carried_to_next_month, bal_to_next_month
                } = value;

                return (
                    <tr key={id} className={`${completedIds.includes(id) ? ' completed' : ''}`}>
                        <td className="index-td">
                            <form>
                                <input type="checkbox" id={`crew_cb_${id}`} name={`crew_cb_${id}`}
                                    className="filled-in chk-col-green"
                                    checked={ selectedAccount.includes(id) ? true : false }
                                    onChange={() => handleSelect(id)}
                                />
                                <label htmlFor={`crew_cb_${id}`}></label>
                            </form>
                        </td>
                        <td className="text-center">{index+1}</td>
                        <td className="text-name">{fullname}</td>
                        <td className="text-center">{rank}</td>
                        <td className="text-center">{wage_period_from}</td>
                        <td className="text-center">{wage_period_to}</td>
                        <td className="text-center">{total_days}</td>
                        <td className="text-date">{dateFormatStd(coc_date)}</td>
                        <td className="text-center">{thousand(bal_from_last_month)}</td>
                        <td className="text-center">{thousand(total_earnings)}</td>
                        <td className="text-center">{thousand(sub_total_earnings)}</td>
                        <td className="text-center">{thousand(total_deductions)}</td>
                        <td className="text-center">{thousand(bal_carried_to_next_month)}</td>
                        <td className="text-center">{thousand(bal_to_next_month)}</td>
                        <td className="text-actions payroll">
                            <ReactTooltip id={`action-${id}`}/>
                            <button
                                className="btn btn-circle btn-sm bg-green waves-effect waves-circle mr-7"
                                data-tip="Edit Payroll Detail" data-for={`action-${id}`}
                                onClick={() => toggleModal('edit', value)}>
                                <i className="material-icons">edit</i>
                            </button>
                            {/* <button
                                className="btn btn-circle btn-sm bg-blue waves-effect waves-circle mr-7"
                                data-tip="Promote / Demote Crew" data-for={`action-${id}`}
                                onClick={() => toggleModal('promote', value)}>
                                <i className="material-icons">swap_vert</i>
                            </button> */}
                            <button
                                className={`btn btn-circle btn-sm bg-red waves-effect waves-circle mr-7 ${!completedIds.includes(id) ? '' : ' disabled'}`}
                                data-tip="Delete Payroll" data-for={`action-${id}`}
                                disabled={!completedIds.includes(id) ? false : true}
                                onClick={
                                    !completedIds.includes(id) ? 
                                    () => toggleConfirmDelete(id) :
                                    undefined
                                }
                            >
                                <i className="material-icons">delete_forever</i>
                            </button>
                        </td>
                    </tr>
                );
            });
        }
    }

    renderHeaderCheckbox = () => {
        const { accountIds, selectedAccount, completedIds, handleSelect } = this.props;
        let remainingIds = accountIds.filter(id => !completedIds.includes(id));
        return (
            <th className="index-th index-th-check">
                <form>
                    <input type="checkbox" name="account_all" id={`account_all`}
                        className="filled-in chk-col-green"
                        checked={ 
                            accountIds.length && 
                            remainingIds.length &&
                            compareArray(selectedAccount, remainingIds) ? 
                            true : 
                            false 
                        }
                        onChange={() => handleSelect('all')}
                    />
                    <label htmlFor={`account_all`}></label>
                </form>
            </th>
        );
    }

    renderCustomFilter = () => {
        const handleSearchParent = debounce((keyword) => {
            this.props.handleSearch(keyword);
        }, 500);

        return (
            <div className="row clearfix dt-body-filter">
                <div className="col-md-6 col-lg-6 col-sm-6">
                    <div className="payroll-legend">
                        Legend : 
                        <span className="box-color legend-grey"></span><span className="text"> Sent</span>
                        <span className="box-color"></span><span className="text"> Not Sent</span>
                    </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6">
                    <DtSearch
                        placeholder={'Search'}
                        icon={'search'}
                        onChange={handleSearchParent}
                    />
                </div>
            </div>
        );
    }

    render() {
        const {  isLoading, dataSource, reloadDatatable, toggleModal } = this.props;
        const datatableHeader = [
            {label: 'Full Name', field: 'fullname'},
            {label: 'Rank', field: 'rank'},
            {label: 'Wage Period', field: '', colspan: '2', tooltip: 'Wage Period'},
            {label: 'TD', field: 'total_days', tooltip: 'Total Days'},
            {label: 'COC Date', field: 'coc_date'},
            {label: 'BLM', field: 'bal_from_last_month', tooltip: 'Balance from Last Month'},
            {label: 'TE', field: 'total_earnings', tooltip: 'Total Earnings'},
            {label: 'SE', field: 'sub_total_earnings', tooltip: 'Subtotal Earnings'},
            {label: 'TD', field: 'total_deductions', tooltip: 'Total Deductions'},
            {label: 'BCNM', field: 'bal_carried_to_next_month', tooltip: 'Balance Carried to Next Month'},
            {label: 'BNM', field: 'bal_to_next_month', tooltip: 'Balance to Next Month'},
            {label: 'Actions', field: ''},
        ];
        
        return (
            <Datatable
                checkBox={this.renderHeaderCheckbox}
                isLoading={isLoading}
                tableHeader={datatableHeader}
                tableBody={this.renderTableBody}
                dataSource={dataSource}
                toggleModal={toggleModal}
                reloadDatatable={reloadDatatable}
                limitable={false}
                searchable={false}
                creatable={false}
                card={false}
                customFilter={this.renderCustomFilter}
                headerLength={15}
            />
        );
    }
}

export default PayrollDatatable;


