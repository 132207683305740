import React, { Component } from 'react';
import { thousand } from '../../helpers';
import ReactTooltip from 'react-tooltip';
import { Datatable } from '../../components/datatables';

class BasicWageDatatable extends Component {

    renderTableBody = (dataSource) => {
        if(Array.isArray(dataSource.data) && dataSource.data.length) {
            let { from } = dataSource;
            return dataSource.data.map((value) => {
                const {
                    id, principal, rank, basic_wages, fixed_overtime, leave, currency, over_time,
                    others, others_currency
                } = value;

                const editParams = {
                    id, principal: principal.id, rank: rank.id,
                    basic_wages, fixed_overtime, leave, over_time, currency,
                    others, others_currency,
                    principal_name: principal.name, rank_name: rank.description, rank_code: rank.code
                };

                return (
                    <tr key={id}>
                        <td className="index-td">{from++}</td>
                        <td>{principal.name}</td>
                        <td>{rank.description}</td>
                        <td>{currency}</td>
                        <td className="text-right">{thousand(basic_wages)}</td>
                        <td className="text-right">{thousand(fixed_overtime)}</td>
                        <td className="text-right">{thousand(leave)}</td>
                        <td className="text-center" style={{minWidth: '120px'}}>
                            <ReactTooltip id={`action-${id}`}/>
                            <button
                                className="btn btn-circle btn-sm bg-green waves-effect waves-circle mr-7"
                                data-tip="Detail" data-for={`action-${id}`}
                                onClick={() => this.props.toggleModal('detail', editParams)}>
                                <i className="material-icons">search</i>
                            </button>
                            <button
                                className="btn btn-circle btn-sm bg-blue waves-effect waves-circle mr-7"
                                data-tip="Edit" data-for={`action-${id}`}
                                onClick={() => this.props.toggleModal('update', editParams)}>
                                <i className="material-icons">edit</i>
                            </button>
                            <button
                                className="btn btn-circle btn-sm bg-red waves-effect waves-circle mr-7"
                                data-tip="Delete" data-for={`action-${id}`}
                                onClick={() => this.props.toggleConfirmDelete(id)}>
                                <i className="material-icons">delete_forever</i>
                            </button>
                        </td>
                    </tr>
                );
            });
        }
    }

    render() {
        const {  dataSource, currencyData, isLoading, reloadDatatable, toggleModal } = this.props;
        const datatableHeader = [
            {label: 'Principal', field: 'principal.name'},
            {label: 'Rank', field: 'rank.description'},
            {label: 'Currency', field: 'currency'},
            {label: 'Basic Wage', field: 'basic_wages'},
            {label: 'Fixed/OT', field: 'fixed_overtime'},
            {label: 'Leave', field: 'leave'},
            {label: 'Actions', field: ''},
        ];

        return (
            <Datatable
                isLoading={isLoading}
                tableHeader={datatableHeader}
                tableBody={this.renderTableBody}
                title={{main: 'Basic Wage', sub: 'Basic Wage Data Management'}}
                dataSource={dataSource}
                currencyData={currencyData}
                toggleModal={toggleModal}
                reloadDatatable={reloadDatatable}
                pagination={true}
            />
        );
    }
}

export default BasicWageDatatable;


