import * as AUTH from './auth';
import * as USER from './user';
import * as BASIC_WAGE from './basic-wage';
import * as WAGE_ACCOUNT from './wage-account';
import * as VESSEL from './vessel';
import * as RANK from './rank';
import * as PRINCIPAL from './principal';
import * as EXCHANGE_RATE from './exchange-rate';
import * as CURRENCY from './currency';
import * as GENERAL_SETTING from './general-setting';
import * as WAGE_SUMMARY from './wage-summary';
import * as REASON from './reason';
import * as NOTIFICATION from './notification';
import * as AUDIT_TRAIL from './audit-trail';

export {
    AUTH,
    USER,
    BASIC_WAGE,
    VESSEL,
    RANK,
    PRINCIPAL,
    WAGE_ACCOUNT,
    EXCHANGE_RATE,
    CURRENCY,
    GENERAL_SETTING,
    WAGE_SUMMARY,
    REASON,
    NOTIFICATION,
    AUDIT_TRAIL,
};