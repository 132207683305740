import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { compose } from 'redux';
import { getNotification } from '../notification/action';
import { logout } from '../login/action';
import { showToast } from '../../helpers';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, Badge, MenuItem, Menu, Drawer } from '@material-ui/core';
import ProfileModal from '../profile';
import Sidebar from './sidebar';
import ebu from '../../assets/images/ebu.png';

const drawerWidth = 200;

const useStyles = theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    menuItem: {
        fontSize: '13px',
    },
    title: {
        fontSize: '18px',
        fontWeight: '600',
        marginLeft: '15px',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
      drawerPaper: {
        width: drawerWidth,
    },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
      // necessary for content to be below app bar
      toolbar: theme.mixins.toolbar,
});

class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            mobileMoreAnchorEl: null,
            isMenuOpen: false,
            isMobileMenuOpen: false,
            showAccountSettings: false,
            showDrawer: true,
        };
        this.intervalId = null;
    }

    componentDidMount() {
        const interval = process.env.REACT_APP_REFRESH_NOTIF_INTERVAL;
        this.fetchNotifications();
        this.intervalId = setInterval(this.fetchNotifications.bind(this), interval);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    fetchNotifications = () => {
        const online = navigator.onLine;
        if(online) this.props.getNotification(1, 10);
    }

    toggleAccountSetting = () => {
        this.setState({ showAccountSettings: !this.state.showAccountSettings });
        this.handleMenuClose();
        this.handleMobileMenuClose();
    }

    toggleDrawer = (open) => {
        const { showDrawer } = this.state;
        const isMobile = window.innerWidth < 900; // handle minimum responsive resolution
        let margin;

        if(isMobile) {
            margin = '85px 15px 15px 15px';
        } else {
            margin = showDrawer ? '85px 15px 15px 15px' : '85px 15px 0 210px';
        }
        
        document.getElementById('app-content').style.margin  = margin;
        this.setState({ showDrawer: (open ? open : !showDrawer) });
    }

    handleClickLogout = () => {
        this.handleMenuClose();
        this.handleMobileMenuClose();

        Swal.fire({
            title: 'Logout',
            text: "Logout from Payroll ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {  
                // localStorage.removeItem('persist:root');
                this.handleLogout();
            }
        });
    }

    handleLogout = async () => {
        try {
            await this.props.logout();

        } catch (error) {
            showToast('error', 'Something is wrong. Please try again later');
        }
    }

    handleClickNotification = () => {
        this.props.history.push('/notification')
    }

    handleMenuOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget, isMenuOpen: true });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null, isMenuOpen: false });
    };
    
    handleMobileMenuOpen = (event) => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget, isMobileMenuOpen: true });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null, isMobileMenuOpen: false });
    };

    renderMenuDesktop = () => {
        const { anchorEl, isMenuOpen } = this.state; 
        const { classes } = this.props;
        return (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={'navbar-account-menu'}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem className={classes.menuItem} onClick={this.toggleAccountSetting}>Account Settings</MenuItem>
                <MenuItem className={classes.menuItem} onClick={this.handleClickLogout}>Logout</MenuItem>
            </Menu>
        );
    }

    renderMobileMenu = () => {
        const { mobileMoreAnchorEl, isMobileMenuOpen } = this.state; 
        return (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={'navbar-account-menu-mobile'}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem onClick={this.toggleAccountSetting}>Account Settings</MenuItem>
                <MenuItem onClick={this.handleClickLogout}>Logout</MenuItem>
            </Menu>
        );
    }

    render() {
        const { showDrawer } = this.state;
        const { classes, notifCount } = this.props;
        return (
            <div className={classes.grow}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => this.toggleDrawer()}
                        >
                            <i className="material-icons">
                                { showDrawer ? 'menu_open' : 'menu' } 
                            </i>
                        </IconButton>
                        <div className="navbar-header">
                            <img className="logo-navbar" src={ebu}  alt='ebu'/>
                        </div>
                        <Typography className={classes.title} variant="h3" noWrap>
                            Crew Payroll System
                        </Typography>                          
                        <div className={classes.grow} />

                        {/* Note : section for desktop  */}
                        <div className={classes.sectionDesktop}>
                            <IconButton 
                                aria-label="show 17 new notifications" 
                                color="inherit"
                                onClick={this.handleClickNotification}
                            >
                                {
                                    notifCount > 0 ?
                                    (
                                        <Badge badgeContent={notifCount} max={99} color="secondary">
                                            <i className="material-icons icon-navbar">notifications</i>
                                        </Badge>
                                    ) : (
                                        <i className="material-icons icon-navbar">notifications</i>
                                    )
                                }
                            </IconButton>
                            <IconButton
                                edge="end"
                                aria-label="show more"
                                aria-controls={'navbar-account-menu'}
                                aria-haspopup="true"
                                onClick={this.handleMenuOpen}
                                color="inherit"
                            >
                                <i className="material-icons icon-navbar">account_circle</i>
                            </IconButton>
                        </div>

                        {/* Note : section for mobile  */}
                        <div className={classes.sectionMobile}>
                            <IconButton 
                                aria-label={`show ${notifCount} new notifications`}
                                color="inherit"
                                onClick={this.handleClickNotification}
                            >
                                {
                                    notifCount > 0 ?
                                    (
                                        <Badge badgeContent={notifCount} color="secondary">
                                            <i className="material-icons icon-navbar">notifications</i>
                                        </Badge>
                                    ) : (
                                        <i className="material-icons icon-navbar">notifications</i>
                                    )
                                }
                            </IconButton>
                            <IconButton
                                aria-label="show more"
                                aria-controls={'navbar-account-menu-mobile'}
                                aria-haspopup="true"
                                onClick={this.handleMobileMenuOpen}
                                color="inherit"
                            >
                                <i className="material-icons icon-navbar">account_circle</i>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="left"
                    open={this.state.showDrawer}
                >
                    <Sidebar/>
                </Drawer>
                { this.renderMobileMenu() }
                { this.renderMenuDesktop() }
                <ProfileModal
                    isOpen={this.state.showAccountSettings}
                    formData={this.state.formData}
                    handleClose={this.toggleAccountSetting}
                    errorMessage={this.props.errorProfile}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ auth, profile, notification }) => ({
    token: auth.token,
    user: auth.user,
    errorProfile : profile.errorMessage,
    notifCount: notification.notifIds ? notification.notifIds.length : 0,
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
    getNotification: (params) => dispatch(getNotification(params)),
});

const NavbarStyle = withStyles(useStyles)(Navbar);
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(NavbarStyle);