import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import * as Yup from 'yup';

const components = {
    DropdownIndicator: null,
};

const createOption = (label) => ({
    label,
    value: label,
});

class InputCreateable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            value: [],
            error: null,
            errorMessage: null,
        };
    }

    componentDidMount() {
        this.setState({ value: this.props.defaultValue });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.value !== this.state.value) {
            this.props.onChange({
                name: this.props.name,
                value: this.state.value
            });
        }
    }

    handleChange = (value, actionMeta) => {
        this.setState({ value });
    };

    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    };

    handleValidation = (inputValue, type) => {
        let schema = {};
        switch (type) {
            case 'email':
                schema = Yup.string().email("Please input a valid email format");
                break;
            case 'number':
                schema = Yup.string().number();
                break;
            default :
                break;
        }

        try {
            schema.validateSync(inputValue);
            return true;
        } catch (error) {
            if(error.errors.length) {
                this.setState({
                    error: 'validate',
                    errorMessage: error.errors[0]
                });
            }
            return false;
        }
    }

    handleKeyDown = (event) => {
        const { inputValue, value } = this.state;
        if (!inputValue) return;

        if(this.state.error) { // return error state to normal
            this.setState({
                error: null,
                errorMessage: null
            });
        }

        switch (event.key) {
            case 'Enter':
            case 'Tab':
                const validate = this.handleValidation(inputValue, 'email');
                if(!validate) return;
                if (Array.isArray(value) && value.length > 0) {
                    this.setState({
                        inputValue: '',
                        value: [...value, createOption(inputValue)],
                    });
                } else {
                    this.setState({
                        inputValue: '',
                        value: [createOption(inputValue)],
                    });
                }
                event.preventDefault();
                break;
            default :
                break;
                
        }
    };

    render() {
        const { inputValue, value, error, errorMessage } = this.state;
        const { placeholder, className, label, mandatory, name, line, note, noMargin } = this.props;
        return (
            <div>
                {
                    label ? 
                    (
                        <label htmlFor={name}>
                            {label} {mandatory ? <span className="mandatory-icon">*</span> : ''}
                        </label>
                    ) : ''
                }
                <div className={`form-group ${noMargin ? 'm-0' : ''}`}>
                    <div className={`${line === false ? '' : 'form-line'}`}>
                        <CreatableSelect
                            className={className ? className : null}
                            classNamePrefix={className ? className : null}
                            components={components}
                            inputValue={inputValue}
                            isClearable={false}
                            isMulti
                            menuIsOpen={false}
                            onChange={this.handleChange}
                            onInputChange={this.handleInputChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={placeholder ? placeholder : "Input and press enter..."}
                            value={value}
                        />
                    </div>
                    {
                        error ? 
                        (
                            <div className="block">
                                <span className="mini-note text-error">* {errorMessage}</span>
                            </div>
                        ) : ''
                        
                    }
                    {
                        note ?
                        (
                            <div className="block">
                                <span className="mini-note">* {note}</span>
                            </div>
                        ) : ''
                    }
                </div>
            </div>
        );
    }
}

export default InputCreateable;