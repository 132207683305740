import { VESSEL_STATE } from './action';

const INITIAL_STATE = {
    dtLoading: false,
    datatable: [],
    datatableAll: [],
    success: null,
    error: null,
    errorMessage: null,
};

const vesselReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VESSEL_STATE.REQUEST:
            return {
                ...state,
                dtLoading: true,
                success: null,
                error: null,
                errorMessage: null,
            };
        case VESSEL_STATE.REQUEST_FAILED:
            return {
                ...state,
                dtLoading: false,
                error: action.requestType,
                errorMessage: action.payload,
                datatable: [],
            };
        case VESSEL_STATE.FETCH_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                success: 'dt',
                datatable: action.payload.contents.vessels
            };
        case VESSEL_STATE.FETCH_ALL_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                success: 'all',
                datatableAll: action.payload.contents.vessels
            };
        default:
            return state;
    }
};

export default vesselReducer;
