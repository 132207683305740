import React, {Component} from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { BrowserHistory } from '../config';
import ErrorBoundary from '../pages/errors/error-boundaries';
import Public from './public';
import Private from './private';
import PrivateRoute from './private-route';
import LoginPage from './../pages/login';
import ForgotPasswordPage from './../pages/forgot-password';
import ResetPasswordPage from './../pages/reset-password';
import VerifyPasswordPage from './../pages/verify-password';
import HomePage from '../pages/home';
import UserPage from '../pages/user';
import BasicWagePage from '../pages/basic-wage';
import ExchangeRatePage from '../pages/exchange-rate';
import WageSummaryPage from '../pages/wage-summary';
import GeneratePayrollPage from '../pages/generate-payroll';
import PayrollTransactionPage from '../pages/payroll-transaction';
import GeneralSettingPage from '../pages/general-setting';
import NotificationPage from '../pages/notification';
import AuditTrailPage from '../pages/audit-trail';

class Routes extends Component {
    render(){
        return (
            <ErrorBoundary>
                <Router history={BrowserHistory}>
                    <Switch>
                        {/* Add into this list if you're creating new URI */}
                        <Route exact path={[
                            "/", "/basic-wage", "/user", "/exchange-rate", "/wage-summary", "/generate-payroll",
                            "/payroll-transaction", "/general-setting", "/notification", "/audit-trail"
                        ]}>  
                            <Private>
                                <PrivateRoute exact path="/" component={HomePage} authed={this.props.token} />
                                <PrivateRoute exact path="/generate-payroll" component={GeneratePayrollPage} authed={this.props.token} />
                                <PrivateRoute exact path="/payroll-transaction" component={PayrollTransactionPage} authed={this.props.token} />
                                <PrivateRoute exact path="/wage-summary" component={WageSummaryPage} authed={this.props.token} />
                                <PrivateRoute exact path="/basic-wage" component={BasicWagePage} authed={this.props.token} />
                                <PrivateRoute exact path="/exchange-rate" component={ExchangeRatePage} authed={this.props.token} />
                                <PrivateRoute exact path="/user" component={UserPage} authed={this.props.token} />
                                <PrivateRoute exact path="/general-setting" component={GeneralSettingPage} authed={this.props.token} />
                                <PrivateRoute exact path="/notification" component={NotificationPage} authed={this.props.token} />
                                <PrivateRoute exact path="/audit-trail" component={AuditTrailPage} authed={this.props.token} />
                            </Private>
                        </Route>
                        <Route exact>
                            <Public>
                                <Route path="/login" component={LoginPage}/>
                                <Route path="/forgot-password" component={ForgotPasswordPage}/>
                                <Route path="/password/reset" component={ResetPasswordPage}/>
                                <Route path="/verify/password/reset" component={VerifyPasswordPage}/>
                            </Public>
                        </Route>
                    </Switch>
                </Router>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = ({auth}) => ({
    token : auth.token
});

export default connect(
    mapStateToProps
)(Routes);