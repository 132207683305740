import React, { Component } from "react";
import { connect } from "react-redux";
import { formChanged, submitTab, resetSubmit } from "../action";
import { showPopup, showToast, dateFormatStd } from "../../../helpers";
import { Tabs } from "../../../components/ui";
import Modal from "../../../components/modal";
import EarningSection from "./earning";
import DeductionSection from "./deduction";
import RemarkSection from "./remark";

class PayrollModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "remarks",
      data: {},
      accountId: null,
      previousPayroll: false,
      resetKey: 123,
    };
    this.initialState = this.state;
  }

  UNSAFE_componentWillMount() {
    this.setState({ data: this.props.dataSource });
  }

  componentDidMount() {
    if (this.props.dataPrevious.includes(this.props.dataSource.contract_id)) {
      // check if previous payroll record existed
      this.setState({ previousPayroll: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (this.props.isOpen === "detail" && nextProps.isOpen === "close") {
      this.setState(this.initialState);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.trigger === "PAYROLL_UPDATE_SUCCESS" &&
      !this.props.closeModal &&
      this.props.nextTab &&
      prevState.activeTab !== this.props.nextTab
    ) {
      this.handleChangeTab(this.props.nextTab);
      this.props.resetSubmit(false);
    }

    if (
      !prevProps.payroll.error &&
      this.props.payroll.errorMessage &&
      this.props.payroll.error === "update-earning"
    ) {
      showToast("error", this.props.payroll.errorMessage);
    }
  }

  handleRefreshSection = () => {
    this.setState({ resetKey: Math.random() });
  };

  handleCancelAndClose = () => {
    this.props.handleClose();
  };

  handleChangeTab = (nextTab) => {
    const { activeTab } = this.state;
    const { dataChanged, formEditing } = this.props;

    const tabChanged = () => {
      this.setState({ activeTab: nextTab });
      if (dataChanged) this.props.formChanged(false);
    };

    if (dataChanged || formEditing) {
      showPopup(
        "custom-confirm",
        "You haven't saved the data. Do you want to save ?",
        "Save",
        "question",
        "default",
        () => {
          this.props.submitTab(true, false, activeTab, nextTab);
          tabChanged();
        }
      );
    } else {
      this.props.submitTab(true, false, activeTab, nextTab);
      tabChanged();
    }
  };

  renderTopProfile = () => {
    const { data } = this.state;
    return (
      <div className="row top-profile">
        <div className="profile-label col-lg-4 col-md-4">
          {"Vessel"} &nbsp;&nbsp;
          <span className="profile-label-content">
            {data ? data.vessel.name : ""}
          </span>
        </div>
        <div className="profile-label col-lg-4 col-md-4">
          {"Name"} &nbsp;&nbsp;
          <span className="profile-label-content">
            {data ? data.fullname : ""}
          </span>
        </div>
        <div className="profile-label col-lg-4 col-md-4">
          {"Wage Period"} &nbsp;&nbsp;
          <span className="profile-label-content">
            {data ? dateFormatStd(data.account_period_from, "M-Y") : ""}
          </span>
        </div>
        <div className="profile-label col-lg-4 col-md-4">
          {"CSC"} &nbsp;&nbsp;
          <span className="profile-label-content">
            {data ? (data.csc === 1 ? "CSC" : "NON-CSC") : ""}
          </span>
        </div>
        <div className="profile-label col-lg-4 col-md-4">
          {"Rank"} &nbsp;&nbsp;
          <span className="profile-label-content">{data ? data.rank : ""}</span>
        </div>
        <div className="profile-label col-lg-4 col-md-4">
          {"COC Date"} &nbsp;&nbsp;
          <span className="profile-label-content">
            {data ? dateFormatStd(data.coc_date) : ""}
          </span>
        </div>
      </div>
    );
  };

  renderBottomProfile = () => {
    const { data } = this.state;
    return (
      <div className="row bottom-profile">
        <div className="col-lg-5 col-md-5 m-0">
          <div className="title">Vessel</div>
          <div className="content">{data ? data.vessel.name : ""}</div>
        </div>
        <div className="col-lg-5 col-md-5 m-0">
          <div className="title">Name</div>
          <div className="content">{data ? data.fullname : ""}</div>
        </div>
        <div className="col-lg-2 col-md-2 m-0">
          <div className="title">Rank</div>
          <div className="content">{data ? data.rank : ""}</div>
        </div>
      </div>
    );
  };

  render() {
    const { data, activeTab, resetKey, previousPayroll } = this.state;
    const { isOpen, csc } = this.props;

    if (data) {
      return (
        <Modal
          isOpen={isOpen === "edit" ? true : false}
          background={"bg-green"}
          classBody={"payroll"}
          size={"modal-90"}
          title={"Edit Payroll Detail"}
          onClose={this.handleCancelAndClose}
          removeClose={true}
          content={
            <Tabs
              classNav={"tab-payroll"}
              activeTab={activeTab}
              handleChangeTab={this.handleChangeTab}
              tabs={[
                { name: "remarks", text: "Remarks", icon: "date_range" },
                {
                  name: "earnings",
                  text: "Earnings",
                  icon: "add_circle_outline",
                },
                {
                  name: "deductions",
                  text: "Deductions",
                  icon: "remove_circle_outline",
                },
              ]}
              contents={[
                {
                  name: "remarks",
                  content: (
                    <RemarkSection
                      key={resetKey}
                      handleClose={this.handleCancelAndClose}
                      dataSource={data}
                      previousDataExisted={previousPayroll}
                      csc={csc}
                      profile={this.renderTopProfile}
                      activeTab={activeTab}
                    />
                  ),
                },
                {
                  name: "earnings",
                  content: (
                    <EarningSection
                      key={resetKey}
                      handleClose={this.handleCancelAndClose}
                      dataSource={data}
                      previousDataExisted={previousPayroll}
                      csc={csc}
                      profile={this.renderTopProfile}
                      activeTab={activeTab}
                    />
                  ),
                },
                {
                  name: "deductions",
                  content: (
                    <DeductionSection
                      key={resetKey}
                      handleClose={this.handleCancelAndClose}
                      dataSource={data}
                      previousDataExisted={previousPayroll}
                      csc={csc}
                      profile={this.renderTopProfile}
                      activeTab={activeTab}
                    />
                  ),
                },
              ]}
            />
          }
        />
      );
    }
  }
}

const mapStateToProps = ({ payroll }) => ({
  payroll,
  closeModal: payroll.closeModal,
  updatedParams: payroll.updatedParams,
  formEditing: payroll.formEditing,
  dataChanged: payroll.formChanged,
  submitStatus: payroll.submitStatus,
  currentTab: payroll.currentTab,
  nextTab: payroll.nextTab,
  trigger: payroll.trigger,
});

const mapDispatchToProps = (dispatch) => ({
  formChanged: (params) => dispatch(formChanged(params)),
  submitTab: (submitStatus, closeModal, currentTab, nextTab) =>
    dispatch(submitTab(submitStatus, closeModal, currentTab, nextTab)),
  resetSubmit: (closeModal) => dispatch(resetSubmit(closeModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollModal);
