import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from '../pages/auth/reducer';
import loginReducer from '../pages/login/reducer';
import forgotPasswordReducer from '../pages/forgot-password/reducer';
import resetPasswordReducer from '../pages/reset-password/reducer';
import verifyPasswordReducer from '../pages/verify-password/reducer';
import profileReducer from '../pages/profile/reducer';
import userReducer from '../pages/user/reducer';
import basicWageReducer from '../pages/basic-wage/reducer';
import vesselReducer from '../pages/vessel/reducer';
import rankReducer from '../pages/rank/reducer';
import principalReducer from '../pages/principal/reducer';
import generateReducer from '../pages/generate-payroll/reducer';
import payrollReducer from '../pages/payroll-transaction/reducer';
import exchangeRateReducer from '../pages/exchange-rate/reducer';
import currencyReducer from '../pages/currency/reducer';
import generalSettingReducer from '../pages/general-setting/reducer';
import summaryReducer from '../pages/wage-summary/reducer';
import reasonReducer from '../pages/reason/reducer';
import globalReducer from '../pages/global/reducer';
import notificationReducer from '../pages/notification/reducer';
import auditTrailReducer from '../pages/audit-trail/reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'currency', 'vessel']
};

const allReducer = {
    auth: authReducer,
    login: loginReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    verifyPassword: verifyPasswordReducer,
    profile: profileReducer,
    user: userReducer,
    basicWage: basicWageReducer,
    vessel: vesselReducer,
    rank: rankReducer,
    principal: principalReducer,
    generate: generateReducer,
    payroll: payrollReducer,
    exchangeRate: exchangeRateReducer,
    currency: currencyReducer,
    generalSetting: generalSettingReducer,
    summary: summaryReducer,
    reason: reasonReducer,
    global: globalReducer,
    notification: notificationReducer,
    auditTrail: auditTrailReducer,
};

export default persistCombineReducers(persistConfig, allReducer);

// *** DEVNOTE : replace with this code to reset persist storage on logout ***

// const appReducer = persistCombineReducers(persistConfig, allReducer);
// const rootReducer = (state, action) => {
//     if (action.type === 'LOGOUT_SUCCESS') {
//         storage.removeItem('persist:root');
//         state = undefined;
//     }
//     return appReducer(state, action);
// }

// export default rootReducer;

