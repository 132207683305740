import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  showPopup,
  revertToDBFormat,
  strToDate,
  convEmptyStringToCustom,
} from "../../../helpers";
import {
  updateEarningPayroll,
  formChanged,
  formEditing,
  submitTab,
  resetSubmit,
} from "../action";
import { MInput, MDatepicker } from "../../../components/material/form";
import { Button, Select } from "../../../components/ui";
import DynamicFields from "../../../components/dynamic-fields";

class DeductionSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      additionalData: [],
      fixedData: [],
      converted_currency: this.props.dataSource.converted_currency
        ? this.props.dataSource.converted_currency
        : "USD", // default converter currency
      initialValues: {},
    };
    this.initialState = this.state;
    this.formik = React.createRef();
  }

  UNSAFE_componentWillMount() {
    if (this.props.dataSource) {
      const { dataSource } = this.props;
      this.setState({
        initialValues: {
          sum_cash_by_master: dataSource.sum_cash_by_master,
          bal_of_wages_upon_sign_off: dataSource.bal_of_wages_upon_sign_off,
          money_orders_amounts: dataSource.money_orders_amounts,
          money_orders_date: dataSource.money_orders_date,
          allotments: dataSource.allotments,
          // allotments_paid_by: dataSource.allotments_paid_by || "EBU",
          allotments_paid_by: "EQUINOX",
        },
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { trigger, submitStatus, nextTab, currentTab } = nextProps;
    if (
      trigger === "PAYROLL_SUBMIT_TAB" &&
      submitStatus &&
      currentTab === "deductions" &&
      nextTab !== "deductions"
    ) {
      this.formik.current.handleSubmit();
      this.props.resetSubmit(false);
    }
  }

  handleConverterChange = (param) => {
    this.setState({ converted_currency: param.value });
    this.props.formChanged(true);
  };

  handleChange = (data) => {
    if (data.type === "dynamic") {
      this.setState({ additionalData: data.params });
    }

    const status = data.changed !== undefined ? data.changed : false;
    this.props.formChanged(status);
  };

  handleClickSubmit = (closeModal, nextTab = null) => {
    this.props.submitTab(false, closeModal, "deductions", nextTab);
    this.formik.current.handleSubmit();
  };

  handleSubmit = (params) => {
    this.props.updateEarningPayroll(params);
  };

  handleSubmitAndOthers = (params) => {
    const result = revertToDBFormat(this.state.additionalData);
    const convertedParams = convEmptyStringToCustom(params, null);
    const mergedValues = {
      ...convertedParams,
      id: this.props.dataSource.id,
      converted_currency: this.state.converted_currency,
      deductions_others: result.values, // Uncommand this if you don't want to prorates value
      deductions_others_prorates: result.prorates, // using prorates data
      deductions_others_currency: result.currencies,
    };

    this.handleSubmit(mergedValues);
  };

  handleFormEditing = (editing) => {
    this.props.formEditing(editing);
  };

  handleBlur = () => {
    if (!this.props.dataChanged) this.props.formChanged(true); // manual override dataChanged state to dirty/false
  };

  handleCancelAndClose = () => {
    this.props.formChanged(true);
    if (this.props.dataChanged) {
      showPopup(
        "custom-confirm",
        "You haven't saved the data. Cancel the proceed anyway? Unsaved data may occurred",
        "Discard changes",
        "warning",
        "reversed",
        () => {
          this.props.handleClose();
        }
      );
    } else {
      this.props.handleClose();
    }
  };

  renderForm = () => {
    const { money_orders_date } = this.props.dataSource;
    const { csc } = this.props;
    return (
      <div>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-6">
            <MInput
              label="Sum Cash by Master"
              name="sum_cash_by_master"
              type="currency"
              placeholder="0.00"
              fullWidth
              noMargin
              onBlur={this.handleBlur}
            />
          </div>
          <div className="col-lg-6 col-md-6">
            {csc === 1 ? (
              ""
            ) : (
              <MInput
                label="Bal. of Wages Upon Sign Off"
                name="bal_of_wages_upon_sign_off"
                type="currency"
                fullWidth
                noMargin
                placeholder="0.00"
                onBlur={this.handleBlur}
              />
            )}
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-6">
            <MInput
              label="Remittance Amounts"
              name="money_orders_amounts"
              type="currency"
              placeholder="0.00"
              fullWidth
              noMargin
              onBlur={this.handleBlur}
            />
          </div>
          <div className="col-lg-6 col-md-6">
            <MDatepicker
              className={"datepicker-filter"}
              name={"money_orders_date"}
              label={"Remittance Date"}
              placeholder="Select date"
              icon={"date_range"}
              setValue={
                money_orders_date ? strToDate(money_orders_date) : undefined
              }
              onChange={this.handleBlur}
            />
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-6">
            <MInput
              label="Allotments"
              name="allotments"
              type="currency"
              placeholder="0.00"
              fullWidth
              noMargin
              onBlur={this.handleBlur}
            />
          </div>
          <div className="col-lg-6 col-md-6">
            <MInput
              label="Allotments Paid By"
              name="allotments_paid_by"
              type="text"
              fullWidth
              noMargin
              onBlur={this.handleBlur}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { dataSource } = this.props;
    const { loading, currencyData, editing } = this.props;
    const { converted_currency } = this.state;
    const dynamicData = {
      params: dataSource.deductions_others,
      currencies: dataSource.deductions_others_currency,
      wageDays: dataSource.total_days,
    };

    const validation = Yup.object({
      sum_cash_by_master: Yup.string().nullable(),
      money_orders_amounts: Yup.string().nullable(),
      money_orders_date: Yup.string().nullable(),
      allotments: Yup.string().nullable(),
      allotments_paid_by: Yup.string().nullable(),
    });

    return (
      <div>
        <Formik
          innerRef={this.formik}
          initialValues={this.state.initialValues}
          validationSchema={validation}
          onSubmit={this.handleSubmitAndOthers}
          enableReinitialize
        >
          {(formikProps) => (
            <Form>
              <div className="tab-body tab-edit-payroll">
                <div className="row">
                  <div className="col-lg-12 m-0">{this.props.profile()}</div>
                </div>
                <div className="row">
                  <div className="col-lg-6 m-0">
                    <h4 className="title-separator">Deductions</h4>
                    {this.renderForm()}
                  </div>
                  <div className="col-lg-6">
                    <h4 className="title-separator">Additional Deductions</h4>
                    <DynamicFields
                      handleSubmit={this.handleChange}
                      handleEditing={this.handleFormEditing}
                      dataSource={dynamicData}
                      currencyData={currencyData}
                      editable={true}
                      btnAddColor={"bg-red"}
                    />
                  </div>
                </div>
              </div>
              <div className="tab-footer currency p-0">
                <div className="currency-converter">
                  <span>Convert Currency Report to </span>
                  <Select
                    label="converted_currency"
                    className=""
                    name="converted_currency"
                    placeholder="Currency"
                    mandatory="true"
                    data={currencyData}
                    keyPair={["id", "name"]}
                    setValue={converted_currency}
                    onChange={this.handleConverterChange}
                    onlyValue={true}
                  />
                </div>
                <div className="button-footer">
                  {/* <Button
                    classCustom={"btn-lg mr-7"}
                    color={"bg-green"}
                    type={"button"}
                    disabled={editing || loading ? true : false}
                    loading={loading}
                    text="Update"
                    onClick={() => this.handleClickSubmit(false, null)}
                  /> */}
                  <Button
                    classCustom={"btn-lg mr-7"}
                    color={"bg-blue"}
                    type={"button"}
                    disabled={editing || loading ? true : false}
                    loading={loading}
                    text="Update &amp; Finish"
                    onClick={() => this.handleClickSubmit(true, null)}
                  />
                  {/* <button
                    type="button"
                    onClick={this.handleCancelAndClose}
                    className="btn btn-link waves-effect"
                    data-dismiss="modal"
                  >
                    Close
                  </button> */}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = ({ payroll, currency }) => ({
  payroll,
  loading: payroll.loading,
  formReset: payroll.formReset,
  dataChanged: payroll.formChanged,
  editing: payroll.formEditing,
  currencyData: currency.datatable,
  submitStatus: payroll.submitStatus,
  currentTab: payroll.currentTab,
  nextTab: payroll.nextTab,
  trigger: payroll.trigger,
});

const mapDispatchToProps = (dispatch) => ({
  updateEarningPayroll: (params) => dispatch(updateEarningPayroll(params)),
  formChanged: (params) => dispatch(formChanged(params)),
  formEditing: (params) => dispatch(formEditing(params)),
  submitTab: (submitStatus, closeModal, currentTab, nextTab) =>
    dispatch(submitTab(submitStatus, closeModal, currentTab, nextTab)),
  resetSubmit: (closeModal) => dispatch(resetSubmit(closeModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeductionSection);
