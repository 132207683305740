import React, { Component } from 'react';
import { connect } from 'react-redux';
import { startOfMonth, lastDayOfMonth } from 'date-fns';
import { getCrew, generatePayroll } from './action';
import { showPopup, showToast, strToDate, compareArray, convDate } from '../../helpers';
import { Button } from '../../components/ui';
import PayrollFilter from '../../components/payroll-filter';
import CrewDatatable from './datatable';

class GeneratePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            start_period: convDate(startOfMonth(new Date(), 1)),
            end_period: convDate(lastDayOfMonth(new Date(), 1)),
            ids: [],
            dtFilter: { keyword: '' }
        };
        this.initialState = this.state;
    }

    componentDidMount() {
        const { wage_period } = this.props.globalFilter;
        const converted = this.convertPeriod(wage_period);

        this.setState({ 
            start_period: converted.start_period,
            end_period: converted.end_period
        }, () => { 
            this.fetchCrewDatatable();
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if( !this.props.error && nextProps.success === 'dt-crew' 
            && nextProps.existedIds && nextProps.loading === false
        ) {
            this.setState({ ids: nextProps.existedIds }); // fill checked ids from existedIds response 
        }
    }

    componentDidUpdate(prevProps) {
        if(!prevProps.success && this.props.success === 'generate' && this.props.loading === false ) {
            showToast('success', 'Payroll data successfully generated');
            this.fetchCrewDatatable();
        }

        if(!prevProps.error && this.props.error === 'generate' && this.props.loading === false) {
            showPopup('error', this.props.errorMessage);
        }
    }

    fetchCrewDatatable = () => {
        const { vessel_id, csc } = this.props.globalFilter;
        const { dtFilter, start_period, end_period } = this.state;

        this.props.getCrew({  // DevNote: generate-payroll is using start_period & end_period date range instead wage_period
            keyword: dtFilter.keyword,
            vessel_id,
            csc,
            start_period, 
            end_period,
        });
    }

    checkReady = (withCrewSelect) => {
        const { ids } = this.state;
        const { principal_id, vessel_id, csc, wage_period } = this.props.globalFilter;

        if( principal_id && vessel_id && csc !== null && wage_period ) {
            if(withCrewSelect && withCrewSelect === 'crew') return ids.length > 0 ? true : false;
            return true;
        }
        return false;
    }

    convertPeriod = (wagePeriod) => {
        const date = strToDate(wagePeriod);
        return {
            start_period: convDate(startOfMonth(date)), // first day of wagePeriod month
            end_period: convDate(lastDayOfMonth(date)) // last day of wagePeriod month
        }
    }

    handleSearch = (keyword) => {
        this.setState(
            { dtFilter : {...this.state.dtFilter, keyword} },
            () => this.fetchCrewDatatable()
        );
    }

    handleFilterChange = (params) => {
        if(params.wage_period) {
            const converted = this.convertPeriod(params.wage_period);
            this.setState({ 
                start_period: converted.start_period,
                end_period: converted.end_period
            });
        }

        this.setState(params, () => {
            this.fetchCrewDatatable();
        });
    }

    handleSelectCrew = (selectedId) => {
        const stateId = this.state.ids;
        const allCrewIds = this.props.crewIds;

        if(selectedId === 'all') {
            this.setState(() => {
                if(Array.isArray(stateId) && compareArray(stateId, allCrewIds)) {
                    return { ids: [] }; // remove all crew id from checked state
                }
                return {ids: this.props.crewIds}; // fill all crew id to checked state
            });
        } else {
            this.setState(() => {
                if(stateId.includes(selectedId)) {
                    return { ids: stateId.filter((value) => selectedId !== value) } // remove if unchecked
                }
                return { ids: [...stateId, selectedId] } // add if checked
            });
        }
    }

    handleGeneratePayroll = () => {
        const { principal_id, vessel_id, csc } = this.props.globalFilter;
        const { ids, start_period, end_period } = this.state;

        showPopup(
            'custom-confirm',
            'Are you sure you want to process this data ?',
            'Yes, Generate',
            'question',
            'default',
            () => this.props.generatePayroll({ ids, principal_id, vessel_id, csc, start_period, end_period })
        )
    }

    renderFilterCrew = () => {
        const { loading } = this.props;
        return (
            <div className="payroll-filter">
                <PayrollFilter
                    onChange={this.handleFilterChange}
                />
                <div className="row pad-0 container-button-payroll">
                    <div className="col-lg-6 col-md-6 m-0">
                        <Button
                            classCustom={'btn-generate-payroll btn-lg'}
                            color={'bg-green'}
                            type={'button'}
                            disabled={this.checkReady('crew') ? false : true}
                            loading={loading}
                            text={'Generate Payroll'}
                            icon={'cached'}
                            onClick={this.checkReady('crew') ? this.handleGeneratePayroll : undefined}
                        />
                    </div>
                </div>
            </div>
        );
    }

    render () {
        const { ids } = this.state;
        const { dtLoading, crewData, crewIds, } = this.props;
        return (
            <div className="page-container">
                <div className="container-fluid">
                    <div className="block-header">
                        <h2>
                            Generate Payroll
                            <small>Generate Payroll Data</small>
                        </h2>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="card">
                                <div className="body">
                                    { this.renderFilterCrew() }
                                    <CrewDatatable
                                        isLoading={dtLoading}
                                        dataSource={crewData}
                                        reloadDatatable={this.fetchCrewDatatable}
                                        handleSelect={this.handleSelectCrew}
                                        handleSearch={this.handleSearch}
                                        crewIds={crewIds}
                                        selectedCrew={ids}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ global, generate }) => ({
    generate,
    globalFilter: global.payrollFilter,
    loading: generate.loading,
    error: generate.error,
    errorMessage: generate.errorMessage,
    success: generate.success,
    existedIds: generate.existedIds,
    crewIds: generate.crewIds,
    crewData: generate.crewDatatable,
    dtLoading: generate.dtLoading,
});

const mapDispatchToProps = dispatch => ({
    getCrew: (params) => dispatch(getCrew(params)),
    generatePayroll: (params) => dispatch(generatePayroll(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneratePage);


