import React, { Component } from 'react';
import { thousand, dateFormatStd, toNumber } from '../../helpers';
import { Datatable } from '../../components/datatables';

class SummaryDatatable extends Component {

    spreadAdditional = (str) => {
        let obj = JSON.parse(str);
        let newArr = [];

        if(obj && Object.keys(obj).length > 0 && obj.constructor === Object) {
            Object.entries(obj).forEach((value, index) => {
                newArr[index] = {name: value[0], amount: value[1]};
            });
        }

        return newArr.map((value, index) => {
            return (
                <div key={index}>
                    <span className="addt-name">{value.name} {' : '} {value.amount}</span>
                    <br/>
                </div>
            );
        });
    }

    renderTableBody = (dataSource) => {
        const { type, csc } = this.props;
        if(type === 'earning') {
            return dataSource.map((value, index) => {
                const { 
                    id, fullname, rank, basic_wages_calc, fixed_over_time_calc, leave_leavesub_calc,
                    leavepay_from_l21, earnings_others_filtered_calc_converted, over_time_calc,
                    bal_from_last_month, sub_total_earnings, total_earnings,
                } = value;

                return (
                    <tr key={id}>
                        <td className="text-center">{index+1}</td>
                        <td className="text-name">{fullname}</td>
                        <td className="text-center">{rank}</td>
                        <td className="text-right">{thousand(basic_wages_calc)}</td>
                        <td className="text-right">{thousand(fixed_over_time_calc)}</td>
                        { csc === 1 ? <td className="text-right">{thousand(leave_leavesub_calc)}</td> : <td className="text-right">{thousand(over_time_calc)}</td>}
                        <td className="text-right">{thousand(leavepay_from_l21)}</td>
                        <td className="text-full">{this.spreadAdditional(earnings_others_filtered_calc_converted)}</td>
                        <td className="text-right">{thousand(total_earnings)}</td>
                        <td className="text-right">{thousand(bal_from_last_month)}</td>
                        <td className="text-right">{thousand(sub_total_earnings)}</td>
                    </tr>
                );
            });
        } else if(type === 'deduction') {
            return dataSource.map((value, index) => {
                const { 
                    id, fullname, rank, sum_cash_by_master, allotments, money_order_amounts, 
                    deductions_others_converted, total_deductions, bal_carried_to_next_month
                } = value;

                return (
                    <tr key={id}>
                        <td className="text-center">{index+1}</td>
                        <td className="text-name">{fullname}</td>
                        <td className="text-center">{rank}</td>
                        <td className="text-right">{thousand(sum_cash_by_master)}</td>
                        <td className="text-right">{thousand(allotments)}</td>
                        <td className="text-right">{thousand(money_order_amounts)}</td>
                        <td className="text-full">{this.spreadAdditional(deductions_others_converted)}</td>
                        <td className="text-right">{thousand(total_deductions)}</td>
                        <td className="text-right">{thousand(bal_carried_to_next_month)}</td>
                    </tr>
                );
            });
        } else if(type === 'accumulated_leave') {
            return dataSource.map((value, index) => {
                const { 
                    id, fullname, rank, wage_period_from, wage_period_to, total_days, 
                    leave_days_earned, leave_days_spent, leave_days_paid_out, leave_days_end_of_month, // CSC only
                    leavepay_from_last_month, leavepay_this_month, bal_to_next_month, transferred_to_line_4,  // Non CSC only
                    sign_off_date,  reason_for_sign_off, remarks,
                } = value;

                return (
                    <tr key={id}>
                        <td className="text-center">{index+1}</td>
                        <td className="text-name">{fullname}</td>
                        <td className="text-center">{rank}</td>
                        <td className="text-center">{wage_period_from}</td>
                        <td className="text-center">{wage_period_to}</td>
                        <td className="text-center">{total_days}</td>
                        { 
                            csc === 1 ? 
                            <td className="text-right">{toNumber(leave_days_earned, '')}</td> : 
                            <td className="text-right">{thousand(leavepay_from_last_month)}</td> 
                        }
                        { 
                            csc === 1 ? 
                            <td className="text-right">{toNumber(leave_days_spent, '')}</td> : 
                            <td className="text-right">{thousand(leavepay_this_month)}</td>
                        }
                        { 
                            csc === 1 ? 
                            <td className="text-right">{toNumber(leave_days_paid_out, '')}</td> : 
                            <td className="text-right">{thousand(bal_to_next_month)}</td>
                        }
                        { 
                            csc === 1 ? 
                            <td className="text-right">{toNumber(leave_days_end_of_month, '')}</td> : 
                            <td className="text-right">{thousand(transferred_to_line_4)}</td>
                        }
                        <td className="text-date">{sign_off_date ? dateFormatStd(sign_off_date) : ''}</td>
                        <td className="text-center">{reason_for_sign_off}</td>
                        <td className="text-center">{remarks}</td>
                    </tr>
                );
            });
        }
    }

    renderHeader = () => {
        const { type, csc } = this.props;
        let header = [
            {label: 'Family & First Name', field: 'fullname'},
            {label: 'Rank', field: 'rank'},
        ];

        if(type === 'earning') {
            header = [
                ...header,
                {label: 'BW', field: 'basic_wages_calc', tooltip: 'Basic Wages'},
                {label: 'Overtime', field: '', colspan: '2', tooltip: 'Overtime'},
                {label: 'L21', field: 'leavepay_from_l21', tooltip: 'Leavepay from L21'},
                {label: 'Additional Earnings', field: ''},
                {label: 'TE', field: 'total_earnings', tooltip: 'Total Earnings'},
                {label: 'BLM', field: 'bal_from_last_month', tooltip: 'Balance from Last Month'},
                {label: 'SE', field: 'sub_total_earnings', tooltip: 'Subtotal Earnings'},
            ];
        } else if (type === 'deduction') {
            header = [
                ...header,
                {label: 'SCM', field: 'sum_cash_by_master', tooltip: 'Sum Cash by Master'},
                {label: 'AA', field: 'allotments', tooltip: 'Allotment Amount'},
                {label: 'MO', field: 'money_orders_amounts', tooltip: 'Money Orders'},
                {label: 'Additional Deductions', field: ''},
                {label: 'TD', field: 'total_deductions', tooltip: 'Total Deductions'},
                {label: 'BCNM', field: 'bal_carried_to_next_month', tooltip: 'Balance Carried to Next Month'},
            ];
        } else if (type === 'accumulated_leave') {
            header = [
                ...header,
                {label: 'Wage Period', field:'', colspan: '2'},
                {label: 'TD', field: 'total_days', tooltip: 'Total Days'},
            ];

            if(csc === 1) {
                header = [
                    ...header,
                    {label: 'LDE', field: 'leave_days_earned', tooltip: 'Leave Days Earned'},
                    {label: 'LDS', field: 'leave_days_spent', tooltip: 'Leave Days Spent'},
                    {label: 'LDPO', field: 'leave_days_paid_out', tooltip: 'Leave Days Paid Out'},
                    {label: 'LDEM', field: 'leave_days_end_of_month', tooltip: 'Leave Days End of Month'},
                ];
            } else {
                header = [
                    ...header,
                    {label: 'LPLM', field: 'leavepay_from_last_month', tooltip: 'Leavepay from Last Month'},
                    {label: 'LPTM', field: 'leavepay_this_month', tooltip: 'Leavepay This Month'},
                    {label: 'BNM', field: 'bal_to_next_month', tooltip: 'Balance to Next Month'},
                    {label: 'TL4', field: 'transferred_to_line_4', tooltip: 'Transferred to Line 4'},
                ];
            }

            header = [
                ...header,
                {label: 'SOD', field: 'sign_off_date', tooltip: 'Sign Off Date'},
                {label: 'RSO', field: 'reason_for_sign_off', tooltip: 'Reason for Sign Off'},
                {label: 'Remarks', field: 'remarks'},
            ];


        }
        return header;
    }

    render() {
        const {  isLoading, reloadDatatable, type } = this.props;
        let { dataSource } = this.props;

        if(type === 'earning') {
            dataSource = dataSource.earnings;
        } else if (type === 'deduction') {
            dataSource = dataSource.deductions;
        } else if (type === 'accumulated_leave') {
            dataSource = dataSource.accumulatedLeave;
        }

        return (
            <Datatable
                isLoading={isLoading}
                tableHeader={this.renderHeader()}
                tableBody={this.renderTableBody}
                dataSource={dataSource}
                reloadDatatable={reloadDatatable}
                limitable={false}
                creatable={false}
                searchable={false}
                card={false}
                pagination={false}
                headerLength={13}
            />
        );
    }
}

export default SummaryDatatable;


