import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPrincipal } from '../../pages/principal/action';
import { getVessel } from '../../pages/vessel/action';
import { changeFilter } from '../../pages/global/action';
import { strToDate, convDate } from '../../helpers';
import { Select2Filter, DatepickerFilter } from '../ui';

class PayrollFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            principal_id: null,
            vessel_id: null,
            csc: null,
            wage_period: convDate(new Date()),
            cscData: [
                { id: 1, name: "CSC" },
                { id: 0, name: "NON-CSC"},
            ],
        };
        this.initialState = this.state;
    }

    UNSAFE_componentWillMount() {
        const { principalData, globalFilter } = this.props;
        if (Array.isArray(principalData) && principalData.length < 1) this.props.getPrincipal();
        this.setState(globalFilter);
    }

    resetPayrollFilter = () => {
        this.setState(this.initialState);
    }

    renderCSCDropdown = (selectedID) => { //set dynamic CSC dropdown based on active principal csc/non-csc from general settings
        let cscData = [];
        const { principalData } = this.props;
        const principal = principalData.find(data => data.id === selectedID);
        if(principal.csc === 1) { cscData = [...cscData, {id: 1, name: "CSC"}]; }
        if(principal.non_csc === 1) { cscData = [...cscData, {id: 0, name: "NON-CSC"}]; }
        this.setState({ cscData, csc: null, vessel_id: null }); // reset other filter if principal changed
    }

    handleFilterChange = (params) => {
        if(params.principal_id) {
            this.props.getVessel({filter: params.principal_id, search:''});
            this.renderCSCDropdown(params.principal_id);
        }

        this.setState(
            params, // first, set state of filter params                                                                                          
            () => {
                this.props.onChange(this.state);
                this.props.changeFilter(this.state);
            }
        );
    }

    checkReady = () => {
        const { principal_id, vessel_id, csc, wage_period } = this.state;
        if( principal_id && vessel_id && csc !== null && wage_period ) return true;
        return false;
    }

    render () {
        const { principalData, principalLoading, vesselData, vesselLoading } = this.props;
        const { principal_id, vessel_id, csc, wage_period, cscData } = this.state; 

        return (
            <div className="row w-100 container-filter-payroll">
                <div className="col-md-5 mb-0">
                    <Select2Filter
                        className={'principal-filter'}
                        name={'principal_id'}
                        label={'Principal'}
                        placeholder="Select Principal first"
                        keyPair={['id', 'name']}
                        data={principalData}
                        onChange={this.handleFilterChange}
                        loading={principalLoading}
                        setValue={principal_id}
                    />
                </div>
                <div className="col-md-3 mb-0">
                    <Select2Filter
                        className={`vessel-filter ${!principal_id ? ' disabled' : ''}`}
                        disabled={!principal_id ? true : false}
                        name={'vessel_id'}
                        label={'Vessel'}
                        placeholder={"Select Vessel"}
                        keyPair={['id', 'name']}
                        data={vesselData}
                        onChange={this.handleFilterChange}
                        loading={vesselLoading}
                        setValue={vessel_id}
                    />
                </div>
                <div className="col-md-2 mb-0">
                    <Select2Filter
                        className={'csc-filter'}
                        name={'csc'}
                        label={'CSC'}
                        placeholder="Select CSC"
                        data={cscData}
                        keyPair={['id', 'name']}
                        onChange={this.handleFilterChange}
                        setValue={csc}
                    />
                </div>
                <div className="col-md-2">
                    <DatepickerFilter
                        className={'monthpicker-filter'}
                        name={'wage_period'}
                        label={'Wage Period'}
                        placeholder="Select date"
                        icon={'date_range'}
                        onChange={this.handleFilterChange}
                        type={'monthyear'}
                        setValue={wage_period ? strToDate(wage_period) : null}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ global, vessel, principal }) => ({
    global,
    globalFilter: global.payrollFilter,
    principalData: principal.datatable,
    principalLoading: principal.dtLoading,
    vesselData: vessel.datatable,
    vesselLoading: vessel.dtLoading,
});

const mapDispatchToProps = dispatch => ({
    getPrincipal: (params) => dispatch(getPrincipal(params)),
    getVessel: (params) => dispatch(getVessel(params)),
    changeFilter: (params) => dispatch(changeFilter(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayrollFilter);


