import { WAGE_ACCOUNT } from "../../services";

export const PAYROLL_STATE = {
  REQUEST: "PAYROLL_FETCH_REQUEST",
  REQUEST_FAILED: "PAYROLL_REQUEST_FAILED",
  FETCH_PAYROLL_SUCCESS: "PAYROLL_FETCH_SUCCESS",
  UPDATE_PAYROLL_SUCCESS: "PAYROLL_UPDATE_SUCCESS",
  DELETE_PAYROLL_SUCCESS: "PAYROLL_DELETE_SUCCESS",
  GENERATE_PREVIEW_SUCCESS: "PAYROLL_GENERATE_PREVIEW_SUCCESS",
  SEND_PAYSLIP_ALL_SUCCESS: "PAYROLL_SEND_PAYSLIP_ALL_SUCCESS",
  CREATE_PROMOTION_SUCCESS: "PAYROLL_CREATE_PROMOTION_SUCCESS",
  FORM_CHANGED: "PAYROLL_FORM_CHANGED",
  FORM_TAB_RESET: "PAYROLL_FORM_TAB_RESET",
  CLOSE_MODAL_RESET: "PAYROLL_FORM_CLOSE_MODAL_RESET",
  SUBMIT_TAB: "PAYROLL_SUBMIT_TAB",
  RESET_SUBMIT_TAB: "PAYROLL_RESET_SUBMIT_TAB",
};

const requesting = (requestType, response) => ({
  type: PAYROLL_STATE.REQUEST,
  requestType,
  payload: response,
});

const requestingFailed = (requestType, error) => ({
  type: PAYROLL_STATE.REQUEST_FAILED,
  requestType,
  payload: error,
});

const fetchingPayrollSuccess = (requestType, response) => ({
  type: PAYROLL_STATE.FETCH_PAYROLL_SUCCESS,
  requestType,
  payload: response,
});

const updatingPayrollSuccess = (requestType, response) => ({
  type: PAYROLL_STATE.UPDATE_PAYROLL_SUCCESS,
  requestType,
  payload: response,
});

const deletingPayrollSuccess = (response) => ({
  type: PAYROLL_STATE.DELETE_PAYROLL_SUCCESS,
  payload: response,
});

const generatingPreviewSuccess = (requestType, response) => ({
  type: PAYROLL_STATE.GENERATE_PREVIEW_SUCCESS,
  requestType,
  payload: response,
});

const sendingPreviewAllSuccess = (requestType, response) => ({
  type: PAYROLL_STATE.SEND_PAYSLIP_ALL_SUCCESS,
  requestType,
  payload: response,
});

const creatingPromotionSuccess = (requestType, response) => ({
  type: PAYROLL_STATE.CREATE_PROMOTION_SUCCESS,
  requestType,
  payload: response,
});

const changeFormStatus = (changed, editing) => ({
  type: PAYROLL_STATE.FORM_CHANGED,
  changed,
  editing,
});

const submittingTab = (status, closeModal, currentTab, nextTab) => ({
  type: PAYROLL_STATE.SUBMIT_TAB,
  submitStatus: status,
  closeModal,
  currentTab,
  nextTab,
});

const resettingSubmit = (closeModal) => ({
  type: PAYROLL_STATE.RESET_SUBMIT_TAB,
  closeModal,
});

export const formChanged = (changed) => {
  return (dispatch) => {
    dispatch(changeFormStatus(changed));
  };
};

export const formEditing = (editing) => {
  return (dispatch) => {
    dispatch(changeFormStatus(null, editing));
  };
};

export const submitTab = (status, closeModal, currentTab, nextTab) => {
  return (dispatch) => {
    dispatch(submittingTab(status, closeModal, currentTab, nextTab));
  };
};

export const resetSubmit = (closeModal) => {
  return (dispatch) => {
    dispatch(resettingSubmit(closeModal));
  };
};

export const getPayroll = (params) => {
  return (dispatch) => {
    dispatch(requesting("dt-payroll"));
    WAGE_ACCOUNT.getPayroll(params).then(
      (response) => {
        dispatch(fetchingPayrollSuccess("dt-payroll", response.data.body));
      },
      (error) => {
        const response = error.response.data;
        dispatch(requestingFailed("dt-payroll", response.body.message));
      }
    );
  };
};

export const updateEarningPayroll = (params) => {
  return async (dispatch) => {
    dispatch(requesting("update-earning"));
    await WAGE_ACCOUNT.putEarningPayroll(params).then(
      (response) => {
        dispatch(updatingPayrollSuccess("update-earning", response.data.body));
      },
      (error) => {
        const response = error.response.data;
        dispatch(requestingFailed("update-earning", response.body.message));
      }
    );
  };
};

export const updateRemarksLeaveDays = (params) => {
  return async (dispatch) => {
    dispatch(requesting("update-remarks-leavedays"));
    await WAGE_ACCOUNT.putRemarksLeaveDays(params).then(
      (response) => {
        dispatch(
          updatingPayrollSuccess("update-remarks-leavedays", response.data.body)
        );
      },
      (error) => {
        const response = error.response.data;
        dispatch(
          requestingFailed("update-remarks-leavedays", response.body.message)
        );
      }
    );
  };
};

export const removePayroll = (params) => {
  return async (dispatch) => {
    dispatch(requesting("delete"));
    await WAGE_ACCOUNT.removePayroll(params).then(
      (response) => {
        dispatch(deletingPayrollSuccess(response.data.body));
      },
      (error) => {
        const response = error.response.data;
        dispatch(requestingFailed("delete", response.body.message));
      }
    );
  };
};

export const postPreview = (params) => {
  return async (dispatch) => {
    dispatch(requesting("generate-preview"));
    await WAGE_ACCOUNT.postPreview(params).then(
      (response) => {
        dispatch(
          generatingPreviewSuccess("generate-preview", response.data.body)
        );
      },
      (error) => {
        const response = error.response.data;
        dispatch(requestingFailed("generate-preview", response.body.message));
      }
    );
  };
};

export const postDeleteAll = (params) => {
  return async (dispatch) => {
    dispatch(requesting("delete-all-payroll"));
    await WAGE_ACCOUNT.postDeleteAll(params).then(
      (response) => {
        dispatch(deletingPayrollSuccess(response.data.body));
      },
      (error) => {
        const response = error.response.data;
        dispatch(requestingFailed("delete", response.body.message));
      }
    );
  };
};

export const sendAllPayslip = (params) => {
  return async (dispatch) => {
    dispatch(requesting("send-preview-all"));
    await WAGE_ACCOUNT.sendAllPayslip(params).then(
      (response) => {
        dispatch(
          sendingPreviewAllSuccess("send-preview-all", response.data.body)
        );
      },
      (error) => {
        const response = error.response.data;
        dispatch(requestingFailed("send-preview-all", response.body.message));
      }
    );
  };
};

export const postPromotion = (params) => {
  return async (dispatch) => {
    dispatch(requesting("promote-demote"));
    await WAGE_ACCOUNT.postPromotion(params).then(
      (response) => {
        dispatch(
          creatingPromotionSuccess("promote-demote", response.data.body)
        );
      },
      (error) => {
        const response = error.response.data;
        dispatch(requestingFailed("promote-demote", response.body.message));
      }
    );
  };
};
