import { USER } from '../../services';
import { transformObject } from '../../helpers';

export const USER_STATE = {
    REQUEST: 'USER_REQUEST',
    REQUEST_FAILED: 'USER_REQUEST_FAILED',
    FETCH_SUCCESS: 'USER_FETCH_SUCCESS',
    CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
    DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    ACTIVATE_SUCCESS: 'USER_ACTIVATION_SUCCESS',
};

const requesting = (requestType) => ({
    type: USER_STATE.REQUEST,
    requestType,
});

const requestFailed = (requestType, response) => ({
    type: USER_STATE.REQUEST_FAILED,
    requestType,
    payload: response
});

const fetchingSuccess = (response) => ({
    type: USER_STATE.FETCH_SUCCESS,
    payload: response
});

const creatingSuccess= (response) => ({
    type: USER_STATE.CREATE_SUCCESS,
    payload: response
});

const deletingSuccess= (response) => ({
    type: USER_STATE.DELETE_SUCCESS,
    payload: response
});

const activatingSuccess = (response) => ({
    type: USER_STATE.ACTIVATE_SUCCESS,
    payload: response
});

export const getUser = (params) => {
    return dispatch => {
        dispatch(requesting('dt'));
        USER.getUser(params)
            .then(
                response => {
                    dispatch(fetchingSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestFailed('dt', response.body.message));
                }
            );
    };
};

export const postUser = (params, setErrors) => {
    return async dispatch => {
        dispatch(requesting('post'));
        await USER.postUser(params)
            .then(
                response => {
                    dispatch(creatingSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    const code = response.meta.code;
                    if(code === 422) {
                        const errMsg = transformObject(response.body.message);
                        setErrors(errMsg);
                        dispatch(requestFailed('validate', errMsg));
                    } else {
                        dispatch(requestFailed('post', response.body.message));
                    }
                }
            );
    };
};

export const activateUser = (params) => {
    return async dispatch => {
        dispatch(requesting('activate'));
        await USER.activateUser(params)
            .then(
                response => {
                    dispatch(activatingSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestFailed('activate', response.body.message));
                }
            );
    };
};

export const removeUser = (params) => {
    return async dispatch => {
        dispatch(requesting('delete'));
        await USER.deleteUser(params)
            .then(
                response => {
                    dispatch(deletingSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestFailed('delete', response.body.message));
                }
            );
    };
};