import React, { Component } from 'react';
import { Datatable } from '../../components/datatables';
import { Button } from '../../components/ui';
import { compareArray } from '../../helpers';

class NotificationDatatable extends Component {

    checkReady = () => {
        const { selectedNotif } = this.props;
        if(selectedNotif.length > 0) return true;
        return false;
    }

    renderTableBody = (dataSource) => {
        const { selectedNotif, handleSelect } = this.props;
        if(Array.isArray(dataSource.data) && dataSource.data.length) {
            return dataSource.data.map((value, index) => {
                const { id, data  } = value;
                return (
                    <tr key={index}>
                        <td className="index-td">
                            <form>
                                <input type="checkbox" id={`notif_cb_${id}`} name={`notif_cb_${id}`}
                                    className="filled-in chk-col-green"
                                    checked={ selectedNotif.includes(id) ? true : false }
                                    onChange={() => handleSelect(id)}
                                />
                                <label htmlFor={`notif_cb_${id}`}></label>
                            </form>
                        </td>
                        <td className="index-td">{index+1}</td>
                        <td className="text-left">{data.email}</td>
                        <td className="text-left">{data.subject}</td>
                        <td className={`text-center notif-status-text ucwords ${data.type === 'success' ? 'green' : 'red'}`}>
                            {data.type}
                        </td>
                        <td className={`text-left ${data.type === 'success' ? '' : 'red'}`}>{data.info}</td>
                    </tr>
                );
            });
        }
    }

    renderHeaderCheckbox = () => {
        const { notifIds, selectedNotif, handleSelect } = this.props;
        return (
            <th className="index-th index-th-check">
                <form>
                    <input type="checkbox" name="notification_all" id={`notification_all`}
                        className="filled-in chk-col-green"
                        checked={ notifIds.length && compareArray(selectedNotif, notifIds) ? true : false }
                        onChange={() => handleSelect('all')}
                    />
                    <label htmlFor={`notification_all`}></label>
                </form>
            </th>
        );
    }

    render() {
        const {  dtLoading, loading, dataSource, reloadDatatable, toggleModal, handleRead } = this.props;
        const datatableHeader = [
            {label: 'E-Mail', field: 'data.email'},
            {label: 'Subject', field: 'data.subject'},
            {label: 'Status', field: 'data.type'},
            {label: 'Description', field: 'data.info'},
        ];

        return (
            <Datatable
                isLoading={dtLoading}
                checkBox={this.renderHeaderCheckbox}
                tableHeader={datatableHeader}
                tableBody={this.renderTableBody}
                title={{main: 'Notification', sub: 'Email status notification'}}
                dataSource={dataSource}
                toggleModal={toggleModal}
                reloadDatatable={reloadDatatable}
                pagination={true}
                creatable={false}
                searchable={false}
                customHeader={() => {
                    return(
                        <div className="header">
                            <div className="d-flex">
                                <Button
                                    classCustom={'btn-lg'}
                                    color={'bg-green'}
                                    text={'Mark as Read'}
                                    icon={'done'}
                                    disabled={!loading && this.checkReady() ? false : true}
                                    onClick={this.checkReady() ? handleRead : undefined}
                                />
                            </div>
                        </div>
                    );
                }}
            />
        );
    }
}

export default NotificationDatatable;