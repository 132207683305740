import { RANK } from '../../services';

export const RANK_STATE = {
    REQUEST: 'RANK_REQUEST',
    REQUEST_FAILED: 'RANK_REQUEST_FAILED',
    FETCH_SUCCESS: 'RANK_FETCH_SUCCESS',
};

const requesting = (requestType, response) => ({
    type: RANK_STATE.REQUEST,
    requestType,
    payload: response
});

const requestingFailed = (requestType, error) => ({
    type: RANK_STATE.REQUEST_FAILED,
    requestType,
    payload: error
});

const fetchingSuccess = (requestType, response) => ({
    type: RANK_STATE.FETCH_SUCCESS,
    requestType,
    payload: response
});

export const getRank = (params) => {
    return dispatch => {
        dispatch(requesting('dt'));
        RANK.get(params)
            .then(
                response => {
                    dispatch(fetchingSuccess('dt', response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestingFailed('dt', response.body));
                }
            );
    };
};

