import React from 'react';
import { Link } from 'react-router-dom';

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
    }

    handleBackToHome () {
        this.props.history.push('/payroll-transaction')
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="four-zero-four">
                    <div className="four-zero-four-container">
                        <div className="error-code">404</div>
                        <div className="error-message">This page doesn't exist</div>
                        <div className="button-place">
                            <Link to={"/payroll-transaction"} className="btn btn-default btn-lg waves-effect">GO TO HOMEPAGE</Link>
                        </div>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
