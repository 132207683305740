import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  showPopup,
  revertToDBFormat,
  revertFixedToDBFormat,
  convEmptyStringToCustom,
  calcExcess,
  prorate,
} from "../../../helpers";
import {
  updateEarningPayroll,
  formChanged,
  formEditing,
  submitTab,
  resetSubmit,
} from "../action";
import { Button, Select as SelectUI } from "../../../components/ui";
import { Select } from "../../../components/forms";
import { MInput } from "../../../components/material/form";
import DynamicFields from "../../../components/dynamic-fields";
import StaticFields from "../../../components/static-fields";

class EarningSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      additionalData: [],
      fixedData: [],
      converted_currency: this.props.dataSource.converted_currency
        ? this.props.dataSource.converted_currency
        : "USD", // default converter currency
      over_time: null,
      over_time_excess: null,
      over_time_currency: null,
      over_time_calc: "0.00",
      bal_from_last_month: null,
    };
    this.initialState = this.state;
    this.formik = React.createRef();
  }

  UNSAFE_componentWillMount() {
    if (this.props.dataSource) {
      const { dataSource } = this.props;
      this.setState({
        over_time: dataSource.over_time,
        over_time_excess: dataSource.over_time_excess,
        over_time_currency: dataSource.over_time_currency,
        over_time_calc: dataSource.over_time_calc || "0.00",
        bal_from_last_month: dataSource.bal_from_last_month,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { trigger, submitStatus, nextTab, currentTab } = nextProps;
    if (
      trigger === "PAYROLL_SUBMIT_TAB" &&
      submitStatus &&
      currentTab === "earnings" &&
      nextTab !== "earnings"
    ) {
      this.formik.current.handleSubmit();
      this.props.resetSubmit(false);
    }
  }

  handleChange = (data) => {
    if (data.type === "dynamic") {
      this.setState({ additionalData: data.params });
    } else if (data.type === "static") {
      this.setState({ fixedData: data.params });
    }

    const status = data.changed !== undefined ? data.changed : false;
    this.props.formChanged(status);
  };

  handleBlur = (event) => {
    const { name, value } = event.target;
    const calculateOvertime = () => {
      const { over_time, over_time_excess } = this.state;
      const rate = over_time ? over_time : 0;
      this.setState({ over_time_calc: calcExcess(over_time_excess, rate) });
    };

    this.setState({ [name]: value }, () => {
      if (name === "over_time" || name === "over_time_excess")
        calculateOvertime();
    });

    if (!this.props.dataChanged) this.props.formChanged(true);
  };

  handleClickSubmit = (closeModal, nextTab = null) => {
    this.props.submitTab(false, closeModal, "earnings", nextTab);
    this.formik.current.handleSubmit();
  };

  handleSubmit = (params) => {
    this.props.formChanged(true);
    const { dataSource } = this.props;
    const {
      additionalData,
      fixedData,
      converted_currency,
      over_time_calc,
    } = this.state;
    console.log(additionalData);
    const resultAdditional = revertToDBFormat(additionalData);
    console.log(resultAdditional);
    const params1 = revertFixedToDBFormat(fixedData);
    const leave = parseFloat(params1.leave);
    const leavesub = params1.leavesub ? parseFloat(params1.leavesub) : 0;
    const leave_leavesub = leave + leavesub;
    const totalWageDays =
      parseFloat(
        dataSource.previous_leave_days ? dataSource.previous_leave_days : 0
      ) +
      parseFloat(dataSource.total_days ? dataSource.total_days : 0) +
      parseFloat(
        dataSource.leave_days_additional ? dataSource.leave_days_additional : 0
      );
    let params2 = {
      id: dataSource.id,
      converted_currency,
      leave_leavesub,
      leave_leavesub_calc: prorate(leave_leavesub, totalWageDays, false, true),
      basic_wages_calc: prorate(
        dataSource.basic_wages,
        totalWageDays,
        false,
        true
      ),
      fixed_over_time_calc: prorate(
        dataSource.fixed_over_time,
        totalWageDays,
        false,
        true
      ),
      earnings_others_filtered: resultAdditional.values,
      earnings_others_filtered_calc: resultAdditional.prorates,
      earnings_others_filtered_currency: resultAdditional.currencies,
      bal_from_last_month: params.bal_from_last_month
        ? params.bal_from_last_month
        : null,
    };

    if (this.props.csc === 0) {
      //only for NON-CSC
      const params3 = {
        over_time: params.over_time,
        over_time_currency: params.over_time_currency,
        over_time_excess: params.over_time_excess,
        over_time_calc,
      };
      params2 = Object.assign(params2, params3);
    }

    let submitParams = Object.assign(params1, params2);
    let convertedParams = convEmptyStringToCustom(submitParams, null);
    this.props.updateEarningPayroll(convertedParams);
  };

  handleConverterChange = (param) => {
    this.setState({ converted_currency: param.value });
    this.props.formChanged(true);
  };

  handleFormEditing = (editing) => {
    this.props.formEditing(editing);
  };

  handleCancelAndClose = () => {
    this.props.formChanged(true);
    if (this.props.dataChanged) {
      showPopup(
        "custom-confirm",
        "You haven't saved the data. Cancel the proceed anyway? Unsaved data may occurred",
        "Discard changes",
        "warning",
        "reversed",
        () => this.props.handleClose()
      );
    } else {
      this.props.handleClose();
    }
  };

  renderOvertimeForm = () => {
    return (
      <div>
        <h5 className="title-separator">Overtime</h5>
        <div className="row">
          <div className="col-lg-3 col-md-3 mb-15">
            <MInput
              label="Overtime"
              name="over_time"
              type="currency"
              placeholder="0.00"
              fullWidth
              noMargin
              onBlur={this.handleBlur}
            />
          </div>
          <div className="col-lg-3 col-md-3 mb-15">
            <MInput
              label="O/T Excess"
              name="over_time_excess"
              type="currency"
              placeholder="0.00"
              fullWidth
              noMargin
              onBlur={this.handleBlur}
            />
          </div>
          <div className="col-lg-2 col-md-2 mb-15">
            <Select
              label="Currency"
              className="form-control"
              name="over_time_currency"
              placeholder="None"
              data={this.props.currencyData}
              keyPair={["id", "name"]}
              formGroup={true}
              noMargin={true}
              onlyValue={true}
              onBlur={this.handleBlur}
            />
          </div>
          <div className="col-lg-4 col-md-4 mb-15">
            <MInput
              label="Overtime Total"
              name="over_time_calc"
              type="text"
              fullWidth
              noMargin
              disabled
              value={this.state.over_time_calc}
            />
          </div>
        </div>
      </div>
    );
  };

  renderLastMonthForm = () => {
    return (
      <div>
        <h5 className="title-separator">Last Month Earning</h5>
        <div className="row p-0">
          <div className="col-lg-6 col-md-6 m-0">
            <MInput
              label="Balance from Last Month"
              name="bal_from_last_month"
              placeholder="0.00"
              type="currency"
              fullWidth
              noMargin
              allowMinus
              onBlur={this.handleBlur}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      dataSource,
      currencyData,
      editing,
      loading,
      csc,
      previousDataExisted,
    } = this.props;
    const {
      converted_currency,
      over_time,
      over_time_excess,
      over_time_calc,
      over_time_currency,
      bal_from_last_month,
    } = this.state;
    const dynamicData = {
      params: dataSource.earnings_others_filtered,
      params_calc: dataSource.earnings_others_filtered_calc,
      currencies: dataSource.earnings_others_filtered_currency,
      wageDays: dataSource.total_days,
    };
    const staticData = () => {
      let titles = [
        "Basic Wages",
        "Leave",
        "Leave Sub",
        "Overtime",
        "Overtime Excess",
      ];
      let params = {
        basic_wages: dataSource.basic_wages,
        leave: dataSource.leave,
        leavesub: dataSource.leavesub,
      };
      let currencies = [
        dataSource.basic_wages_currency,
        dataSource.leave_currency,
        dataSource.leavesub_currency,
      ];

      // UNCOMMAND this if you want to hide fix overtime on non csc
      // if (csc) {
      //add only for CSC params
      titles = [...titles, "Fixed O/T"];
      params = { ...params, fixed_over_time: dataSource.fixed_over_time };
      currencies = [...currencies, dataSource.fixed_over_time_currency];
      // }
      const totalWageDays =
        parseFloat(
          dataSource.previous_leave_days ? dataSource.previous_leave_days : 0
        ) +
        parseFloat(dataSource.total_days ? dataSource.total_days : 0) +
        parseFloat(
          dataSource.leave_days_additional
            ? dataSource.leave_days_additional
            : 0
        );
      return {
        titles: JSON.stringify(titles),
        params: JSON.stringify(params),
        currencies: JSON.stringify(currencies),
        wageDays: totalWageDays,
      };
    };

    const validation = Yup.object({
      over_time: Yup.string().nullable(),
      over_time_excess: Yup.string().nullable(),
      over_time_currency: Yup.string().nullable(),
      bal_from_last_month: Yup.string().nullable(),
    });

    const initialValues = {
      over_time,
      over_time_excess,
      over_time_currency,
      over_time_calc,
      bal_from_last_month,
    };

    return (
      <div>
        <Formik
          innerRef={this.formik}
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {(formikProps) => (
            <Form>
              <div className="tab-body tab-edit-payroll">
                <div className="row">
                  <div className="col-lg-12 m-0">{this.props.profile()}</div>
                </div>
                <div className="row">
                  <div className="col-lg-6 m-0">
                    <h4 className="title-separator">Fixed Earnings</h4>
                    <StaticFields
                      handleSubmit={this.handleChange}
                      dataSource={staticData()}
                      currencyData={currencyData}
                      handleEditing={this.handleFormEditing}
                      prorate={true}
                    />
                    {csc === 0 ? this.renderOvertimeForm() : ""}
                    {!previousDataExisted ? this.renderLastMonthForm() : ""}
                  </div>
                  <div className="col-lg-6 m-0">
                    <h4 className="title-separator">Additional Earnings</h4>
                    <DynamicFields
                      handleSubmit={this.handleChange}
                      handleEditing={this.handleFormEditing}
                      dataSource={dynamicData}
                      currencyData={currencyData}
                      prorate={true}
                      editable={true}
                    />
                  </div>
                </div>
              </div>
              <div className="tab-footer currency p-0">
                {/* OPTIONAL : bottom profile  */}
                {/* <div className="col-lg-6 col-md-6 mb-15">
                                    { this.props.profile() }
                                </div> */}
                <div className="col-lg-12 col-md-12 mb-15">
                  <div className="currency-converter">
                    <span>Convert Currency Report to </span>
                    <SelectUI
                      label="converted_currency"
                      className=""
                      name="converted_currency"
                      placeholder="Currency"
                      data={currencyData}
                      keyPair={["id", "name"]}
                      setValue={converted_currency}
                      onChange={this.handleConverterChange}
                      onlyValue={true}
                    />
                  </div>
                  <div className="button-footer">
                    <Button
                      classCustom={"btn-lg mr-7"}
                      color={"bg-green"}
                      type={"button"}
                      disabled={editing || loading ? true : false}
                      loading={loading}
                      text="Update &amp; Next"
                      onClick={() =>
                        this.handleClickSubmit(false, "deductions")
                      }
                    />
                    {/* <Button
                      classCustom={"btn-lg mr-7"}
                      color={"bg-blue"}
                      type={"button"}
                      disabled={editing || loading ? true : false}
                      loading={loading}
                      text="Update &amp; Finish"
                      onClick={() => this.handleClickSubmit(true, null)}
                    /> */}
                    {/* <button
                      type="button"
                      onClick={this.handleCancelAndClose}
                      className="btn btn-link waves-effect"
                      data-dismiss="modal"
                    >
                      Close
                    </button> */}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = ({ payroll, currency }) => ({
  payroll,
  loading: payroll.loading,
  dataChanged: payroll.formChanged,
  editing: payroll.formEditing,
  currencyData: currency.datatable,
  submitStatus: payroll.submitStatus,
  currentTab: payroll.currentTab,
  nextTab: payroll.nextTab,
  trigger: payroll.trigger,
});

const mapDispatchToProps = (dispatch) => ({
  updateEarningPayroll: (params) => dispatch(updateEarningPayroll(params)),
  formChanged: (params) => dispatch(formChanged(params)),
  formEditing: (params) => dispatch(formEditing(params)),
  submitTab: (submitStatus, closeModal, currentTab, nextTab) =>
    dispatch(submitTab(submitStatus, closeModal, currentTab, nextTab)),
  resetSubmit: (closeModal) => dispatch(resetSubmit(closeModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EarningSection);
