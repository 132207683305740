import { EXCHANGE_RATE } from '../../services';
import { transformObject } from '../../helpers';

export const EXCHANGE_RATE_STATE = {
    REQUEST: 'EXCHANGE_RATE_REQUEST',
    REQUEST_FAILED: 'EXCHANGE_RATE_REQUEST_FAILED',
    FETCH_SUCCESS: 'EXCHANGE_RATE_FETCH_SUCCESS',
    CREATE_SUCCESS: 'EXCHANGE_RATE_CREATE_SUCCESS',
    UPDATE_SUCCESS: 'EXCHANGE_RATE_UPDATE_SUCCESS',
    DELETE_SUCCESS: 'EXCHANGE_RATE_DELETE_SUCCESS',
    HISTORY_FETCH_SUCCESS: 'EXCHANGE_RATE_HISTORY_FETCH_SUCCESS',
};

const requesting = (requestType, response) => ({
    type: EXCHANGE_RATE_STATE.REQUEST,
    requestType,
    payload: response
});

const requestFailed = (requestType, error) => ({
    type: EXCHANGE_RATE_STATE.REQUEST_FAILED,
    requestType,
    payload: error
});

const fetchingSuccess = (requestType, response) => ({
    type: EXCHANGE_RATE_STATE.FETCH_SUCCESS,
    requestType,
    payload: response
});

const creatingSuccess = (response) => ({
    type: EXCHANGE_RATE_STATE.CREATE_SUCCESS,
    payload: response
});

const updatingSuccess = (response) => ({
    type: EXCHANGE_RATE_STATE.UPDATE_SUCCESS,
    payload: response
});

const deletingSuccess = (response) => ({
    type: EXCHANGE_RATE_STATE.DELETE_SUCCESS,
    payload: response
});

const fetchingHistorySuccess = (requestType, response) => ({
    type: EXCHANGE_RATE_STATE.HISTORY_FETCH_SUCCESS,
    requestType,
    payload: response
});

export const getExchangeRate = (params) => {
    return dispatch => {
        dispatch(requesting('dt'));
        EXCHANGE_RATE.get(params)
            .then(
                response => {
                    dispatch(fetchingSuccess('dt', response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestFailed('dt', response.body.message));
                }
            );
    };
};

export const postExchangeRate = (params, setErrors) => {
    return async dispatch => {
        dispatch(requesting('post'));
        await EXCHANGE_RATE.post(params)
            .then(
                response => {
                    dispatch(creatingSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    const code = response.meta.code;
                    if(code === 422) {
                        const errMsg = transformObject(response.body.message);
                        setErrors(errMsg);
                        dispatch(requestFailed('validate', errMsg));
                    } else {
                        dispatch(requestFailed('post', response.body.message));
                    }
                }
            );
    };
};

export const updateExchangeRate = (params, setErrors) => {
    return async dispatch => {
        dispatch(requesting('update'));
        await EXCHANGE_RATE.put(params)
            .then(
                response => {
                    dispatch(updatingSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    const code = response.meta.code;
                    if(code === 422) {
                        const errMsg = transformObject(response.body.message);
                        setErrors(errMsg);
                        dispatch(requestFailed('validate', errMsg));
                    } else {
                        dispatch(requestFailed('update', response.body.message));
                    }
                }
            );
    };
};

export const removeExchangeRate = (params) => {
    return async dispatch => {
        dispatch(requesting('delete'));
        await EXCHANGE_RATE.remove(params)
            .then(
                response => {
                    dispatch(deletingSuccess(response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestFailed('delete', response.body.message));
                }
            );
    };
};

export const getHistory = (params) => {
    return dispatch => {
        dispatch(requesting('history'));
        EXCHANGE_RATE.getHistory(params)
            .then(
                response => {
                    dispatch(fetchingHistorySuccess('history', response.data.body));
                },
                error => {
                    const response = error.response.data;
                    dispatch(requestFailed('history', response.body.message));
                }
            );
    };
};