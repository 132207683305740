import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { thousand, dateFormatStd, convDate } from '../../helpers';
import { Datatable } from '../../components/datatables';

class ExchangeRateDatatable extends Component {

    replaceIdToText = (id) => {
        const currency = this.props.currencyData.find(curr => curr.id === id)
        return currency.name;
    }

    renderTableBody = (dataSource) => {
        if(Array.isArray(dataSource.data) && dataSource.data.length) {
            return dataSource.data.map((value, index) => {
                const { id, from_id, to_id, amounts, month  } = value;
                const { amount, rate_id, updated_at } = amounts[0];
                const editParams = { id, month, from: from_id, to: to_id, amount, rate_id };

                return (
                    <tr key={index}>
                        <td className="index-td">{index+1}</td>
                        <td className="text-center">{this.replaceIdToText(from_id)}</td>
                        <td className="text-center">{this.replaceIdToText(to_id)}</td>
                        <td className="text-right">{thousand(amount)}</td>
                        <td className="text-center">{dateFormatStd(updated_at, 'DMYSTRING')}</td>
                        <td className="text-center">
                            <ReactTooltip id={`action-${id}`}/>
                            <button
                                className="btn btn-circle btn-sm bg-green waves-effect waves-circle mr-7"
                                data-tip="Detail" data-for={`action-${id}`}
                                onClick={() => this.props.toggleModal('detail', editParams)}>
                                <i className="material-icons">search</i>
                            </button>
                            <button
                                className="btn btn-circle btn-sm bg-blue waves-effect waves-circle mr-7"
                                data-tip="Edit" data-for={`action-${id}`}
                                onClick={() => this.props.toggleModal('update', editParams)}>
                                <i className="material-icons">edit</i>
                            </button>
                            <button
                                className="btn btn-circle btn-sm bg-red waves-effect waves-circle mr-7"
                                data-tip="Delete" data-for={`action-${id}`}
                                onClick={() => this.props.toggleConfirmDelete(id)}>
                                <i className="material-icons">delete_forever</i>
                            </button>
                        </td>
                    </tr>
                );
            });
        }
    }

    render() {
        const {  isLoading, dataSource, currencyData, reloadDatatable, toggleModal } = this.props;
        const datatableHeader = [
            {label: 'From', field: 'from_id'},
            {label: 'To', field: 'to_id'},
            {label: 'Rate Amount', field: 'rate_amount'},
            {label: 'Last Updated', field: 'last_updated'},
            {label: 'Actions', field: ''},
        ];

        const parseNested = (dataSource) => {
            if(Array.isArray(dataSource.data) && dataSource.data.length) {
                dataSource.data = dataSource.data.map((value) => {
                    const rate_amount = value.amounts[0].amount;
                    const last_updated = value.amounts[0].updated_at;
                    return {...value, rate_amount, last_updated };
                });
            }
            return dataSource;
        }

        return (
            <Datatable
                isLoading={isLoading}
                tableHeader={datatableHeader}
                tableBody={this.renderTableBody}
                title={{main: 'Exchange Rate', sub: 'Currencies Exchange Rate Management'}}
                dataSource={parseNested(dataSource)}
                currencyData={currencyData}
                toggleModal={toggleModal}
                reloadDatatable={reloadDatatable}
                pagination={true}
            />
        );
    }
}

export default ExchangeRateDatatable;