import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip';
import Waves from 'node-waves';
import Navbar from '../pages/navigator/navbar';
import { AlertPswd } from '../components/alert';

class Private extends Component {

    componentDidMount() {
        this.handleButtonWavesEffect();
    }

    handleButtonWavesEffect = () => {
        Waves.init(); // init button node-waves effect from package
        Waves.attach('.waves-effect');
    }

    render() {
        return (
            <div className="theme-green">
                <div className="overlay"></div>
                <Navbar/>
                <section className="content" id="app-content">
                    <ReactTooltip />
                    <AlertPswd countdown={this.props.passwordExpiredDate} />
                    {this.props.children}
                </section>
            </div>
        );
    }
}

const mapStateToProps = ({auth}) => ({
    passwordExpiredDate: auth.passwordExpiredDate,
    token : auth.token
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Private);