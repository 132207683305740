import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Modal from '../../components/modal';
import { Button } from '../../components/ui';
import { handleEnter } from '../../helpers';
import { MInput } from '../../components/material/form';

class UserModal extends Component {

    constructor(props) {
        super(props);
        this.formik = React.createRef();
    }

    handleCancelAndClose = () => {
        let formikProps = this.formik.current;
        this.props.handleClose();
        formikProps.dirty = false;
        formikProps.resetForm();
    }

    handleSubmit = (params, { ...formikProps }) => {
        this.props.handleSubmit(params, formikProps);
    }

    clickSubmit = () => {
        const formikProps = this.formik.current;
        formikProps.handleSubmit();
    }

    render () {
        const { isOpen, formType, formData, modalLoading, errorMessage } = this.props;
        const editPassword =
            formType === 'update' ?
            (
                Yup.string()
                    .min(6, "Password must contain at least 6 characters")
                    .max(30, "Password must contain maximum 30 characters")
            )
            : null;
    
        const validation = Yup.object({
            username: Yup.string()
                .trim('Username cannot have trailing spaces')
                .strict()
                .required('Username is required')
                .min(6, 'Username must contain at least 6 characters'),
            email: Yup.string()
                .required('Email is required')
                .email("Email is an format '@', please input is valid email format"),
            password: Yup.string().concat(editPassword)
        });

        return (
            <Modal
                isOpen={isOpen}
                background={'bg-green'}
                title={'Create User'}
                onClose={this.handleCancelAndClose}
                errorMessage={errorMessage}
                content={(
                    <Formik
                        innerRef={this.formik}
                        initialValues={formData}
                        validationSchema={validation}
                        onSubmit={this.handleSubmit}
                        enableReinitialize
                    >
                        {() => (
                            <Form 
                                autoComplete="off"
                                onKeyDown={(e) => handleEnter(e, this.clickSubmit)}
                            >
                                <MInput
                                    label="Username"
                                    name="username"
                                    placeholder="Input username"
                                    required
                                    fullWidth
                                    autoFocus
                                />
                                <MInput
                                    label="E-Mail"
                                    name="email"
                                    placeholder="Input email"
                                    required
                                    fullWidth
                                />
                            </Form>
                            )
                        }   
                    </Formik>
                )}
                footer={(
                    <div>
                        <Button
                            classCustom={'btn-lg'}
                            color={'bg-blue'}
                            type={'submit'}
                            disabled={modalLoading}
                            loading={modalLoading}
                            text={'Save'}
                            icon={'check'}
                            onClick={this.clickSubmit}
                        />
                        <Button
                            classCustom={'btn-link'}
                            color={'bg-white'}
                            type={'button'}
                            text={'Cancel'}
                            onClick={this.handleCancelAndClose}
                        />
                    </div>
                )}
            />
        );
    }
}

export default UserModal;