import { AUTH } from './../../services'
import { transformObject } from '../../helpers';

export const RESET_PASSWORD_STATE = {
    RESET_PASSWORD_REQUEST: 'AUTH_RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'AUTH_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILED: 'AUTH_RESET_PASSWORD_FAILED',
};

const requestResetPassword = (requestType) => ({
    type: RESET_PASSWORD_STATE.RESET_PASSWORD_REQUEST,
    requestType
});

const resetPasswordSuccess = (requestType, response) => ({
    type: RESET_PASSWORD_STATE.RESET_PASSWORD_SUCCESS,
    requestType,
    payload: response
});

const resetPasswordFailed = (requestType, error) => ({
    type: RESET_PASSWORD_STATE.RESET_PASSWORD_FAILED,
    requestType,
    payload: error
});

export const checkExpired = (params) => {
    return dispatch => {
        dispatch(requestResetPassword('check'));
        AUTH.getResetPassword(params)
            .then(
                response => {
                    let data = response.data.body;
                    dispatch(resetPasswordSuccess('check', data));
                },
                error => {
                    dispatch(resetPasswordFailed('check', null));
                }
            );
    };
};

export const resetPassword = (params, setErrors) => {
    return dispatch => {
        dispatch(requestResetPassword('reset'));
        AUTH.postResetPassword(params)
            .then(
                response => {
                    const data = response.data.body;
                    dispatch(resetPasswordSuccess('reset', data));
                },
                error => {
                    const response = error.response.data;
                    const code = response.meta.code;
                    if(code === 422) {
                        const errMsg = transformObject(response.body.message);
                        setErrors(errMsg);
                        dispatch(resetPasswordFailed('validate', errMsg));
                    } else {
                        dispatch(resetPasswordFailed('reset', response.body.message));
                    }
                }
            );
    };
};

