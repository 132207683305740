import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { debounce } from 'lodash';
import { sortNested } from '../../helpers';
import { DtLoader, DtSearch, DtLimit } from '../../components/ui';
import { DtPagination, DtHeader } from '../../components/datatables';

class Datatable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sortType: 'desc',
            sortBy: null,
            limit: null,
            page: null,
            filter: null,
            colspan: null,
        }
    }

    UNSAFE_componentWillMount() {
        let cols = this.props.tableHeader.length + 1;
            cols = this.props.checkBox ? cols + 1 : cols;
            cols = this.props.headerLength ? this.props.headerLength : cols;

        this.setState({ colspan: cols });
    }

    renderTableBody = () => {
        const { sortType, sortBy, colspan } = this.state;
        let { dataSource, pagination } = this.props;
        let data  = pagination ? dataSource.data : dataSource; 
        
        // DevNote : dont forget to adjust in parent component :
        // if pagination === TRUE : dataSource.data.map  
        // if pagination === FALSE : dataSource.map  
        
        if(Array.isArray(data) && data.length) {
            if(pagination) {
                dataSource.data = sortNested(data, sortBy, sortType);
            } else {
                dataSource = sortNested(data, sortBy, sortType);
            }
            return this.props.tableBody(dataSource);
        } else {
            return (
                <tr><td colSpan={colspan} className="dt-empty-text"> No data available in table </td></tr>
            );
        }
    }

    handleFilter = (params, resetPage = false) => {
        this.setState(
            params, 
            () =>  {
                let { limit, page, filter } = this.state;
                if(resetPage) page = 1;
                this.props.reloadDatatable(limit, page, filter);
            }
        );
    }

    handleSearch = debounce((keyword) => {
        this.handleFilter({ filter: keyword }, true);
    }, 500);

    handleSort = (sortType, sortBy) => {
        this.setState({ sortType, sortBy });
    }

    handleCreate = () => {
        this.props.toggleModal('create');
    }

    render() {
        return (
            <div className={this.props.card !== false ? 'container-fluid' : ''}>
                {
                    this.props.title ? 
                    (
                        <div className="block-header">
                            <h2>
                                {this.props.title.main ? this.props.title.main : ''}
                                <small>{this.props.title.sub ? this.props.title.sub : ''}</small>
                            </h2>
                        </div>
                    ) : ''
                }
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className={this.props.card !== false ? 'card' : ''}>
                            {   
                                this.props.customHeader ? 
                                this.props.customHeader() :
                                (
                                    this.props.creatable !== false ?
                                    (
                                        <div className="header">
                                            <div className="d-flex">
                                                <button
                                                    className="btn btn-lg btn-primary waves-effect btn-create"
                                                    onClick={this.handleCreate}>
                                                    <i className="material-icons">add</i>
                                                    <span>Create New</span>
                                                </button>
                                            </div>
                                        </div>
                                    ) : ''
                                )
                            }
                            <div className={`body datatable ${this.props.customFilter ? 'p-tb-0' : ''}`}>
                                {
                                    this.props.customFilter ?
                                    this.props.customFilter() : 
                                    undefined
                                }
                                {
                                    this.props.searchable === false && this.props.limitable === false ? 
                                    '' :
                                    (
                                        <div className="row clearfix dt-body-filter">
                                            <div className="col-md-6 col-lg-6 col-sm-6 mb-0">
                                            {
                                                this.props.limitable !== false ?
                                                (
                                                    <DtLimit
                                                        onChange={(event) => this.handleFilter(event, true)}
                                                    />
                                                ) : ''
                                            }
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-sm-6">
                                            {
                                                this.props.searchable !== false ?
                                                (
                                                    <DtSearch
                                                        placeholder={'Search'}
                                                        icon={'search'}
                                                        onChange={this.handleSearch}
                                                    />
                                                ) : ''
                                            }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <div className="table-responsive">
                                        <ReactTooltip id={`action-table`}/>
                                        <table 
                                            className={
                                                this.props.tableClassCustom ? 
                                                this.props.tableClassCustom :
                                                "table table-bordered table-striped table-hover js-basic-example dataTable"
                                            }
                                        >
                                            <thead>
                                                <tr>
                                                    { this.props.checkBox ? this.props.checkBox() : undefined }
                                                    <th className="w-1p index-th">No. </th>
                                                    <DtHeader
                                                        header={this.props.tableHeader}
                                                        onSort={this.handleSort}
                                                        sortable={this.props.sortable === false ? this.props.sortable : true}
                                                    />
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.props.isLoading ?
                                                <DtLoader
                                                    size={this.props.loaderSize ? this.props.loaderSize : undefined}
                                                    loaderCustom={this.props.loaderCustom ? this.props.loaderCustom : undefined}
                                                    showText={this.props.showLoaderText === false ? false : true}
                                                    colspan={this.state.colspan}
                                                /> :
                                                this.renderTableBody()
                                            }
                                            </tbody>
                                        </table>
                                        <div className="table-footer">
                                        </div>
                                    </div>
                                    {
                                        this.props.pagination ?
                                        <DtPagination
                                            dataSource={this.props.dataSource}
                                            reloadDatatable={this.handleFilter}
                                        /> :
                                        ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Datatable;


