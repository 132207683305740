import { AUTH } from './../../services';
import { transformObject } from '../../helpers';

export const FORGOT_PASSWORD_STATE = {
    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SENT: 'FORGOT_PASSWORD_SENT',
    FORGOT_PASSWORD_FAILED: 'FORGOT_PASSWORD_FAILED',
};

const requestForgotPassword = (requestType) => ({
    type: FORGOT_PASSWORD_STATE.FORGOT_PASSWORD_REQUEST,
    requestType
});

const forgotPasswordSent = (response) => ({
    type: FORGOT_PASSWORD_STATE.FORGOT_PASSWORD_SENT,
    payload: response
});

const forgotPasswordFailed = (requestType, error) => ({
    type: FORGOT_PASSWORD_STATE.FORGOT_PASSWORD_FAILED,
    requestType,
    payload: error
});

export const forgotPassword = (params, setErrors, resetForm) => {
    return async (dispatch) => {
        dispatch(requestForgotPassword('post'));
        await AUTH.postForgotPassword(params)
            .then(
                response => {
                    dispatch(forgotPasswordSent(response.data.body));
                    resetForm();
                },
                error => {
                    const response = error.response.data;
                    const code = response.meta.code;
                    if(code === 422) {
                        const errMsg = transformObject(response.body.message);
                        setErrors(errMsg);
                        dispatch(forgotPasswordFailed('validate', errMsg));
                    } else {
                        dispatch(forgotPasswordFailed('post', response.body.message));
                    }
                }
            );
    };
};