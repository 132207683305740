import { FORGOT_PASSWORD_STATE } from './action';

const INITIAL_STATE = {
    loading: false,
    data: null,
    success: null,
    error: null,
    errorMessage: null,
};

const forgotPasswordReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_STATE.FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                success: null,
                error: null,
                errorMessage: null,
                data: null,
            };
        case FORGOT_PASSWORD_STATE.FORGOT_PASSWORD_SENT:
            return {
                ...state,
                loading: false,
                success: 'email-sent',
                data: action.payload
            };
        case FORGOT_PASSWORD_STATE.FORGOT_PASSWORD_FAILED:
            const errMsg = action.requestType === 'validate' ? null : action.payload;
            return {
                ...state,
                loading: false,
                error: action.requestType,
                errorMessage: errMsg,
            };
        default:
            return state;
    }
};

export default forgotPasswordReducer;
