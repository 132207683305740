import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { convDate, numericKeypress } from '../../helpers';
import { default as ReactSelect } from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IconButton, Menu, MenuItem } from '@material-ui/core';

export const MenuDropdown = (props) => {
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };

  return (
    <div>
        <IconButton aria-label={props.label} aria-controls={`menu-${props.name}`} aria-haspopup="true" onClick={handleClick}>
            <i className="material-icons">person</i>
        </IconButton>
        <Menu
            className="pull-right"
            id={`menu-${props.name}`}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            keepMounted
        >
            {props.items}
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu>
    </div>
  );
}

export const Loader = (props) => {
    return (
        <div className={props.customClass ? props.customClass : 'page-loader-wrapper'}>
            <div className="loader">
                <div className={`preloader ${props.size ? 'pl-size-'+props.size : ''} `}>
                    <div className="spinner-layer pl-green">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>
                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
                {
                    props.showText === false ?
                    '' :
                    (<p>{props.text ? props.text : "Please wait..."}</p>)
                }
            </div>
        </div>
    );
}

export const DtLoader = (props) => {
    return (
        <tr className={`${props.className ? props.className : ''}`}>
            <td colSpan={props.colspan} className="dt-empty-text">
                <div className="page-loader-wrapper loader-dt">
                    <div className={`loader ${props.loaderCustom ? props.loaderCustom : ''}`}>
                        <div className={`preloader ${props.size ? 'pl-size-'+props.size : 'pl-size-sm'} `}>
                            <div className="spinner-layer pl-green">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                        {
                            props.showText === false ?
                            '' :
                            (<p>{props.text ? props.text : "Please wait..."}</p>)
                        }
                    </div>
                </div>
            </td>
        </tr>
    );
}

export const BtnSpinner = (props) => {
    return (
        <div className={`preloader ${props.class ? props.class : 'pl-size-xss'}`}>
            <div className={`spinner-layer pl-${props.color ? props.color : 'white'}`}>
                <div className="circle-clipper left">
                    <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
            </div>
        </div>
    );
};

export const Button = ({ classCustom, loading, ...props }) => {
    return (
        <button 
            className={`btn ${props.color ? ' btn-colored ' + props.color : ' btn-primary'} ${classCustom ? classCustom : ''} waves-effect ${props.disabled ? ' disabled' : ''}`} 
            type={props.type} 
            disabled={props.disabled}
            {...props}
        >
            { loading ? <BtnSpinner/> : (props.icon ? <i className="material-icons">{props.icon}</i> : '') }
            { 
                props.icon ? 
                (<span className={`btn-text ${ loading ? 'loading' : ''}`}>{ props.text }</span>) :
                props.text
            }
        </button>
    );
};

export const PaginationLink = (props) => {
    return (
        <li key={props.linkKey} className={`${props.disabled ? ' disabled' : ''} ${props.active ? ' active' : ''}`}>
            <Link
                to={props.link ? props.link : "#"}
                onClick={ !props.disabled ? props.onClick : undefined}
                className="waves-effect">
                { props.icon ? <i className="material-icons pagination-icon">{props.icon}</i> : '' }
                { props.text }
            </Link>
        </li>
    );
};

export const DtSearch = (props) => {

    const handleInputChange = (event) => {
        const keyword = event.target.value;
        props.onChange(keyword);
    }

    return (
        <div className={`input-group datatable-search ${props.className ? props.className : ''}`}>
            <span className="input-group-addon">
                <i className="material-icons">{props.icon}</i>
            </span>
            <div className="form-line">
                <input type="text" onChange={handleInputChange} className="form-control" placeholder={props.placeholder}/>
            </div>
        </div>
    );
}

export const DtFilter = (props) => {
    const handleChange = (event) => {
        const id = event.target.value;
        props.onChange(id);
    }

    const renderOptions = (options, keyPair) => {
        return options.map((value) => {
            const {[keyPair[0]]: id, [keyPair[1]]: text} = value;
            return (<option key={id} value={id}>{text}</option>);
        });
    }

    return (
        <div className={`input-group dt-filter ${props.className}`}>
            <span className={`label-dt-filter ${props.labelClass}`}>
                <label>{props.label}</label>
            </span>
            <div className="form-line">
                <select type="text" className="form-control" onChange={handleChange}>
                    <option value="">{props.placeholder ? props.placeholder : `Select ${props.label}`}</option>
                    {props.data ? renderOptions(props.data, props.keyPair) : ''}
                </select>
            </div>
        </div>
    );
}

export const DtLimit = (props) => {
    const handleChange = (event) => {
        const value = event.target.value;
        props.onChange({'limit': value});
    }

    const renderLimitFilter = () => {
        const limit = [10,25,50];
        return limit.map((value, index) => {
            return (<option key={index} value={value}>{value}</option>);
        });
    }

    return (
        <div className="input-group input-sm mb-0">
            <label> Show</label>
            <select
                className={`form-control input-sm dt-limit-filter ${props.customClass ? props.customClass : '' }`}
                onChange={handleChange}
            >
                { renderLimitFilter() }
            </select>
            <label>entries</label>
        </div>
    );
}

export const Select2Filter = ({ detailedValue, customPrefix, ...props}) => {

    const renderOptions = () => {
        const data = props.data;
        const keyPair = props.keyPair;
        if(Array.isArray(data) && data.length) {
            return data.map((value) => {
                const {[keyPair[0]]: id, [keyPair[1]]: text} = value;
                const isDisabled = value.isDisabled ? true : false;
                return({ value: id, label: text, isDisabled });
            });
        }
        return [];
    }

    const options = renderOptions();

    const find = (initialValue) => {
        if(props.isMulti && initialValue) {
            return options.filter(data => initialValue.includes(data.value) );
        } else if (initialValue || initialValue === 0) {
            return options.find(option => option.value === initialValue)
        } else {
            return null;
        }
    }

    const handleChange = (event) => {  // event is object: {value : id, label: text}, if isMulti == true event is Array of value
        let value = props.isMulti ? event : event.value;
        if(props.name) {
            if(detailedValue) {
                props.onChange({ name: props.name, value });
            } else {
                props.onChange({ [props.name]:value });
            }
        } else {
            props.onChange(value);
        }
    }

    const selectStyles = { 
        menuPortal: (style) => ({ ...style, zIndex: 9999 }),
        option: (styles) => {
            return {
              ...styles,
              fontSize: 12,
              fontFamily: 'Roboto',
            }
        },
    };

    return (
        <div className={`input-group dt-filter ${props.className}`}>
            {
                props.label ?
                (
                    <span className={`label-dt-filter ${props.labelClass}`}>
                        <label>
                            {props.label && !props.loading ? props.label : `Loading ${props.label ? props.label : ''} ...`}
                            {props.mandatory ? <span className="mandatory-icon">*</span> : ''}
                        </label>
                    </span>
                ) : ''
            }
            {
                props.loading ?
                (
                    <Loader
                        size={'xs'}
                        showText={false}
                        customClass={'loader-select'}
                    />
                ) :
                (
                    <div className="form-line">
                        <ReactSelect
                            menuPortalTarget={document.body}
                            styles={selectStyles}
                            className='react-select-container'
                            classNamePrefix={props.customPrefix ? props.customPrefix: 'react-select'}
                            options={props.data ? options : null}
                            menuContainerStyle={{ zIndex: 999 }}
                            placeholder={props.placeholder ? props.placeholder : `Select ${props.label}`}
                            onChange={handleChange}
                            isDisabled={props.disabled ? true : false}
                            isMulti={props.isMulti}
                            defaultValue={find(props.defaultValue)}
                            value={props.setValue || props.setValue === null || props.setValue === 0 ? find(props.setValue) : undefined}
                        />
                    </div>
                )
            }
        </div>
    );
}

export const SelectFilter = (props) => {
    const handleChange = (event) => {
        const id = event.target.value;
        if(props.name) {
            props.onChange({[props.name]:id});
        } else {
            props.onChange(id);
        }
    }

    const renderOptions = (options, keyPair) => {
        return options.map((value, index) => {
            const {[keyPair[0]]: id, [keyPair[1]]: text} = value;
            return (<option key={id} value={id}>{text}</option>);
        });
    }

    return (
        <div className={`input-group dt-filter ${props.className}`}>
            <span className={`label-dt-filter ${props.labelClass}`}>
                <label>
                    {props.label && !props.loading ? props.label : `Loading ${props.label ? props.label : ''} ...`}
                    {props.mandatory ? <span className="mandatory-icon">*</span> : ''}
                </label>
            </span>
            {
                props.loading ?
                (<Loader
                    size={'xs'}
                    showText={false}
                    customClass={'loader-select'}
                 />
                ) :
                (
                    <div className="form-line">
                        <select type="text" className="form-control" onChange={handleChange}
                            value={props.setValue ? props.setValue : ''}
                            disabled={props.disabled ? true : false}
                            data-dropup-auto="false"
                        >
                            <option value="">{props.placeholder ? props.placeholder : `Select ${props.label}`}</option>
                            {props.data ? renderOptions(props.data, props.keyPair) : ''}
                        </select>
                    </div>
                )
            }
        </div>
    );
}

export const DatepickerFilter = (props) => {
    const initialDate = props.setValue || props.setValue === undefined ? props.setValue : new Date();
    const [startDate, setStartDate] = useState(initialDate);
    const calendarRef = React.useRef();
    const rootElement = document.querySelector('#root');
    const PopperContainer = ({ children }) => (ReactDOM.createPortal(children, rootElement));

    const handleClickIcon = () => {
        calendarRef.current.setOpen(true);
    }

    const handleChange = (date) => {
        setStartDate(date);
        if(props.name) {
            props.onChange({[props.name]: convDate(date)});
        } else {
            props.onChange(convDate(date));
        }
    }

    return (
        <div className={`input-group datepicker ${props.className}`}>
            <span className={`label-dt-filter ${props.labelClass}`}>
                <label>{props.label} {props.mandatory ? <span className="mandatory-icon">*</span> : ''}</label>
            </span>
            <div className="form-line">
                <DatePicker
                    className="form-control"
                    selected={startDate}
                    placeholderText={props.placeholder}
                    dateFormat={props.type === 'monthyear' ? "MM-yyyy" : "dd-MM-yyyy"}
                    showMonthYearPicker={props.type === 'monthyear' ? true : false}
                    showDisabledMonthNavigation
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    onChange={handleChange}
                    popperContainer={PopperContainer}
                    disabled={props.disabled ? true : false}
                    ref={calendarRef}
                />
            </div>
            { props.icon ? ( <span className="input-group-addon absolute-icon" onClick={handleClickIcon}><i className="material-icons">{props.icon}</i></span>) : '' }
        </div>
    );
};

export const Select = (props) => {
    
    const handleChange = (event) => {
        const value = event.target.value;
        props.onChange({name: props.name, value});
    }

    const renderOptions = (options, keyPair) => {
        return options.map((value, index) => {
            const {[keyPair[0]]: id, [keyPair[1]]: text} = value;
            if(props.onlyValue) { //special handler for currencies, return value=string not ID
                return (<option key={id} value={text}>{text}</option>);
            }
            return (<option key={id} value={id}>{text}</option>);
        });
    }

    if(props.formGroup) {
        return (
            <div>
                <label htmlFor={props.name}>
                    {props.label} {props.mandatory ? <span className="mandatory-icon">*</span> : ''}
                </label>
                <div className="form-group">
                    <div className={`form-line`}>
                        <select className="form-control show-tick" as="select"
                            defaultValue={props.setValue ? props.setValue : ''}
                            onChange={handleChange}
                            onFocus={props.onFocus}
                            disabled={props.disabled ? true : false}
                            {...props}
                        >
                            <option value="">{props.placeholder ? props.placeholder : `None`}</option>
                            {props.data ? renderOptions(props.data, props.keyPair) : ''}
                        </select>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <select type="text" className={`${props.className} ${props.disabled ? ' disabled' : ''}`}
            defaultValue={props.setValue ? props.setValue : ''}
            onChange={handleChange}
            onFocus={props.onFocus}
            disabled={props.disabled ? true : false}
        >
            <option value="">{props.placeholder ? props.placeholder : `None`}</option>
            {props.data ? renderOptions(props.data, props.keyPair) : ''}
        </select>
    );
}

export const InlineLabel = (props) => {
    return (
        <div className="row clearfix">
            <div className={`${props.customLabel ? props.customLabel : 'col-lg-2 col-md-2 form-control-label' } ${props.align}`}>
                <label htmlFor="email_address_2">{props.label ? props.label : ''}</label>
            </div>
            <div className={`${props.customInput ? props.customInput : 'col-lg-10 col-md-10' }`}>
                <div className="form-group">
                    <div className={`${props.line === false ? '' : 'form-line'}`}>
                        <label htmlFor="email_address_2">{props.text ? props.text : ''}</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const InlineContainer = ({ classRow, classLabel, classInput, removeMargin, ...props}) => {
    return (
        <div className={`row ${classRow ? classRow : ''}`}>
            <div className={`${classLabel ? classLabel : 'col-lg-2 col-md-2 form-control-label' } ${props.align ? props.align : 'text-left'}  
                ${removeMargin ? 'm-0' : ''}`}>
                <label>{props.label ? props.label : ''}</label>
            </div>
            <div className={`${classInput ? classInput : 'col-lg-10 col-md-10' } ${removeMargin ? 'm-0' : ''}`}>
                {
                    props.content ? 
                    props.content() : ''
                }
            </div>
        </div>
    );
}

export const InputLabel = ({ ...props }) => {

    const handleKeypress = (e) => {
        numericKeypress(e);
    }

    const renderInputType = (type) => {
        if(type === 'number') {
            return (
                <input className="form-control" {...props}
                    onKeyDown={handleKeypress}
                    min="0"
                    placeholder="0"
                />
            );
        } else if(type === 'currency') {
            return (
                <input
                    className="form-control" {...props}
                    onKeyDown={handleKeypress}
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                />
            );
        } else {
            return (
                <input className="form-control" {...props} />
            );
        }
    }

    return (
        <div>
            <label htmlFor={props.name}>
                {props.label} {props.mandatory ? <span className="mandatory-icon">*</span> : ''}
            </label>
            <div className="form-group">
                <div className={`${props.line === false ? '' : 'form-line'}`}>
                    {renderInputType(props.type)}
                </div>
            </div>
        </div>
    );
};

export const Tooltip = (props) => {
    return (
        <ReactTooltip id={`action-${props.id}`}/>
    );
}

export const Tabs = ({ classMain, classHeader, classNav, classContent, tabs, contents, activeTab, ...props }) => {

    const handleChangeTab = (tabName) => {
        props.handleChangeTab(tabName);
    }

    const renderTabHeader = (tabs) => {
        return tabs.map((value, index) => {
            return (
                <li key={`tabs-${index}`} role="presentation" className={`${activeTab === value.name ? 'active' : ''}`}>
                    <Link 
                        to={"#"} 
                        data-toggle="tab"
                        onClick={() => handleChangeTab(value.name)}
                    >
                        { value.icon ? (<i className="material-icons mr-10">{value.icon}</i>) : '' }
                        { value.text }
                    </Link>
                </li>
            );
        });
    }

    const renderTabContent = (contents) => {
        return contents.map((value, index) => {
            return (
                <div key={`tab-contents-${index}`} role="tabpanel" id="earning" className={`tab-pane fade ${activeTab === value.name ? ' in active' : ''}`}>
                    {value.content}
                </div>
            );
        });
    }

    return (
        <div className={classMain ? classMain : ''}>
            <div className={classHeader ? classHeader : 'header-tabs'}>
                <ul className={`nav nav-tabs tab-col-green tab-nav-right ${classNav ? classNav : ''}`} role="tablist">
                    { renderTabHeader(tabs) }
                </ul>
            </div>
            <div className={classContent ? classContent : 'tab-content'}>
                { renderTabContent(contents) }
            </div>
        </div>
    );
}

export const TabContent = ({ activeTab, tabName, content, ...props }) => {
    return (
        <div role="tabpanel" id="earning" className={`tab-pane fade ${activeTab === tabName ? ' in active' : ''}`}>
            {props.children}
        </div>
    );
}
