import React, { Component } from "react";
import { prorate, showPopup, showToast, mergeParams } from "../../helpers";
import { InputTable } from "../../components/forms";
import { Select } from "../../components/ui";

export class StaticFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: true,
      dataChanged: false,
      wageDays: 0,
      params: [],
      editParams: { editing: false, type: null, index: null, values: {} },
    };
    this.initialState = this.state;
    this.resetEdit = this.state.editParams;
  }

  componentDidMount() {
    if (this.props.dataSource) {
      const dataSource = this.props.dataSource;
      const params = mergeParams(
        dataSource.params,
        dataSource.currencies,
        dataSource.wageDays
      );
      const wageDays = dataSource.wageDays;

      this.setState(
        {
          editable: this.props.editable,
          params,
          wageDays,
        },
        () => this.handleSubmitToParent()
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.editParams.editing !== this.state.editParams.editing) {
      this.props.handleEditing(this.state.editParams.editing); // send editing state to parent component
    }

    if (prevProps.dataSource.wageDays !== this.props.dataSource.wageDays) {
      const dataSource = this.props.dataSource;
      const params = mergeParams(
        dataSource.params,
        dataSource.currencies,
        dataSource.wageDays
      );
      const wageDays = dataSource.wageDays;

      this.setState(
        {
          editable: this.props.editable,
          params,
          wageDays,
        }
        // () => this.handleSubmitToParent()
      );
    }
  }

  handleSubmitToParent = () => {
    this.props.handleSubmit({
      type: "static",
      changed: this.state.dataChanged,
      params: this.state.params,
    });
  };

  toggleEdit = (edit, indexParam, valueParam) => {
    if (edit) {
      const index =
        indexParam === "new"
          ? Object.keys(this.state.params).length
          : indexParam;
      this.setState({
        editParams: {
          ...this.state.editParams,
          editing: true,
          type: "edit",
          index,
          values: valueParam,
        },
      });
    } else {
      const initialParams = this.state.params;
      this.setState(
        {
          editParams: this.resetEdit,
          dataChanged: true,
          params: [],
        },
        () => this.setState({ params: initialParams })
      ); // revert to initial params if cancelled
    }
  };

  handleAcceptUpdate = (type, index, oldValue) => {
    const newParams = this.state.editParams.values;
    const params = this.state.params;

    if (!newParams || !newParams.amount || !newParams.currency) {
      showToast("error", "Please fill in amount and currency");
    } else {
      const updatedParams = Object.assign([...params], { [index]: newParams });
      this.setState(
        {
          dataChanged: true,
          params: updatedParams,
          editParams: this.resetEdit,
        },
        () => this.handleSubmitToParent()
      );
    }
  };

  handleCancelUpdate = () => {
    this.toggleEdit(false);
  };

  handleDelete = (removedIndex, value) => {
    const params = this.state.params;
    showPopup("confirm-delete", null, () => {
      params.splice(removedIndex, 1);
      this.setState({ params, dataChanged: true });
    });
  };

  handleValueChange = (params) => {
    const edit = this.state.editParams;
    this.setState({
      editParams: {
        ...edit,
        values: { ...edit.values, [params.name]: params.value },
      },
    });
  };

  renderBody = (newParams) => {
    const editState = this.state.editParams;
    if (Array.isArray(newParams) && newParams.length) {
      return newParams.map((value, index, array) => {
        return this.renderRow("existing", value, index, array);
      });
    } else if (editState.editing) {
      return;
    } else {
      const cols = this.props.prorate ? 6 : 5;
      return (
        <tr key={0}>
          <td colSpan={cols} className="dt-empty-text">
            {" "}
            No data found{" "}
          </td>
        </tr>
      );
    }
  };

  renderRow = (type, value, index) => {
    const editState = this.state.editParams;

    const replaceString = (string) => {
      return string.replace(/_/g, " ");
    };

    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td className={`ucwords`}>
          <InputTable
            classCustom={"break input-static-name"}
            name={"name"}
            type={"text"}
            defaultValue={value.name}
            onBlur={this.handleValueChange}
            disabled={
              editState.editing && editState.index === index ? false : true
            }
            hidden
          />
          {replaceString(value.name)}
        </td>
        <td
          className={`text-right ${
            editState.editing && editState.index === index ? " edited" : ""
          }`}
        >
          <InputTable
            type={"currency"}
            name={"amount"}
            defaultValue={value.amount}
            onBlur={this.handleValueChange}
            disabled={
              editState.editing && editState.index === index ? false : true
            }
          />
        </td>
        {this.props.prorate ? (
          <td className={`text-right nonedit`}>
            {value.amount
              ? prorate(value.amount, this.state.wageDays, true, true)
              : "0.00"}
          </td>
        ) : (
          ""
        )}
        <td
          className={`${
            editState.editing && editState.index === index ? " edited" : ""
          }`}
        >
          <Select
            className={""}
            name={"currency"}
            data={this.props.currencyData}
            keyPair={["id", "name"]}
            index={index}
            placeholder={"None"}
            onChange={this.handleValueChange}
            setValue={value.currency}
            disabled={
              editState.editing && editState.index === index ? false : true
            }
            onlyValue={true}
          />
        </td>
        <td className="text-center">
          {editState.editing ? (
            editState.index === index ? (
              <div>
                <button
                  className="btn-outline pad-0"
                  type="button"
                  onClick={() => this.handleAcceptUpdate(type, index, value)}
                >
                  <i className="material-icons">check</i>
                </button>
                <button
                  className="btn-outline pad-0"
                  type="button"
                  onClick={this.handleCancelUpdate}
                >
                  <i className="material-icons">close</i>
                </button>
              </div>
            ) : (
              ""
            )
          ) : (
            <div>
              <button
                className="btn-outline pad-0"
                type="button"
                onClick={() => this.toggleEdit(true, index, value)}
              >
                <i className="material-icons">edit</i>
              </button>
            </div>
          )}
        </td>
      </tr>
    );
  };

  renderTableHeader() {
    const defaultHeader = [
      { name: "Name", className: "w-auto text-center break" },
      { name: "Amount", className: "td-20 text-center" },
      { name: "Prorate", className: "td-20 text-center" },
      { name: "Currency", className: "td-75px" },
      { name: "Action", className: "td-100px text-center" },
    ];

    const header = this.props.tableHeader
      ? this.props.tableHeader
      : defaultHeader;

    return header.map((key, index) => {
      if (!this.props.prorate && key.name === "Prorate") {
        return false;
      }
      return (
        <th className={`${key.className ? key.className : ""}`} key={index}>
          {key.name}
        </th>
      );
    });
  }

  render() {
    const { params } = this.state;
    return (
      <div className="table-responsive">
        <table className="table table-striped table-hover table-fixed editable">
          <thead>
            <tr>
              <th className="td-30px">No.</th>
              {this.renderTableHeader()}
            </tr>
          </thead>
          <tbody>{this.renderBody(params)}</tbody>
        </table>
      </div>
    );
  }
}

export default StaticFields;
