import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

class AlertPswd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    UNSAFE_componentWillMount() {
        let days = this.handleCountdown();
        if(days < 7) { this.setState({ show : true }); }
    }

    handleClose = () => {
        this.setState({ show : false });
    }


    handleCountdown = () => {
        if(this.props.countdown) {
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            const now = new Date();
            const expiredDay = new Date(this.props.countdown);
            const diffDays = Math.round(Math.abs((now - expiredDay) / oneDay));
            return diffDays;
        }
    }

    render() {
        let days = this.handleCountdown();
        const { show } = this.state;
        return (
            <CSSTransition
                classNames="slide-trans"
                in={show}
                timeout={500}
                unmountOnExit
                appear
            >
                <div
                    className={`alert alert-danger alert-dismissible alert-global`}
                    role="alert"
                >
                    <button 
                        type="button" 
                        className="close" 
                        data-dismiss="alert" 
                        aria-label="Close"
                        onClick={this.handleClose}
                    >
                        <span aria-hidden="true">×</span></button>
                        Your password will expired in {days < 1 ? "1" : days} {days < 2 ? 'day' : 'days'}, Please change your password immediately
                </div>
            </CSSTransition>
        );
    }
}

export default AlertPswd;