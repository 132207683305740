import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

class NavbarList extends Component {

    checkActive(url) {
        return (url === this.props.location.pathname) ? 'active' : '';
    }

    render() {
        const props = this.props;
        return (
            <li className={`${this.checkActive(props.url)}`}>
                <Link className="waves-effect" to={props.url}>
                    { props.icon ? <i className="material-icons">{props.icon}</i> : '' }
                    <span>{ props.menuName }</span>
                </Link>
            </li>
        );
    }
}


export default withRouter(NavbarList);