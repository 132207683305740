import { PROFILE_STATE } from './action';

const INITIAL_STATE = {
    loading: false,
    error: null,
    errorMessage: null,
    success: null,
    data: []
};

const profileReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROFILE_STATE.REQUEST:
            return {
                ...state,
                loading: true,
                success: null,
                error: null,
                errorMessage: null,
                data: [],
            };
        case PROFILE_STATE.REQUEST_FAILED:
            const errMsg = action.requestType === 'validate' ? null : action.payload;
            return {
                ...state,
                loading: false,
                error: action.requestType,
                errorMessage: errMsg,
            };
        case PROFILE_STATE.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'get',
                data: action.payload.contents
            };
        case PROFILE_STATE.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'update',
                data: action.payload.contents
            };
        default:
            return state;
    }
};

export default profileReducer;
