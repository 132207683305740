import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Datatable } from '../../components/datatables';

class UserDatatable extends Component {

    renderTableBody = (dataSource) =>  {
        if(Array.isArray(dataSource.data) && dataSource.data.length) {
            let { from } = dataSource;
            return dataSource.data.map((value) => {
                const { id, username, email , active, roles } = value;
                const isSuperadmin = roles && roles[0].id === 1 ? true : false;
                return (
                    <tr key={id}>
                        <td className="index-td">{from++}</td>
                        <td>{username}</td>
                        <td>{email}</td>
                        <td className="text-center">
                            <ReactTooltip id={`switch-${id}`}/>
                            <form>
                                <div className="switch"
                                    data-tip={active === 1 ? "Switch to Inactive" : "Switch to Active"}
                                    data-for={`action-${id}`}
                                >
                                    <label>
                                        <input
                                            className={isSuperadmin ? 'disabled' : ''}
                                            name="active"
                                            type="checkbox"
                                            defaultChecked={active === 1 ? true : false}
                                            onChange={!isSuperadmin ? (event) => this.props.toggleActivation(event, id) : undefined}
                                            disabled={isSuperadmin ? true : false}
                                        />
                                        <span className="lever switch-col-green"></span>
                                    </label>
                                </div>
                            </form>
                        </td>
                        <td className="text-center">
                            <ReactTooltip id={`action-${id}`}/>
                            {
                                roles && roles[0].id === 1 ? '' :
                                (
                                    <button
                                        className="btn btn-circle btn-sm bg-red waves-effect waves-circle"
                                        data-tip="Delete" data-for={`action-${id}`}
                                        onClick={() => this.props.toggleConfirmDelete(id)}>
                                        <i className="material-icons">delete_forever</i>
                                    </button>
                                )
                            }
                        </td>
                    </tr>
                );
            });
        }
    }

    render() {
        const {  isLoading, dataSource, currencyData, reloadDatatable, toggleModal } = this.props;
        const datatableHeader = [
            {label: 'Username', field: 'username'},
            {label: 'Email', field: 'email'},
            {label: 'Active', field: ''},
            {label: 'Actions', field: ''},
        ];

        return (
            <Datatable
                isLoading={isLoading}
                tableHeader={datatableHeader}
                tableBody={this.renderTableBody}
                title={{main: 'User', sub: 'User Account Management'}}
                dataSource={dataSource}
                currencyData={currencyData}
                toggleModal={toggleModal}
                reloadDatatable={reloadDatatable}
                pagination={true}
            />
        );
    }
}

export default UserDatatable;


