import { apiService } from '../config';

export function getSettings() {
    return apiService.get(`/settings`);
};

export function putSettings(params) {
    return apiService.put(`/settings/update`,
        params
    );
};

