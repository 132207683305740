import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DynamicFields from '../../components/dynamic-fields';
import Modal from '../../components/modal';
import { revertToDBFormat, thousand, showPopup } from '../../helpers';
import { Select } from '../../components/forms';
import { Button, InlineLabel } from '../../components/ui';
import { MInput } from '../../components/material/form';

class BasicWageModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            editing: false,
            dataChanged: false,
            additionalData: [],
            dynamicData: {},
        };
        this.formik = React.createRef();
    }

    UNSAFE_componentWillMount() {
        this.setState({ data: this.props.formData });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(!nextProps.isOpen && this.props.isOpen && this.props.isOpen !== nextProps.isOpen) {
            this.setState({ dataChanged : false });
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.formData !== prevProps.formData) {
            const formData = this.props.formData ? this.props.formData : {};
            this.setState({ data: formData });
        }
    }

    render() {
        const { 
            isOpen, formType, handleSubmit, handleClose, errorMessage, modalLoading,
            principalData, rankData, currencyData,
        } = this.props;

        let formData = this.state.data;
        let dynamicData = (data) => {
            if(data) {
                return {
                    params: data.others,
                    currencies: data.others_currency,
                }
            }
            return {};
        }

        const validation = Yup.object({
            principal: Yup.string().required('Principal is required'),
            rank: Yup.string().required('Rank is required'),
            currency: Yup.string().required('Choose currency'),
            basic_wages: Yup.string().required('Basic wage is required'),
            fixed_overtime: Yup.string().required('Fixed O/T is required'),
            leave: Yup.string().required('Leave pay is required'),
        });

        const renderDetail = () => {
            return (
                <div>
                    <div className="row">
                        <div className="col-lg-12">
                            <InlineLabel
                                align={"text-left"}
                                label={"Principal"}
                                text={formData.principal_name}
                            />
                            <InlineLabel
                                align={"text-left"}
                                label={"Rank"}
                                text={formData.rank_name+' ( '+formData.rank_code+' )'}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <InlineLabel
                                customLabel={"col-lg-4 col-md-4 form-control-label"}
                                customInput={"col-lg-8 col-md-8"}
                                align={"text-left"}
                                label={"Basic Wage"}
                                text={formData.basic_wages ? thousand(formData.basic_wages): '-'}
                            />
                        </div>
                        <div className="col-lg-6">
                            <InlineLabel
                                align={"text-left"}customLabel={"col-lg-4 col-md-4 form-control-label"}
                                customInput={"col-lg-8 col-md-8"}
                                label={"Fixed O/T"}
                                text={formData.fixed_overtime ? thousand(formData.fixed_overtime): '-'}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <InlineLabel
                                align={"text-left"}
                                customLabel={"col-lg-4 col-md-4 form-control-label"}
                                customInput={"col-lg-8 col-md-8"}
                                label={"Leave Pay"}
                                text={formData.leave ? thousand(formData.leave): '-'}
                            />
                        </div>
                        <div className="col-lg-6">
                             <InlineLabel
                                align={"text-left"}
                                customLabel={"col-lg-4 col-md-4 form-control-label"}
                                customInput={"col-lg-8 col-md-8"}
                                label={"Overtime"}
                                text={formData.over_time ? thousand(formData.over_time): '-'}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <InlineLabel
                                align={"text-left"}
                                customLabel={"col-lg-4 col-md-4 form-control-label"}
                                customInput={"col-lg-8 col-md-8"}
                                label={"Currency"}
                                text={formData.currency}
                            />
                        </div>
                    </div>
                </div>
            )
        }

        const renderForm = () => {
            return (
                <div>
                    <div className="row clearfix">
                        <div className='col-md-5 col-sm-5'>
                            <Select
                                label="Principal"
                                className="form-control"
                                name="principal"
                                mandatory="true"
                                placeholder="Select Principal"
                                data={principalData}
                                keyPair={['id', 'name']}
                                line
                            />
                        </div>
                        <div className='col-md-5 col-sm-5'>
                            <Select
                                label="Rank"
                                className="form-control"
                                name="rank"
                                placeholder="Select Rank"
                                mandatory="true"
                                data={rankData}
                                keyPair={['id', 'description']}
                                line
                            />
                        </div>
                        <div className='col-md-2 col-sm-2'>
                            <Select
                                label="Currency"
                                className="form-control"
                                name="currency"
                                mandatory="true"
                                placeholder="Currency"
                                data={currencyData}
                                keyPair={['id', 'name']}
                                onlyValue={true}
                                line
                            />
                        </div>
                    </div>
                    <h5 className="card-inside-title">Basic Wage Mandatories</h5>
                    <div className="row clearfix">
                        <div className='col-md-3 col-sm-3'>
                            <MInput
                                label="Basic Wage"
                                name="basic_wages"
                                type="currency"
                                placeholder="0.00"
                                required
                                fullWidth
                            />
                        </div>
                        <div className='col-md-3 col-sm-3'>
                            <MInput
                                label="Fixed O/T"
                                name="fixed_overtime"
                                type="currency"
                                placeholder="0.00"
                                required
                                fullWidth
                            />
                        </div>
                        <div className='col-md-3 col-sm-3'>
                            <MInput
                                label="Leave Pay"
                                name="leave"
                                type="currency"
                                placeholder="0.00"
                                required
                                fullWidth
                            />
                        </div>
                        <div className='col-md-3 col-sm-3'>
                            <MInput
                                label="Overtime"
                                name="over_time"
                                type="currency"
                                placeholder="0.00"
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
            );
        }

        const handleCancelAndClose = () => {
            let { dirty, resetForm } = this.formik.current;
            const clearAndClose = () => {
                dirty = false;
                handleClose();
                resetForm();
            }
            
            if(dirty || this.state.dataChanged) {
                showPopup(
                    'custom-confirm',
                    "You haven't saved the data. Cancel the proceed anyway? Unsaved data may occurred",
                    'Discard changes',
                    'warning',
                    'reversed',
                    () => clearAndClose()
                )
            } else {
                clearAndClose();
            }
        }

        const handleOthers = (data) => {
            if(data.type === 'dynamic') this.setState({ additionalData: data.params });
            if(data && data.changed) this.setState({ dataChanged: true });
        }

        const clickSubmit = () => {
            const formikProps = this.formik.current;
            formikProps.handleSubmit();
        }

        const handleSubmitAndOthers = (params, formikProps) => {
            const result = revertToDBFormat(this.state.additionalData);
            const mergedValues = {...params, others: result.values, others_currency: result.currencies};
            handleSubmit(mergedValues, formikProps);
        }

        const handleFormEditing = (childParam) => {
            this.setState({ editing: childParam});
        }

        const getTitle = () => {
            if(formType === 'create') {
                return "Create New Basic Wage";
            } else if (formType === 'update') {
                return "Update  Basic Wage";
            } else {
                return "Basic Wage Detail";
            }
        }

        return (
            <Modal
                isOpen={isOpen}
                background={'bg-green'}
                size={'modal-lg'}
                title={getTitle()}
                onClose={handleCancelAndClose}
                errorMessage={errorMessage}
                content={(
                    <Formik
                        innerRef={this.formik}
                        initialValues={formData}
                        validationSchema={validation}
                        onSubmit={handleSubmitAndOthers}
                        enableReinitialize
                    >
                        { () => (
                            <Form autoComplete="off">
                                {
                                    formType === 'detail' ?
                                    renderDetail() :
                                    renderForm()
                                }
                                <h5 className="card-inside-title">Additional Values</h5>
                                <DynamicFields
                                    handleSubmit={handleOthers}
                                    handleEditing={handleFormEditing}
                                    dataSource={dynamicData(formData)}
                                    currencyData={currencyData}
                                    editable={formType === 'detail' ? false : true}
                                />
                            </Form>
                        )}
                    </Formik>
                )}
                footer={(
                    <div>
                        {
                            formType === 'detail' ?
                            '' :
                            (
                                <Button
                                    classCustom={'btn-lg'}
                                    color={'bg-blue'}
                                    type={'submit'}
                                    disabled={modalLoading || this.state.editing}
                                    loading={modalLoading}
                                    text={ formType === 'create' ? 'Save' : 'Update' }
                                    icon={'check'}
                                    onClick={clickSubmit}
                                />
                            )
                        }
                        <button type="button"
                            onClick={()=>{handleCancelAndClose()}}
                            className="btn btn-link waves-effect"
                        >
                            {formType === 'detail' ? 'Close' : "Cancel"}
                        </button>
                    </div>
                )} 
            />
        );
    }
}

export default BasicWageModal;