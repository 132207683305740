import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PaginationLink } from '../ui';

class DtPagination extends Component {

    handlePagination = (limit, page) => {
        this.props.reloadDatatable({limit, page});
    }

    render() {
        const dataSource = this.props.dataSource;
        if(dataSource){
            if(Array.isArray(dataSource.data) && dataSource.data.length) {
                const { last_page, current_page, next_page_url, prev_page_url, from, to, total, per_page } = this.props.dataSource;
                const limit = per_page;
                let neighbor_page_left = current_page < 2 ? current_page : current_page - 1;
                let neighbor_page_right = current_page === last_page ? current_page :  current_page + 1;
                let pageLinks = [];

                for (let i = neighbor_page_left; i <= neighbor_page_right; i++) {
                    const fixedKey = i;
                    pageLinks.push(
                        <li key={fixedKey} className={`${i === current_page ? ' active' : ''}`}>
                            <Link to={"#"}
                                onClick={() => this.handlePagination(limit, fixedKey)}
                                className={`waves-effect`}
                            >{i}</Link>
                        </li>
                    );
                }

                return (
                    <div className="row">
                        <div className="col-md-5 col-lg-5">
                            <div className="pagination-text">
                                Showing {from} to {to} of {total} entries
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-7">
                            <div className="dt-pagination">
                                <ul className="pagination">
                                    <PaginationLink
                                        disabled={current_page === 1 ? true : false}
                                        text={"First"}
                                        onClick={() => this.handlePagination(limit, 1)}
                                    />
                                    <PaginationLink
                                        disabled={!prev_page_url ? true : false}
                                        icon={"chevron_left"}
                                        onClick={() => this.handlePagination(limit, current_page-1)}
                                    />
                                    {pageLinks}
                                    <PaginationLink
                                        disabled={!next_page_url ? true : false}
                                        icon={"chevron_right"}
                                        onClick={() => this.handlePagination(limit, current_page+1)}
                                    />
                                    <PaginationLink
                                        disabled={current_page === last_page ? true : false}
                                        text={"Last"}
                                        onClick={() => this.handlePagination(limit, last_page)}
                                    />
                                </ul>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (<div/>);
            }
        }
    }
}

export default DtPagination;