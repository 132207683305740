import { PRINCIPAL_STATE } from './action';

const INITIAL_STATE = {
    dtLoading: false,
    datatable: [],
    datatableAll: [],
    success: null,
    error: null,
    errorMessage: null,
};

const principalReducer = (state = INITIAL_STATE, action) => {
    const data = action.requestType === 'all' ? 'datatableAll' : 'datatable';
    switch (action.type) {
        case PRINCIPAL_STATE.REQUEST:
            return {
                ...state,
                dtLoading: true,
                success: null,
                error: null,
                errorMessage: null,
                [data]: [],
            };
        case PRINCIPAL_STATE.REQUEST_FAILED:
            return {
                ...state,
                dtLoading: false,
                error: action.requestType,
                errorMessage: action.payload,
            };
        case PRINCIPAL_STATE.FETCH_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                success: 'dt',
                datatable: action.payload.contents.principals
            };
        case PRINCIPAL_STATE.FETCH_ALL_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                success: 'all',
                datatableAll: action.payload.contents.principals
            };
        default:
            return state;
    }
};

export default principalReducer;
