import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NavbarList from "./navbar-list";
import profilebg from "../../assets/images/vessel1.jpg";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccountSettings: false,
    };
  }

  toggleAccountSetting = () => {
    this.setState({ showAccountSettings: !this.state.showAccountSettings });
  };

  renderUserInfo() {
    return (
      <div className="user-info-container">
        <img className="bg-profile" src={profilebg} alt="profile" />
        <div className="user-info">
          <div className="info-container">
            <div className="name">
              {this.props.user ? this.props.user.username : ""}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLeftSidebar() {
    return (
      <aside id="leftsidebar" className="sidebar">
        {this.renderUserInfo()}
        <div className="menu">
          <ul className="list">
            <li className="header">PAYROLL MENU</li>
            <NavbarList
              menuName={"Generate Payroll"}
              url={"/generate-payroll"}
              icon={"loop"}
            />
            <NavbarList
              menuName={"Payroll Transaction"}
              url={"/payroll-transaction"}
              icon={"dvr"}
            />
            <NavbarList
              menuName={"Wage Summary"}
              url={"/wage-summary"}
              icon={"description"}
            />
            <NavbarList
              menuName={"Basic Wage"}
              url={"/basic-wage"}
              icon={"business_center"}
            />
            <NavbarList
              menuName={"Exchange Rate"}
              url={"/exchange-rate"}
              icon={"local_atm"}
            />
            {this.props.user && this.props.user.roles[0].id === 1 ? (
              <NavbarList
                menuName={"User"}
                url={"/user"}
                icon={"supervisor_account"}
              />
            ) : undefined}
            {this.props.user && this.props.user.roles[0].id === 1 ? (
              <NavbarList
                menuName={"General Setting"}
                url={"/general-setting"}
                icon={"settings"}
              />
            ) : undefined}
            {this.props.user && this.props.user.roles[0].id === 1 ? (
              <NavbarList
                menuName={"Audit Trail"}
                url={"/audit-trail"}
                icon={"history"}
              />
            ) : undefined}
          </ul>
        </div>
        {/* <div className="legal">
                    <div className="copyright">
                        &copy; 2020 <Link to={"#"}>Crew Payroll System</Link>
                    </div>
                </div> */}
      </aside>
    );
  }

  render() {
    return <section>{this.renderLeftSidebar()}</section>;
  }
}

const mapStateToProps = ({ auth, profile }) => ({
  token: auth.token,
  user: auth.user,
});

export default withRouter(connect(mapStateToProps)(Sidebar));
