import { USER_STATE } from './action';

const INITIAL_STATE = {
    dtLoading: true,
    modalLoading: false,
    error: null,
    errorMessage: null,
    success: null,
    datatable: [],
    data: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
    const loading = action.requestType === 'dt' ? 'dtLoading' : 'modalLoading';
    switch (action.type) {
        case USER_STATE.REQUEST:
            return {
                ...state,
                [loading]: true,
                error: null,
                errorMessage: null,
                success: null,
                data: [],
            };
        case USER_STATE.REQUEST_FAILED:
            return {
                ...state,
                [loading]: false,
                error: action.requestType,
                errorMessage: action.payload,
            };
        case USER_STATE.FETCH_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                success: 'dt',
                datatable: action.payload.contents.users
            };
        case USER_STATE.CREATE_SUCCESS:
            return {
                ...state,
                modalLoading: false,
                success: 'create',
                data: action.payload
            };
        case USER_STATE.ACTIVATE_SUCCESS:
            return {
                ...state,
                modalLoading: false,
                success: 'activate',
                data: action.payload,
            };
        case USER_STATE.DELETE_SUCCESS:
            return {
                ...state,
                modalLoading: false,
                success: 'delete',
                data: action.payload
            };
        default:
            return state;
    }
};

export default userReducer;
