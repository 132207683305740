import { AUTH } from './../../services'
import { transformObject } from '../../helpers';

export const VERIFY_PASSWORD_STATE = {
    VERIFY_PASSWORD_REQUEST: 'VERIFY_PASSWORD_REQUEST',
    VERIFY_PASSWORD_SUCCESS: 'VERIFY_PASSWORD_SUCCESS',
    VERIFY_PASSWORD_FAILED: 'VERIFY_PASSWORD_FAILED',
};

const requestVerifyPassword = (requestType) => ({
    type: VERIFY_PASSWORD_STATE.VERIFY_PASSWORD_REQUEST,
    requestType
});

const verifyPasswordSuccess = (requestType, response) => ({
    type: VERIFY_PASSWORD_STATE.VERIFY_PASSWORD_SUCCESS,
    requestType,
    payload: response
});

const verifyPasswordFailed = (requestType, error) => ({
    type: VERIFY_PASSWORD_STATE.VERIFY_PASSWORD_FAILED,
    requestType,
    payload: error
});

export const checkExpired = (params) => {
    return dispatch => {
        dispatch(requestVerifyPassword('check'));
        AUTH.getVerifyPassword(params)
            .then(
                response => {
                    let data = response.data.body;
                    dispatch(verifyPasswordSuccess('check', data));
                },
                error => {
                    dispatch(verifyPasswordFailed('check', null));
                }
            );
    };
};

export const changePassword = (params, setErrors) => {
    return dispatch => {
        dispatch(requestVerifyPassword('verify'));
        AUTH.postVerifyPassword(params)
            .then(
                response => {
                    const data = response.data.body;
                    dispatch(verifyPasswordSuccess('verify', data));
                },
                error => {
                    const response = error.response.data;
                    const code = response.meta.code;
                    if(code === 422) {
                        const errMsg = transformObject(response.body.message);
                        setErrors(errMsg);
                        dispatch(verifyPasswordFailed('validate', errMsg));
                    } else {
                        dispatch(verifyPasswordFailed('verify', response.body.message));
                    }
                }
            );
    };
};