
import axios from 'axios';
import { store } from './store';
import { forceLogout } from '../pages/auth/action';
import { showPopup } from '../helpers';

const { dispatch, getState } = store;

const apiService = axios.create({
    baseURL: process.env.REACT_APP_API_URL+process.env.REACT_APP_API_PATH,
    timeout: 10000,
});

// set Global Request Header
apiService.interceptors.request.use((config) => {
    const token = getState().auth.token;
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

let errorPopupOpen = false;

// set Global 401 / 500 Response handler
apiService.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error;
        let errMsg = 'Something is wrong, please try again later ...';

        const errorParse = (err, message) => { // add property error.response.data.body message 
            return {  
                ...err, 
                response : {
                    ...err.response,
                    data : {
                        ...err.response.data,
                        meta: { code : err.response.status },
                        body: { message } 
                    }, 
                }
            }
        }

        const showError = (message) => {
            if( errorPopupOpen === false) {
                errorPopupOpen = true;
                showPopup(
                    'error-confirm',
                    message,
                    () => {
                        window.location.reload(true);
                        errorPopupOpen = false;
                        return true;
                    },
                    () => {
                        errorPopupOpen = false;
                        return false;
                    },
                );
            } 
        }

        if (error.response) {
            console.log("interceptors-error-response : ", error.response);
            if(response.data.meta) { 
                // handle standard error format from backend
                if (response.data.meta.code === 401) dispatch(forceLogout());
            } else { 
                // handle default error from server / axios (eg: timeout, 404/500 errors)
                if(process.env.REACT_APP_MODE === 'DEV') {
                    errMsg = error.response.data.message ? error.response.data.message : error.response.statusText;
                } 
                error = errorParse(error, errMsg);
                showError(errMsg)
            }
        } else { 
            // *handle other global error (connection, api, etc)
            console.log("interceptors-xhr-error : ", error.toJSON());
            errMsg = (error.code === 'ECONNABORTED') ? 'Warning! There was a problem with your network connection' : errMsg;
            error = { response : { data: { meta: { code: 400 }, body: { message: null }}}}; // imitate backend standard error response
            showError(errMsg)
        }
        return Promise.reject(error); // return all response as promise error
    }
);

export { apiService }
