import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { login } from "./action";
import { Button } from "../../components/ui";
import { Alert } from "../../components/alert";
import * as Yup from "yup";
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { MInput } from "../../components/material/form";

const useStyles = (theme) => ({
  root: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    "& .MuiButton-containedPrimary:hover": {
      backgroundColor: "darkgreen",
    },
    "& .Mui-checked": {
      color: "green",
    },
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rememberMe: 0,
      formData: {
        username: "",
        password: "",
      },
    };
    this.formik = React.createRef();
  }

  UNSAFE_componentWillMount() {
    if (this.props.token) {
      this.props.history.push("/generate-payroll"); // redirect to generate-payroll if already logged-in
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.props.success &&
      nextProps.success === "login" &&
      !nextProps.loading
    ) {
      nextProps.history.push("/generate-payroll"); // redirect to generate-payroll if login success
    }
  }

  componentDidMount() {
    if (this.props.authErrorMessage) {
      this.setState({ alertMessage: this.props.authErrorMessage });
    }
  }

  handleSubmit = (params, { setErrors }) => {
    params.remember_me = this.state.rememberMe;
    this.props.login(params, setErrors);
  };

  toggleRememberMe = () => {
    this.setState({ rememberMe: this.state.rememberMe === 0 ? 1 : 0 });
  };

  renderForm = () => {
    const { formData, rememberMe } = this.state;
    const { loading, classes } = this.props;

    const validation = Yup.object({
      username: Yup.string().required("Username is required"),
      password: Yup.string().required(
        "Please fill your password, the password is required"
      ),
    });

    return (
      <Formik
        innerRef={this.formik}
        initialValues={formData}
        validationSchema={validation}
        onSubmit={this.handleSubmit}
      >
        {() => (
          <Form className={classes.root}>
            <MInput
              id="username"
              label="Username"
              name="username"
              placeholder="Enter username"
              required
              fullWidth
              autoFocus
            />
            <MInput
              id="Password"
              label="Password"
              name="password"
              type="password"
              placeholder="Enter password"
              required
              fullWidth
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  name="rememberme"
                  id="rememberme"
                  className={classes.checkBox}
                  checked={rememberMe === 1 ? true : false}
                  onChange={this.toggleRememberMe}
                />
              }
              label="Remember me"
            />
            <Button
              classCustom={"btn-block btn-login"}
              color={"bg-green"}
              type={"submit"}
              disabled={loading}
              loading={loading}
              text={"LOGIN"}
              icon={"exit_to_app"}
            />
            <Grid container>
              <Grid item xs>
                <Link to={"/forgot-password"}>Forgot Password?</Link>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    const { error, errorMessage } = this.props;
    return (
      <div>
        <Alert text={error === "login" && errorMessage ? errorMessage : ""} />
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        {this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, login }) => ({
  token: auth.token,
  loading: login.loading,
  success: login.success,
  error: login.error,
  errorMessage: login.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  login: (params, setErrors) => dispatch(login(params, setErrors)),
});

const LoginStyle = withStyles(useStyles)(Login);

export default connect(mapStateToProps, mapDispatchToProps)(LoginStyle);
