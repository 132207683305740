import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getPayroll,
  updateEarningPayroll,
  removePayroll,
  postPreview,
  postDeleteAll,
  formChanged,
} from "./action";
import { getReason } from "../reason/action";
import { getCurrency } from "../currency/action";
import { splitDate, showPopup, showToast, compareArray } from "../../helpers";
import { Button } from "../../components/ui";
import PayrollFilter from "../../components/payroll-filter";
import PayrollDatatable from "./datatable";
import PayrollModal from "./edit";
import PreviewModal from "./preview";
import PromoteModal from "./promote";

class PayrollPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: [],
      formType: null,
      openModal: null,
      modalData: null,
      dtFilter: { keyword: "" },
      reload: false,
    };
    this.initialState = this.state;
  }

  UNSAFE_componentWillMount() {
    this.fetchPayrollDatatable();
    this.props.getCurrency();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.payroll.success) {
      if (
        nextProps.success === "update-earning" ||
        nextProps.success === "update-remarks-leavedays"
      ) {
        showToast("success", "Payroll data successfully updated");
        if (this.props.dataChanged) this.props.formChanged(false);
        if (nextProps.closeModal) this.toggleModal("close");
      }

      if (nextProps.payroll.success === "delete") {
        showToast("success", "Data successfully deleted");
        this.fetchPayrollDatatable();
      }

      if (nextProps.success === "generate-preview") {
        this.toggleModal("preview");
      }

      if (nextProps.success === "send-preview-all") {
        showToast("success", "Payslip email successfully added to queue list");
        this.toggleModal("close");
        this.setState({ ids: [] });
        this.fetchPayrollDatatable();
      }

      if (nextProps.success === "promote-demote") {
        this.toggleModal("promote");
        showToast("success", "Data successfully created");
      }
    }

    // if(nextProps.dataChanged && !this.state.reload && nextProps.closeModal) { // reload datatable only when changes occured
    this.setState({ reload: true });
    // }
  }

  componentDidMount() {
    const { reasonData } = this.props;
    if (Array.isArray(reasonData) && reasonData.length < 1) {
      this.props.getReason();
    }
    this.fetchPayrollDatatable();
  }

  fetchPayrollDatatable = () => {
    const { vessel_id, csc, wage_period } = this.props.globalFilter;
    this.props.getPayroll({
      keyword: this.state.dtFilter.keyword,
      vessel_id,
      csc,
      month: splitDate(wage_period, 1),
      year: splitDate(wage_period, 0),
    });
  };

  getVesselName = (key, arrayObject) => {
    return arrayObject.find((o) => o.id === key);
  };

  checkReady = (withPayrollSelect) => {
    const {
      ids,
      principal_id,
      vessel_id,
      csc,
      wage_period,
    } = this.props.globalFilter;
    if (principal_id && vessel_id && csc !== null && wage_period) {
      if (withPayrollSelect && withPayrollSelect === "payroll")
        return ids.length > 0 ? true : false;
      return true;
    }
    return false;
  };

  resetPayrollFilter = () => {
    this.setState(this.initialState);
  };

  toggleModal = (type, params) => {
    this.setState(
      {
        formType: type,
        modalData: {
          ...params,
          vessel: this.getVesselName(
            this.props.globalFilter.vessel_id,
            this.props.vesselData
          ),
        },
        previousPayrollIds: this.props.previousPayrollIds,
      },
      () => this.setState({ openModal: type })
    );

    if (type === "close" && this.state.reload) {
      // reload datatable only when changes occured
      this.fetchPayrollDatatable();
      this.setState({ reload: false });
    }
  };

  toggleConfirmDelete = (id) => {
    showPopup("confirm-delete", id, () => this.props.removePayroll({ id }));
  };

  handleSubmit = (params) => {
    this.props.updatePayroll(params);
  };

  handlePreview = () => {
    const params = { ids: this.state.ids };
    this.props.postPreview(params);
  };

  handleDeleteAll = () => {
    const params = { ids: this.state.ids };
    this.props.postDeleteAll(params);
    this.setState({ ids: [] });
  };

  handleSearch = (keyword) => {
    this.setState({ dtFilter: { ...this.state.dtFilter, keyword } }, () =>
      this.fetchPayrollDatatable()
    );
  };

  handleSelectAccount = (selectedId) => {
    const stateId = this.state.ids;
    const allAccountIds = this.props.accountIds;
    const completedIds = this.props.completedIds;
    let remainingIds = allAccountIds.filter((id) => !completedIds.includes(id));

    if (selectedId === "all") {
      this.setState(() => {
        if (Array.isArray(stateId) && compareArray(stateId, remainingIds))
          return { ids: [] }; // remove all account id from checked state
        return { ids: remainingIds }; // fill all account id to checked state
      });
    } else {
      this.setState(() => {
        if (stateId.includes(selectedId))
          return { ids: stateId.filter((value) => selectedId !== value) };
        return { ids: [...stateId, selectedId] };
      });
    }
  };

  handleFilterChange = (params) => {
    this.setState(params, () => {
      this.setState({ ids: [] });
      this.fetchPayrollDatatable();
    });
  };

  renderFilterPayroll = () => {
    const { loading } = this.props;
    const payrollSelected = () => (this.state.ids.length ? true : false);
    return (
      <div className="payroll-filter">
        <PayrollFilter onChange={this.handleFilterChange} />
        <div className="row pad-0 container-button-payroll">
          <div className="col-md-9 m-0">
            <Button
              classCustom={"btn-preview btn-lg"}
              color={"bg-blue"}
              type={"submit"}
              disabled={payrollSelected() && !loading ? false : true}
              loading={loading}
              text={"Preview & Send"}
              icon={"unarchive"}
              onClick={() => this.handlePreview()}
            />
            <Button
              classCustom={"btn-remove-all btn-lg"}
              color={"bg-red"}
              type={"submit"}
              disabled={payrollSelected() && !loading ? false : true}
              loading={loading}
              text={"Remove All"}
              icon={"delete"}
              onClick={() => this.handleDeleteAll()}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { csc } = this.props.globalFilter;
    const { ids, openModal, modalData } = this.state;
    const {
      dtLoading,
      loading,
      payrollData,
      currencyData,
      accountIds,
      completedIds,
      previousPayrollIds,
      previewData,
    } = this.props;

    return (
      <div className="page-container">
        <div className="container-fluid">
          <div className="block-header">
            <h2>
              Payroll Transaction
              <small>Payroll Transaction Data</small>
            </h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="card">
                <div className="body">
                  {this.renderFilterPayroll()}
                  <PayrollDatatable
                    isLoading={dtLoading}
                    dataSource={payrollData}
                    currencyData={currencyData}
                    reloadDatatable={this.fetchPayrollDatatable}
                    handleSelect={this.handleSelectAccount}
                    handleSearch={this.handleSearch}
                    toggleModal={this.toggleModal}
                    toggleConfirmDelete={this.toggleConfirmDelete}
                    accountIds={accountIds}
                    selectedAccount={ids}
                    completedIds={completedIds}
                    csc={csc}
                  />
                  <PromoteModal
                    isOpen={openModal === "promote" ? true : false}
                    dataSource={modalData}
                    handleClose={() => this.toggleModal("close")}
                    modalLoading={loading}
                  />
                  {openModal === "edit" && modalData ? (
                    <PayrollModal
                      isOpen={openModal}
                      dataSource={modalData}
                      dataPrevious={previousPayrollIds}
                      handleClose={() => this.toggleModal("close")}
                      modalLoading={loading}
                      csc={csc}
                      currencyData={currencyData}
                    />
                  ) : (
                    ""
                  )}
                  {openModal === "preview" ? (
                    <PreviewModal
                      isOpen={openModal}
                      dataSource={previewData}
                      handleClose={() => this.toggleModal("close")}
                      modalLoading={loading}
                      csc={csc}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ global, payroll, vessel, currency, reason }) => ({
  payroll,
  globalFilter: global.payrollFilter,
  dataChanged: payroll.formChanged,
  payrollData: payroll.payrollDatatable,
  accountIds: payroll.accountIds,
  previousPayrollIds: payroll.previousPayrollIds,
  completedIds: payroll.sentIds,
  success: payroll.success,
  error: payroll.error,
  errorMessage: payroll.errorMessage,
  closeModal: payroll.closeModal,
  loading: payroll.loading,
  dtLoading: payroll.dtLoading,
  excelData: payroll.excelData,
  previewData: payroll.previewData,
  vesselData: vessel.datatable,
  currencyData: currency.datatable,
  reasonData: reason.datatable,
});

const mapDispatchToProps = (dispatch) => ({
  getReason: (params) => dispatch(getReason(params)),
  getCurrency: (params) => dispatch(getCurrency(params)),
  getPayroll: (params) => dispatch(getPayroll(params)),
  updateEarningPayroll: (params) => dispatch(updateEarningPayroll(params)),
  removePayroll: (params) => dispatch(removePayroll(params)),
  postPreview: (params) => dispatch(postPreview(params)),
  postDeleteAll: (params) => dispatch(postDeleteAll(params)),
  formChanged: (params) => dispatch(formChanged(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollPage);
