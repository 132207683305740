export const AUTH_STATE = {
    TOKEN_EXPIRED: 'AUTH_TOKEN_EXPIRED',
};

const tokenExpired = (response) => ({
    type: AUTH_STATE.TOKEN_EXPIRED,
    payload: response
});

export const forceLogout = () => {
    return dispatch => {
        const message = 'Your login session has expired';
        dispatch(tokenExpired(message));
    };
};