import { apiService } from '../config';

export function getSummary(params) {
    const keyword = params.keyword ? params.keyword : '';
    const vessel_id = params.vessel_id ? params.vessel_id : '';
    const csc = params.csc !== null ? params.csc : '';
    const month = params.month ? params.month : '';
    const year = params.year ? params.year : '';
    return apiService.get(`/wage-summary?keyword=${keyword}&vessel_id=${vessel_id}&csc=${csc}&month=${month}&year=${year}`);
}

export function postDownload(params) {
    return apiService.post(`/wage-summary/download?vessel_id=${params.vessel_id}&csc=${params.csc}&month=${params.month}&year=${params.year}`);
    
    // return apiService.post(`/wage-summary/download`,
    //     params
    // );
};