
export const GLOBAL_STATE = {
    FILTER_CHANGE: 'GLOBAL_FILTER_CHANGED',
};

const filterChanged = (lastFilter) => ({
    type: GLOBAL_STATE.FILTER_CHANGE,
    payload: lastFilter
})

export const changeFilter = (lastFilter) => {
    return dispatch => {
        dispatch(filterChanged(lastFilter));
    };
};