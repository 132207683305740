import { apiService } from '../config';

export function get(params) {
    const page = params.page ? `${params.page}` : '';
    const limit = params.limit ? `${params.limit}` : '';
    const filter = params.filter ? `${params.filter}` : '';
    return apiService.get(`/exchange-rates?page=${page}&limit=${limit}&keyword=${filter}`);
};

export function post(params) {
    return apiService.post(`/exchange-rates/store`,
        params
    );
};

export function put(params) {
    return apiService.post(`/exchange-rates/update`,
        params
    );
};

export function remove(params) {
    return apiService.delete(`/exchange-rates/delete/${params.id}`,
        params
    );
};

export function getHistory(params) {
    return apiService.get(`/exchange-rates/history?rate_id=${params.rate_id}`);
};

