import { NOTIFICATION_STATE } from './action';

const INITIAL_STATE = {
    dtLoading: false,
    loading: false,
    datatable: [],
    success: null,
    error: null,
    errorMessage: null,
    notifIds: [],
};

const notificationReducer = (state = INITIAL_STATE, action) => {
    const loading = action.requestType === 'dt' ? 'dtLoading' : 'loading';
    switch (action.type) {
        case NOTIFICATION_STATE.REQUEST:
            return {
                ...state,
                [loading]: true,
                success: null,
                error: null,
                errorMessage: null,
            };
        case NOTIFICATION_STATE.REQUEST_FAILED:
            return {
                ...state,
                [loading]: false,
                error: action.requestType,
                errorMessage: action.payload,
                datatable: [],
            };
        case NOTIFICATION_STATE.FETCH_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                success: 'dt',
                datatable: action.payload.contents.notifications,
                notifIds: action.payload.contents.ids,
            };
        case NOTIFICATION_STATE.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'update',
                data: action.payload,
            };    
        default:
            return state;
    }
};

export default notificationReducer;
