import { EXCHANGE_RATE_STATE } from './action';

const INITIAL_STATE = {
    dtLoading: true,
    datatable: [],
    error: null,
    errorMessage: null,
    openModal: false,
    modalLoading: false,
    success: null,
    data: null,
    historyData: [],
};

const exchangeRateReducer = (state = INITIAL_STATE, action) => {
    const loading = action.requestType === 'dt' ? 'dtLoading' : 'modalLoading';
    switch (action.type) {
        case EXCHANGE_RATE_STATE.REQUEST:
            return {
                ...state,
                [loading]: true,
                success: null,
                error: null,
                errorMessage: null,
            };
        case EXCHANGE_RATE_STATE.REQUEST_FAILED:
            const errMsg = action.requestType === 'validate' ? null : action.payload;
            return {
                ...state,
                [loading]: false,
                error: action.requestType,
                errorMessage: errMsg,
            };
        case EXCHANGE_RATE_STATE.FETCH_SUCCESS:
            return {
                ...state,
                dtLoading: false,
                datatable: action.payload.contents.exchangeRates
            };
        case EXCHANGE_RATE_STATE.CREATE_SUCCESS:
            return {
                ...state,
                openModal: false,
                modalLoading: false,
                success: 'create',
                data: action.payload,
            };
        case EXCHANGE_RATE_STATE.UPDATE_SUCCESS:
            return {
                ...state,
                openModal: false,
                modalLoading: false,
                success: 'update',
                data: action.payload,
            };
        case EXCHANGE_RATE_STATE.DELETE_SUCCESS:
            return {
                ...state,
                openModal: false,
                modalLoading: false,
                success: 'delete',
            };
        case EXCHANGE_RATE_STATE.HISTORY_FETCH_SUCCESS:
            return {
                ...state,
                modalLoading: false,
                historyData: action.payload.contents.rateAmounts
            };
        default:
            return state;
    }
};

export default exchangeRateReducer;
