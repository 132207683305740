import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
    };
  }

  componentDidMount() {
    this.setState({ showError: false });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ showError: false });
    }

    if (!prevProps.errorMessage && this.props.errorMessage) {
      this.setState({ showError: true });
    }
  }

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { showError } = this.state;
    const {
      isOpen,
      content,
      footer,
      errorMessage,
      background,
      title,
      size,
      classBody,
      removeClose,
    } = this.props;

    return (
      <div>
        <CSSTransition
          classNames="fade-trans"
          in={isOpen}
          timeout={250}
          unmountOnExit
          appear
        >
          <div className={`modal-backdrop`} id={"modal-backdrop"}></div>
        </CSSTransition>
        <CSSTransition
          classNames="slide-trans"
          in={isOpen}
          timeout={250}
          unmountOnExit
          appear
        >
          <div className="modal" role="dialog">
            <div className={`modal-dialog ${size ? size : ""}`} role="document">
              <div id={"modal-global"} className="modal-content fixed-height">
                <div className={`modal-header ${background}`}>
                  <h4 className="modal-title">{title}</h4>
                  {!removeClose ? (
                    <button className="btn-close" onClick={this.handleClose}>
                      <i className="material-icons">close</i>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className={`modal-body ${classBody ? classBody : ""}`}>
                  {content}
                </div>
                {footer ? (
                  <div className="modal-footer">
                    {errorMessage ? (
                      <div className="row">
                        <div className="col-md-7 col-lg-7 m-0">
                          <div
                            className={`modal-error ${
                              showError && errorMessage ? "" : "hidden"
                            }`}
                          >
                            {errorMessage}
                          </div>
                        </div>
                        <div className="col-md-5 col-lg-5 m-0">{footer}</div>
                      </div>
                    ) : (
                      footer
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}
export default Modal;
