import { GLOBAL_STATE } from './action';
import { PROFILE_STATE } from '../profile/action';
import { convDate } from '../../helpers';

const GLOBAL_INITIAL_STATE = {
    passwordExpiredDate: null,
    payrollFilter: {
        principal_id: null,
        vessel_id: null,
        csc: null,
        wage_period: convDate(new Date()),
    },
};

const globalReducer = (state = GLOBAL_INITIAL_STATE, action) => {
    switch (action.type) {
        case GLOBAL_STATE.FILTER_CHANGE:
            return {
                ...state,
                payrollFilter: action.payload,
            };
        case PROFILE_STATE.UPDATE_SUCCESS:
            return {
                ...state,
                passwordExpiredDate: action.payload.contents.passwordExpiredDate,
            };
        default:
            return state;
    }
};

export default globalReducer;