import { PAYROLL_STATE } from './action';

const INITIAL_STATE = {
    loading: false,
    dtLoading: false,
    payrollDatatable: [],
    request: null,
    error: null,
    errorMessage: null,
    success: null,
    accountIds: [],
    previousPayrollIds: [],
    sentIds: [],
    formChanged: false,
    formEditing: false,
    previewData: [],
    promotionData: null,
    closeModal: true,
    formReset: null,
    updatedParams: {},
    updatedData: {},
    submitStatus: null,
    trigger: null,
    currentTab: null,
    nextTab: null,
};

const payrollReducer = (state = INITIAL_STATE, action) => {
    const loading = action.requestType === 'dt-payroll' ? 'dtLoading' : 'loading';
    switch (action.type) {
        case PAYROLL_STATE.REQUEST:
            return {
                ...state,
                [loading]: true,
                error: null,
                errorMessage: null,
                success: null,
                request: action.requestType,
                trigger: action.type,
            };
        case PAYROLL_STATE.REQUEST_FAILED:
            return {
                ...state,
                [loading]: false,
                error: action.requestType,
                errorMessage: action.payload,
                request: null,
            };
        case PAYROLL_STATE.FETCH_PAYROLL_SUCCESS:
            return {
                ...state,
                [loading]: false,
                payrollDatatable: action.payload.contents.wageAccounts,
                accountIds: action.payload.contents.ids,
                previousPayrollIds: action.payload.contents.previousPayrollIds,
                sentIds: action.payload.contents.sentIds,
            };
        case PAYROLL_STATE.UPDATE_PAYROLL_SUCCESS:
            return {
                ...state,
                [loading]: false,
                success: action.requestType,
                updatedParams: action.updatedParams,
                updatedData: action.payload,
                trigger: action.type,
            };
        case PAYROLL_STATE.DELETE_PAYROLL_SUCCESS:
            return {
                ...state,
                [loading]: false,
                success: 'delete',
            };
        case PAYROLL_STATE.GENERATE_PREVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.requestType,
                previewData: action.payload.contents
            };
        case PAYROLL_STATE.SEND_PAYSLIP_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.requestType,
            };
        case PAYROLL_STATE.CREATE_PROMOTION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.requestType,
                promotionData: action.payload.contents
            };
        case PAYROLL_STATE.FORM_CHANGED:
            return {
                ...state,
                formChanged: action.changed,
                formEditing: action.editing,
            };
        case PAYROLL_STATE.FORM_TAB_RESET:
            return {
                ...state,
                formReset: action.status,
            };
        case PAYROLL_STATE.SUBMIT_TAB:
            return {
                ...state,
                submitStatus: action.submitStatus,
                closeModal: action.closeModal,
                currentTab: action.currentTab, 
                nextTab: action.nextTab,
                trigger: action.type,
            };
        case PAYROLL_STATE.RESET_SUBMIT_TAB:
            return {
                ...state,
                closeModal: action.closeModal,
                submitStatus: false,
                currentTab: null, 
                nextTab: null,
                trigger: action.type,
            };
        default:
            return state;
    }
};

export default payrollReducer;
