import React, { Component } from 'react';

class Alert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
        }
    }

    UNSAFE_componentWillMount() {
        if(this.props.text) {
            this.setState({ show : true });
        }
    }

    componentDidUpdate(prevProps) {
        if(!prevProps.text && this.props.text) {
            this.setState({ show : true });
        }
    }

    handleClose = () => {
        this.setState({ show : false });
    }

    render() {
        const { text } = this.props;
        const { show } = this.state;

        return (
            <div className={`alert alert-danger alert-dismissible ${show && text ? '' : ' hidden'}`} role="alert">
                <button 
                    type="button" 
                    className="close" 
                    data-dismiss="alert" 
                    aria-label="Close"
                    onClick={this.handleClose}
                >
                    <span aria-hidden="true">×</span>
                </button>
                {text}
            </div>
        );
    }
}

export default Alert;