import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getNotification, updateNotification } from './action';
import { showPopup, showToast, compareArray } from '../../helpers';
import NotificationDatatable from './datatable';

class NotificationPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ids: [],
        };
    }

    UNSAFE_componentWillMount() {
        this.fetchDatatable();
    }

    componentDidUpdate(prevProps) {
        if(!prevProps.success && this.props.success === 'update' && !this.props.loading) {
            this.fetchDatatable();
        }

        if(!prevProps.success && this.props.error === 'update' && !this.props.loading) {
            showToast('error', this.props.errorMessage);
        }
    }

    fetchDatatable = (limit, page) => {
        this.props.getNotification({limit, page});
    }

    toggleConfirmDelete = (id) => {
        showPopup('confirm-delete', id, this.handleDelete);
    }

    handleDelete = async (id) => {
        this.props.updateStatus({id});
    }

    handleRead = () => {
        const notif_id = this.state.ids;
        showPopup(
            'custom-confirm',
            'Mark selected notifications as read ?',
            'Yes',
            'question',
            'default',
            () => this.props.updateNotification({notif_id})
        );
    }

    handleSelectNotif = (selectedId) => {
        const stateId = this.state.ids;
        const allNotifIds = this.props.notifIds;

        if(selectedId === 'all') {
            this.setState(() => {
                if(Array.isArray(stateId) && compareArray(stateId, allNotifIds)) return {ids: []}; 
                return {ids: this.props.notifIds}; 
            });
        } else {
            this.setState(() => {
                if(stateId.includes(selectedId)) {
                    return { ids: stateId.filter((value) => selectedId !== value) };
                }
                return { ids: [...stateId, selectedId] };
            });
        }
    }

    render () {
        const { ids } = this.state;
        const { datatable, dtLoading, notifIds, loading } = this.props;

        return (
            <div className="page-container">
                <NotificationDatatable
                    dtLoading={dtLoading}
                    loading={loading}
                    dataSource={datatable}
                    toggleModal={this.toggleModal}
                    toggleConfirmDelete={this.toggleConfirmDelete}
                    reloadDatatable={this.fetchDatatable}
                    handleRead={this.handleRead}
                    handleSelect={this.handleSelectNotif}
                    handleSearch={this.handleSearch}
                    notifIds={notifIds}
                    selectedNotif={ids}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ notification }) => ({
    dtLoading: notification.dtLoading,
    loading: notification.loading,
    datatable: notification.datatable,
    success: notification.success,
    error: notification.error,
    errorMessage: notification.errorMessage,
    notifIds: notification.notifIds,
});

const mapDispatchToProps = dispatch => ({
    getNotification: (params) => dispatch(getNotification(params)),
    updateNotification: (params) => dispatch(updateNotification(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationPage);


